import './BrandName.css';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

function BrandName() {
  const tenentData = useSelector((state) => state.counter.tenentData);
  const tenant = localStorage.getItem("tenant").toLowerCase();
  const selectedtenant = tenentData.find((order) => order.employeeid === tenant);
  const regex = /^([^-]+)/;
  const match = selectedtenant ? selectedtenant.Username.match(regex) : null;

 
  const quotes = [
    "Success is not final; failure is not fatal: It is the courage to continue that counts. - Winston Churchill",
    "The only way to do great work is to love what you do. - Steve Jobs",
    "Don’t watch the clock; do what it does. Keep going. - Sam Levenson",
    "Believe you can and you're halfway there. - Theodore Roosevelt",
    "The harder you work for something, the greater you'll feel when you achieve it. - Anonymous",
    "Success is not how high you have climbed, but how you make a positive difference to the world. - Roy T. Bennett",
    "Opportunities don't happen. You create them. - Chris Grosser",
    "Success usually comes to those who are too busy to be looking for it. - Henry David Thoreau",
    "Don’t be afraid to give up the good to go for the great. - John D. Rockefeller",
    "The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
    "Your limitation—it's only your imagination. - Anonymous",
    "Great things never come from comfort zones. - Anonymous",
    "Dream it. Wish it. Do it. - Anonymous",
    "The key to success is to focus on goals, not obstacles. - Anonymous",
    "The way to get started is to quit talking and begin doing. - Walt Disney"
];




  const [quote, setQuote] = useState('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setQuote(quotes[randomIndex]);
  }, []);

  return (
    <div className="brand-name">
      <span className="welcome">Welcome, </span>
      <span className="username"> 
        {selectedtenant ? match[1] : " "}
      </span>
      <div className="greeting">{quote}</div>
    </div>
  )
}

export default BrandName;
