import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const AmChartsMapAnalytics = ({age}) => {
  useEffect(() => {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create map instance
    let chart = am4core.create("chartdivworld", am4maps.MapChart);
    let interfaceColors = new am4core.InterfaceColorSet();

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Mercator();

    // Add zoom control
    chart.zoomControl = new am4maps.ZoomControl();

    // Set initial zoom
    chart.homeZoomLevel = 2.5;
    chart.homeGeoPoint = {
      latitude: 51,
      longitude: -23
    };

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.exclude = ["AQ"];
    polygonSeries.useGeodata = true;
    polygonSeries.mapPolygons.template.nonScalingStroke = true;

    // Add images
    let imageSeries = chart.series.push(new am4maps.MapImageSeries());
    let imageTemplate = imageSeries.mapImages.template;
    imageTemplate.tooltipText = "{title}";
    imageTemplate.nonScaling = true;
   
    let marker = imageTemplate.createChild(am4core.Sprite);
    marker.path = "M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z";
    marker.horizontalCenter = "middle";
    marker.verticalCenter = "middle";
    marker.scale = 0.7;
    marker.fill = interfaceColors.getFor("alternativeBackground");

    imageTemplate.propertyFields.latitude = "latitude";
    imageTemplate.propertyFields.longitude = "longitude";
    if (age==1){
     imageSeries.data = [ {
      "id": "london",
      "svgPath": marker,
      "title": "London",
      "latitude": 51.5002,
      "longitude": -0.1262,
      "scale": 1
    }, {
      "svgPath": marker,
      "title": "Brussels",
      "latitude": 50.8371,
      "longitude": 4.3676,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Prague",
      "latitude": 50.0878,
      "longitude": 14.4205,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Athens",
      "latitude": 37.9792,
      "longitude": 23.7166,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Reykjavik",
      "latitude": 64.1353,
      "longitude": -21.8952,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Dublin",
      "latitude": 53.3441,
      "longitude": -6.2675,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Oslo",
      "latitude": 59.9138,
      "longitude": 10.7387,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Lisbon",
      "latitude": 38.7072,
      "longitude": -9.1355,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Moscow",
      "latitude": 55.7558,
      "longitude": 37.6176,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Belgrade",
      "latitude": 44.8048,
      "longitude": 20.4781,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Bratislava",
      "latitude": 48.2116,
      "longitude": 17.1547,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Ljubljana",
      "latitude": 46.0514,
      "longitude": 14.5060,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Madrid",
      "latitude": 40.4167,
      "longitude": -3.7033,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Stockholm",
      "latitude": 59.3328,
      "longitude": 18.0645,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Bern",
      "latitude": 46.9480,
      "longitude": 7.4481,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Kiev",
      "latitude": 50.4422,
      "longitude": 30.5367,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "Paris",
      "latitude": 48.8567,
      "longitude": 2.3510,
      "scale": 0.5
    }, {
      "svgPath": marker,
      "title": "New York",
      "latitude": 40.43,
      "longitude": -74,
      "scale": 0.5
    } ];
  }else if(age==2)

  {
     imageSeries.data = [
      {
        "id": "Sydney",
        "svgPath": marker,
        "title": "Sydney",
        "latitude": -33.8688,
        "longitude": 151.2093,
        "scale": 1
      },
      {
        "svgPath": marker,
        "title": "Melbourne",
        "latitude": -37.8136,
        "longitude": 144.9631,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Brisbane",
        "latitude": -27.4698,
        "longitude": 153.0251,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Perth",
        "latitude": -31.9505,
        "longitude": 115.8605,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Adelaide",
        "latitude": -34.9285,
        "longitude": 138.6007,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Canberra",
        "latitude": -35.2809,
        "longitude": 149.1300,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Gold Coast",
        "latitude": -28.0167,
        "longitude": 153.4000,
        "scale": 0.5
      }
    ];
    

  }else if (age==3)
  

  {
    imageSeries.data = [
      {
        "svgPath": marker,
        "title": "Mumbai",
        "latitude": 19.0760,
        "longitude": 72.8777,
        "scale": 1
      },
      {
        "svgPath": marker,
        "title": "Delhi",
        "latitude": 28.7041,
        "longitude": 77.1025,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Bangalore",
        "latitude": 12.9716,
        "longitude": 77.5946,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Hyderabad",
        "latitude": 17.3850,
        "longitude": 78.4867,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Chennai",
        "latitude": 13.0827,
        "longitude": 80.2707,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Kolkata",
        "latitude": 22.5726,
        "longitude": 88.3639,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Pune",
        "latitude": 18.5204,
        "longitude": 73.8567,
        "scale": 0.5
      }
    ];
    

  }else{
    imageSeries.data = [
      {
        "svgPath": marker,
        "title": "Singapore",
        "latitude": 1.3521,
        "longitude": 103.8198,
        "scale": 1
      },
      {
        "svgPath": marker,
        "title": "Jurong East",
        "latitude": 1.3332,
        "longitude": 103.7436,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Woodlands",
        "latitude": 1.4361,
        "longitude": 103.7868,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Tampines",
        "latitude": 1.3547,
        "longitude": 103.9437,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Changi",
        "latitude": 1.3644,
        "longitude": 103.9915,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Orchard Road",
        "latitude": 1.3048,
        "longitude": 103.8318,
        "scale": 0.5
      },
      {
        "svgPath": marker,
        "title": "Marina Bay",
        "latitude": 1.2894,
        "longitude": 103.8563,
        "scale": 0.5
      }
    ];
    
    

  }

    // Add lines
    let lineSeries = chart.series.push(new am4maps.MapLineSeries());
    lineSeries.dataFields.multiGeoLine = "multiGeoLine";

    let lineTemplate = lineSeries.mapLines.template;
    lineTemplate.nonScalingStroke = true;
    lineTemplate.arrow.nonScaling = true;
    lineTemplate.arrow.width = 4;
    lineTemplate.arrow.height = 6;
    lineTemplate.stroke = interfaceColors.getFor("alternativeBackground");
    lineTemplate.fill = interfaceColors.getFor("alternativeBackground");
    lineTemplate.line.strokeOpacity = 0.4;

   
       if (age==1){
        lineSeries.data = [{
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 50.4422, "longitude": 30.5367 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 40.4300, "longitude": -74.0000 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 64.1353, "longitude": -21.8952 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 37.9792, "longitude": 23.7166 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 38.7072, "longitude": -9.1355 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 55.7558, "longitude": 37.6176 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 44.8048, "longitude": 20.4781 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 48.2116, "longitude": 17.1547 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 46.0514, "longitude": 14.5060 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 40.4167, "longitude": -3.7033 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 59.3328, "longitude": 18.0645 }
            ]
          ]
        }, {
          "multiGeoLine": [
            [
              { "latitude": 51.5002, "longitude": -0.1262 },
              { "latitude": 46.9480, "longitude": 7.4481 }
            ]
          ]
        }];
  }else if(age==2)

  {
     
     
    lineSeries.data = [
      {
        "multiGeoLine": [
          [
            { "latitude": -33.8688, "longitude": 151.2093 }, // Sydney
            { "latitude": -37.8136, "longitude": 144.9631 }  // Melbourne
          ]
        ]
      },
      {
        "multiGeoLine": [
          [
            { "latitude": -33.8688, "longitude": 151.2093 }, // Sydney
            { "latitude": -27.4698, "longitude": 153.0251 }  // Brisbane
          ]
        ]
      },
      {
        "multiGeoLine": [
          [
            { "latitude": -33.8688, "longitude": 151.2093 }, // Sydney
            { "latitude": -31.9505, "longitude": 115.8605 }  // Perth
          ]
        ]
      },
      {
        "multiGeoLine": [
          [
            { "latitude": -33.8688, "longitude": 151.2093 }, // Sydney
            { "latitude": -34.9285, "longitude": 138.6007 }  // Adelaide
          ]
        ]
      },
      {
        "multiGeoLine": [
          [
            { "latitude": -33.8688, "longitude": 151.2093 }, // Sydney
            { "latitude": -35.2809, "longitude": 149.1300 }  // Canberra
          ]
        ]
      },
      {
        "multiGeoLine": [
          [
            { "latitude": -33.8688, "longitude": 151.2093 }, // Sydney
            { "latitude": -28.0167, "longitude": 153.4000 }  // Gold Coast
          ]
        ]
      }
    ];
    
    

  }else if (age==3)
  

  {
    lineSeries.data = [
      {
        "multiGeoLine": [
          [
            { "latitude": 51.5002, "longitude": -0.1262 },
            { "latitude": 19.0760, "longitude": 72.8777 }
          ]
        ]
      }, {
        "multiGeoLine": [
          [
            { "latitude": 51.5002, "longitude": -0.1262 },
            { "latitude": 28.7041, "longitude": 77.1025 }
          ]
        ]
      }, {
        "multiGeoLine": [
          [
            { "latitude": 51.5002, "longitude": -0.1262 },
            { "latitude": 12.9716, "longitude": 77.5946 }
          ]
        ]
      }, {
        "multiGeoLine": [
          [
            { "latitude": 51.5002, "longitude": -0.1262 },
            { "latitude": 17.3850, "longitude": 78.4867 }
          ]
        ]
      }, {
        "multiGeoLine": [
          [
            { "latitude": 51.5002, "longitude": -0.1262 },
            { "latitude": 13.0827, "longitude": 80.2707 }
          ]
        ]
      }, {
        "multiGeoLine": [
          [
            { "latitude": 51.5002, "longitude": -0.1262 },
            { "latitude": 22.5726, "longitude": 88.3639 }
          ]
        ]
      }, {
        "multiGeoLine": [
          [
            { "latitude": 51.5002, "longitude": -0.1262 },
            { "latitude": 18.5204, "longitude": 73.8567 }
          ]
        ]
      }
    ];
    
    

  }else{
    lineSeries.data = [
      {
        "multiGeoLine": [
          [
            { "latitude": 1.3521, "longitude": 103.8198 }, // Singapore
            { "latitude": 1.3332, "longitude": 103.7436 }  // Jurong East
          ]
        ]
      },
      {
        "multiGeoLine": [
          [
            { "latitude": 1.3521, "longitude": 103.8198 }, // Singapore
            { "latitude": 1.4361, "longitude": 103.7868 }  // Woodlands
          ]
        ]
      },
      {
        "multiGeoLine": [
          [
            { "latitude": 1.3521, "longitude": 103.8198 }, // Singapore
            { "latitude": 1.3547, "longitude": 103.9437 }  // Tampines
          ]
        ]
      },
      {
        "multiGeoLine": [
          [
            { "latitude": 1.3521, "longitude": 103.8198 }, // Singapore
            { "latitude": 1.3644, "longitude": 103.9915 }  // Changi
          ]
        ]
      },
      {
        "multiGeoLine": [
          [
            { "latitude": 1.3521, "longitude": 103.8198 }, // Singapore
            { "latitude": 1.3048, "longitude": 103.8318 }  // Orchard Road
          ]
        ]
      },
      {
        "multiGeoLine": [
          [
            { "latitude": 1.3521, "longitude": 103.8198 }, // Singapore
            { "latitude": 1.2894, "longitude": 103.8563 }  // Marina Bay
          ]
        ]
      }
    ];
    
    

  }

    return () => {
      chart.dispose();
    };
  }, [age]);

  return <div id="chartdivworld"  style={{ height: '85%', position: 'relative' }}/>;
};

export default AmChartsMapAnalytics; 


  