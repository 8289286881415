import React, { useState } from "react";
import "./Pricing.css";
import arrowRight from "../../assets/Arrow Right.png";
import calendar from "../../assets/Calendar.png";
import tick from "../../assets/tick.png";
import cross from "../../assets/Cross.png";
import check from "../../assets/tickwhite.png";
import info from "../../assets/info.png";
import axios from 'axios';
import Tooltip from "./toottip/Tooltip";
import { pricingTableData } from "./pricingTableData";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
function Pricing() {
  const [currency, setCurrency] = useState("INR");

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };
  const type=localStorage.getItem("subtype");

  
  const handleGetStarted = async(card) => {
    //console.log(card)
    await sendTicketToAPI(card)
  

    toast.success(`Your request for the ${card.planName} plan has been submitted. Our team will get back to you soon.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    window.open('https://calendly.com/dukeban', '_blank');

    // Handle the get started action here
  };

  const sendTicketToAPI = async (card) => {
 
    const data = JSON.parse(localStorage.getItem("user"));
    const brandName = localStorage.getItem("tenant").toUpperCase();
    const ticketid = `TIC${brandName}${Date.now()}`;
    //console.log("Authentication successful:", data.idToken.payload.email);
    const postdata = {
      id: ticketid,
      credtedby: data.idToken.payload.email,
      Status: "open",
      createdAt: Date.now(),
      brand: data.idToken.payload.name,
      ticketHeading: `Interested in ${card.planName}`,
      time:Date.now(),
      conversation: [
        {
          time: Date.now(),
          message: `Hi, I am interested in the ${card.planName} plan.`,
          sender: data.idToken.payload.email,
        },
      ],
    };
    try {
      const response = await fetch(
        "https://bxcswtw14g.execute-api.ap-south-1.amazonaws.com/prod/arvoTickets",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postdata),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      //console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const cardsData = [
    {
      planName: "Free",
      recommendedFor: "This plan is recommended to try out for individuals ",
      discountedPrice: ["₹0/mo", "$0/mo"],
      actualPrice: "",
      buttonText: `${type=="type2"?"Get Started":"Subscribed"}`,
      buttonImage: arrowRight,
      planDetails: [
        "30 credits",
        "1 User creation",
        "Unlimited Static QR Codes",
        "Unlimited Static scans",
        "3 Dynamic QR Code",
        `500 Dynamic Scans 'Data Cap'`,
      ],
    },
    {
      planName: "Basic",
      recommendedFor: "This plan is recommended for small-scale businesses",
      discountedPrice: ["₹4,999/mo", "$59/mo"],
      actualPrice: ["₹7,999", "$99/mo"],
      buttonText: "Get Started",
      buttonImage: arrowRight,
      planDetails: [
        "2,500 credits",
        "3 User creation",
        "Unlimited Static QR Codes",
        "Unlimited Static scans",
        "250 dynamic QR Code",
        `6000 Dynamic Scans 'Data Cap'`,
      ],
    },
    {
      planName: "Standard",
      recommendedFor: "This plan is recommended for medium-scale businesses ",
      discountedPrice: ["₹24,999/mo", "$299/mo"],
      actualPrice: ["₹29,999", "$359/mo"],
      buttonText: "Get Started",
      buttonImage: arrowRight,
      planDetails: [
        "15,000 credits",
        "5 User creation",
        "Unlimited Static QR Codes",
        "Unlimited Static scans",
        "1,500 dynamic QR Code",
        `50,000 Dynamic Scans 'Data Cap'`,
        "Instagram and Facebook Social Media Integration",
      ],
    },
    {
      planName: "Enterprise",
      recommendedFor: "This plan is recommended for large-scale enterprises",
      discountedPrice: ["Customised", "Customised"],
      actualPrice: "",
      buttonText: `${type=="type2"?"Subscribed":"Schedule a Call"}`,
      buttonImage: calendar,
      planDetails: [
        "Customised credits",
        "10 User creation",
        "Unlimited Static QR Codes",
        "Unlimited Static scans",
        "Customised dynamic QR Code",
        `Unlimited Dynamic Scans 'Data Cap'`,
        "Instagram, Facebook, Twitter, YouTube, and Website Social Media Integration",
        "Traceability Integration",
        "ERP Integration",
      ],
    },
  ];

  return (
    <div className="pricing-page">
      <ToastContainer/>
      <div className="pricing-header">
        <p>Pricing</p>
        <div className="currency-toggle">
          <select
            className="toggle-currency"
            style={{
              background: "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
              boxShadow: "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset",
              border: "none",
              color: "white",
              fontSize: "14px",
              fontWeight: "400",
              borderRadius: "24px",
              fontFamily: "Roboto",
              padding: "4px 7px",
              cursor: "pointer",
            }}
            value={currency}
            onChange={handleCurrencyChange}
          >
            <option style={{color:'black'}} value="INR">INR</option>
            <option style={{color:'black'}} value="USD">USD</option>
          </select>
        </div>
      </div>
      <div className="pricing-cards">
        {cardsData.map((card, index) => (
          <div
            className={`pricing-card ${card.buttonText === "Subscribed" ? "subscribed" : ""}`}
            key={index}
          >
            <p className={`price-card-title ${card.buttonText === "Subscribed" ? "subscribed-title" : ""}`}>
              {card.planName}
            </p>
            <p className={`price-card-details ${card.buttonText === "Subscribed" ? "subscribed-details" : ""}`}>
              {card.recommendedFor}
            </p>
            <div className="price-details">
              <p className={`discounted-price ${card.buttonText === "Subscribed" ? "subscribed-price" : ""}`}>
              {currency === "INR" ? card.discountedPrice[0] : card.discountedPrice[1]}
              </p>
              {card.actualPrice && (
                <p className="actual-price">
                  {currency === "INR" ? card.actualPrice[0] : card.actualPrice[1]}
                </p>
              )}
            </div>
            <div className="price-button-container">
              <button className="price-button" onClick={()=>handleGetStarted(card)}>
                {card.buttonText}
                {card.buttonText !== "Subscribed" && (
                  <img src={card.buttonImage} alt="Button" />
                )}
              </button>
            </div>
            {card.planDetails && (
              <div className="tier-details">
                {card.planDetails.map((detail, i) => (
                  <div className="tier-detail" key={i}>
                    <div className="tick-circle">
                      <img src={tick} alt="Tick" className="tick-img" />
                    </div>
                    <span
                      className="tier-text"
                      dangerouslySetInnerHTML={{
                        __html: detail.replace(
                          /(\d+|Unlimited)/g,
                          (match) => `<strong>${match}</strong>`
                        ),
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div>
        <table className="pricing-table">
          <thead>
            <tr>
              <th>Features</th>
              <th>Free</th>
              <th>Basic</th>
              <th>Standard</th>
              <th>Enterprise</th>
            </tr>
          </thead>
          <tbody>
            {pricingTableData.features.map((feature, index) => (
              <tr key={index}>
                <td>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {feature}
                      <Tooltip text={pricingTableData.descriptions[index]}>
                        <img
                          src={info}
                          alt="Info"
                          style={{
                            width: "14px",
                            height: "14px",
                            marginLeft: "3px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </div>
                </td>
                <td>
                  {pricingTableData.free[index] === "No" ? (
                    <img
                      src={cross}
                      alt="Cross"
                      style={{
                        backgroundColor: "grey",
                        width: "14px",
                        borderRadius: "50%",
                        height: "14px",
                      }}
                    />
                  ) : pricingTableData.free[index] === "Yes" ? (
                    <img
                      src={check}
                      alt="Check"
                      style={{
                        backgroundColor: "#0956cc",
                        width: "15px",
                        borderRadius: "50%",
                        height: "15px",
                      }}
                    />
                  ) : (
                    pricingTableData.free[index]
                  )}
                </td>
                <td>
                  {pricingTableData.basic[index] === "No" ? (
                    <img
                      src={cross}
                      alt="Cross"
                      style={{
                        backgroundColor: "grey",
                        width: "14px",
                        borderRadius: "50%",
                        height: "14px",
                      }}
                    />
                  ) : pricingTableData.basic[index] === "Yes" ? (
                    <img
                      src={check}
                      alt="Check"
                      style={{
                        backgroundColor: "#0956cc",
                        width: "15px",
                        borderRadius: "50%",
                        height: "15px",
                      }}
                    />
                  ) : (
                    pricingTableData.basic[index]
                  )}
                </td>
                <td>
                  {pricingTableData.standard[index] === "No" ? (
                    <img
                      src={cross}
                      alt="Cross"
                      style={{
                        backgroundColor: "grey",
                        width: "14px",
                        borderRadius: "50%",
                        height: "14px",
                      }}
                    />
                  ) : pricingTableData.standard[index] === "Yes" ? (
                    <img
                      src={check}
                      alt="Check"
                      style={{
                        backgroundColor: "#0956cc",
                        width: "15px",
                        borderRadius: "50%",
                        height: "15px",
                      }}
                    />
                  ) : (
                    pricingTableData.standard[index]
                  )}
                </td>
                <td>
                  {pricingTableData.enterprise[index] === "No" ? (
                    <img
                      src={cross}
                      alt="Cross"
                      style={{
                        backgroundColor: "grey",
                        width: "13px",
                        borderRadius: "50%",
                        height: "13px",
                      }}
                    />
                  ) : pricingTableData.enterprise[index] === "Yes" ? (
                    <img
                      src={check}
                      alt="Check"
                      style={{
                        backgroundColor: "#0956cc",
                        width: "15px",
                        borderRadius: "50%",
                        height: "15px",
                      }}
                    />
                  ) : (
                    pricingTableData.enterprise[index]
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Pricing;
