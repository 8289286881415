import PieChartBox from '../pieChartBox/PieChartBox'
import './Piechart.css'

function Piechart({nfcsessions}) {
  return (
    <div className="pie-chart-container">
      <div className="pie-chart-content">
        <PieChartBox nfcsessions={nfcsessions} />
      </div>
    </div>
  )
}

export default Piechart
