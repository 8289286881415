import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./Traceability.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {
  CurveChart,
  CurveColumnSeries,
  CurveCursor,
  bullets,
  CurveLineSeries,
  SerpentineChart,
} from "@amcharts/amcharts4/plugins/timeline";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PinBullet } from "@amcharts/amcharts4/plugins/bullets";
import ProductJourney from "./ProdiuctJourney/ProductJourney";
import { QRCode } from 'react-qrcode-logo';
import jsPDF from 'jspdf';
import customIcon from './customIcon.svg';
import AlgoData from "./AlgoData/AlgoData";
import refreshIcon from '../../../assets/reload.png';
import loadingGif from '../../../assets/loader.gif'; 
import Algorand from '../../../assets/Algorand_logo.svg.png'; 

am4core.useTheme(am4themes_animated);

function Traceability({ skuid }) {
  // const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [age, setAge] = useState(1);
  const [productJourneys, setProductJourneys] = useState([]);
  const [isJourneyExists, setIsJourneyExists] = useState(false);
  const [journeys, setJourneys] = useState([]);
  const qrCodeRefs = useRef([]);
  const [traceabilityData, setTraceabilityData] = useState([]);
  const [batchNumbers, setBatchNumbers] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState('');
  const [transactionNotes, setTransactionNotes] = useState({});
  const reloadRef = useRef(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [loading, setLoading] = useState(true); 

  const fetchProductJourney = async () => {
    try {
      const response = await axios.get(`https://1c6see68bj.execute-api.ap-south-1.amazonaws.com/prod/product-journey?skuid=${skuid}`);
      if (response.data && response.data.ProductJourney) {
        setIsJourneyExists(true);
        setJourneys(response.data.ProductJourney);
        setAge(2);
      } else {
        setIsJourneyExists(false);
        setJourneys([]);
      }
    } catch (error) {
      console.error('Error fetching product journey:', error);
      setIsJourneyExists(false);
      setJourneys([]);
    }
  };
  

  useEffect(() => {
    fetchProductJourney();
  }, [skuid]);

  const handleChange = (event) => {
    setAge(Number(event.target.value)); // Convert string to number
  };

  const [propertyDetail, setPropertyDetail] = useState(null);
  const [tenantData, setTenantdata] = useState();
  const tenantDataRef = useRef([]);
  // useEffect(() => {
  //   // Calculate the total width of all tab names
  //   const tabList = document.querySelector(".react-tabs__tab-list");
  //   // const totalTabWidth = Array.from(tabList.children).reduce(
  //   //   (acc, tab) => acc + tab.offsetWidth,
  //   //   0
  //   // );

  //   // Set the width of the tab list container
  //   tabList.style.width = `800px`;
  //   tabList.style.height = '36px';
  // }, []);

  const tenants = localStorage.getItem("tenant").toLowerCase();

  const calculateTotalQty = (data) => {
    let totalQty = 0;
    data.forEach((entry) => {
      if (entry.requestBody.rows && entry.requestBody.rows.length > 0) {
        // Sum up the qty values from all rows
        totalQty += entry.requestBody.rows.reduce(
          (acc, row) => acc + row.qty,
          0
        );
      }
    });
    return totalQty;
  };

  useEffect(() => {
    am4core.useTheme(am4themes_animated);
    let map = am4core.create("chartdiv1", SerpentineChart);
    map.curveContainer.padding(100, 20, 50, 20);
    map.levelCount = 3;
    map.yAxisRadius = am4core.percent(20);
    map.yAxisInnerRadius = am4core.percent(2);
    map.maskBullets = false;

    let colorSet = new am4core.ColorSet();

    map.dateFormatter.inputDateFormat = "yyyy-MM-dd'T'HH:mm:ssZ";
    map.dateFormatter.dateFormat = "HH:mm";

    let categoryAxis = map.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.paddingRight = 25;
    categoryAxis.renderer.minGridDistance = 10;

    let dateAxis = map.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.baseInterval = { count: 1, timeUnit: "minute" };
    dateAxis.skipEmptyPeriods = true;
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.renderer.line.strokeDasharray = "1,4";
    dateAxis.renderer.line.strokeOpacity = 0.5;
    dateAxis.tooltip.disabled = true;
    dateAxis.tooltip.background.fillOpacity = 0.2;
    dateAxis.tooltip.background.cornerRadius = 5;
    dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    dateAxis.tooltip.label.paddingTop = 7;
    dateAxis.endLocation = 1;
    dateAxis.startLocation = 0;

    let labelTemplate = dateAxis.renderer.labels.template;
    labelTemplate.verticalCenter = "middle";
    labelTemplate.fillOpacity = 0.4;
    labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");

    let series = map.series.push(new CurveColumnSeries());
    series.columns.template.height = am4core.percent(15);

    series.dataFields.openDateX = "start";
    series.dataFields.dateX = "end";
    series.dataFields.categoryY = "category";
    series.baseAxis = categoryAxis;
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.fillOpacity = 0.6;
    series.columns.template.tooltipText = "{tooltip}";

    let imageBullet1 = series.bullets.push(new PinBullet());
    imageBullet1.locationX = 0.5;
    imageBullet1.propertyFields.stroke = "color";
    imageBullet1.background.propertyFields.fill = "color";
    imageBullet1.image = new am4core.Image();
    imageBullet1.image.propertyFields.href = "icon";
    imageBullet1.image.scale = 0.5;
    imageBullet1.circle.radius = am4core.percent(100);
    imageBullet1.dy = -10;

    let textBullet = series.bullets.push(new am4charts.LabelBullet());
    textBullet.label.propertyFields.text = "text";
    textBullet.disabled = true;
    textBullet.propertyFields.disabled = "textDisabled";
    textBullet.label.strokeOpacity = 0;
    textBullet.locationX = 0.5;
    textBullet.dy = -100;
    textBullet.label.textAlign = "middle";

    map.scrollbarX = new am4core.Scrollbar();
    map.scrollbarX.align = "center";
    map.scrollbarX.width = am4core.percent(75);
    map.scrollbarX.opacity = 0.5;

    let cursor = new CurveCursor();
    map.cursor = cursor;
    cursor.xAxis = dateAxis;
    cursor.yAxis = categoryAxis;
    cursor.lineY.disabled = true;
    cursor.lineX.strokeDasharray = "1,4";
    cursor.lineX.strokeOpacity = 1;

    dateAxis.renderer.tooltipLocation2 = 0;
    categoryAxis.cursorTooltipEnabled = false;

    let label = map.createChild(am4core.Label);
    label.isMeasured = false;
    label.y = am4core.percent(40);
    label.x = am4core.percent(50);
    label.horizontalCenter = "middle";
    label.fontSize = 20;

    if (selectedBatch && traceabilityData.length > 0) {
      const selectedBatchData = traceabilityData.find(item => item.BatchNumber === selectedBatch);
      if (selectedBatchData) {
        const { CreatedOnList, JourneyNumber, TransactionHash } = selectedBatchData;

        const data = CreatedOnList.map((createdOn, index) => {
          const journeyIndex = JourneyNumber - (CreatedOnList.length - index - 1);
          const journey = journeys.find(j => j.index === journeyIndex);
          const isCompleted = journeyIndex <= JourneyNumber;

          if (!journey && isCompleted) {
            console.error("No matching journey found for index:", journeyIndex);
            return {};
          }

          const start = createdOn;
          const end = CreatedOnList[index + 1] || new Date(new Date(createdOn).getTime() + 10 * 60 * 1000).toISOString();
          const transactionNote = TransactionHash[index] && transactionNotes[TransactionHash[index]] ? transactionNotes[TransactionHash[index]] : '';

          return {
            category: "",
            start: start,
            end: end,
            color: isCompleted ? "#7ddc67" : "#555454",
            text: isCompleted ? (transactionNote.length > 15 ? transactionNote.slice(0, 15) + '...' : transactionNote) : '',
            textDisabled: !isCompleted,
            icon: isCompleted ? customIcon : '',
            tooltip: isCompleted ? journey.description : '',
          };
        });

        if (JourneyNumber < journeys.length) {
          for (let i = JourneyNumber; i < journeys.length; i++) {
            data.push({
              category: "",
              start: data[data.length - 1].end,
              end: new Date(new Date(data[data.length - 1].end).getTime() + 10 * 60 * 1000).toISOString(),
              color: "#555454",
              text: '',
              textDisabled: true,
              icon: '',
              tooltip: '',
            });
          }
        }

        map.data = data;

        dateAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          let index = target.dataItem.index;
          if (index < data.length) {
            return map.dateFormatter.format(new Date(data[index].start), "HH:mm");
          }
          return "";
        });

        const firstDate = new Date(CreatedOnList[0]).getTime();
        const lastDate = new Date(data[data.length - 1].end).getTime();

        dateAxis.min = firstDate;
        dateAxis.max = lastDate;

        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.grid.template.strokeOpacity = 0;
        dateAxis.renderer.labels.template.location = 0.5;
        dateAxis.renderer.labels.template.adapter.add("text", (text, target) => {
          let dataItem = target.dataItem;
          if (dataItem && dataItem.index < data.length) {
            return map.dateFormatter.format(new Date(data[dataItem.index].start), "HH:mm");
          }
          return "";
        });
      }
    }

    return () => {
      map.dispose();
    };
  }, [age, selectedBatch, traceabilityData, journeys, transactionNotes]);

  // useEffect(() => {
  //   am4core.useTheme(am4themes_animated);

  //   let chart = am4core.create("chartdiv2", SerpentineChart);
  //   chart.curveContainer.padding(20, 20, 20, 20);
  //   chart.levelCount = 8;
  //   chart.orientation = "horizontal";
  //   chart.fontSize = 11;

  //   let colorSet = new am4core.ColorSet();
  //   colorSet.saturation = 0.6;

  //   chart.data = [
  //     {
  //       category: "Module #1",
  //       start: "2016-01-10",
  //       end: "2016-01-13",
  //       color: colorSet.getIndex(0),
  //       task: "Gathering requirements",
  //     },
  //     {
  //       category: "Module #1",
  //       start: "2016-02-05",
  //       end: "2016-04-18",
  //       color: colorSet.getIndex(0),
  //       task: "Development",
  //     },
  //     {
  //       category: "Module #2",
  //       start: "2016-01-08",
  //       end: "2016-01-10",
  //       color: colorSet.getIndex(5),
  //       task: "Gathering requirements",
  //     },
  //     {
  //       category: "Module #2",
  //       start: "2016-01-12",
  //       end: "2016-01-15",
  //       color: colorSet.getIndex(5),
  //       task: "Producing specifications",
  //     },
  //     {
  //       category: "Module #2",
  //       start: "2016-01-16",
  //       end: "2016-02-05",
  //       color: colorSet.getIndex(5),
  //       task: "Development",
  //     },
  //     {
  //       category: "Module #2",
  //       start: "2016-02-10",
  //       end: "2016-02-18",
  //       color: colorSet.getIndex(5),
  //       task: "Testing and QA",
  //     },
  //     {
  //       category: "",
  //       task: "",
  //     },
  //     {
  //       category: "Module #3",
  //       start: "2016-01-01",
  //       end: "2016-01-19",
  //       color: colorSet.getIndex(9),
  //       task: "Gathering requirements",
  //     },
  //     {
  //       category: "Module #3",
  //       start: "2016-02-01",
  //       end: "2016-02-10",
  //       color: colorSet.getIndex(9),
  //       task: "Producing specifications",
  //     },
  //     {
  //       category: "Module #3",
  //       start: "2016-03-10",
  //       end: "2016-04-15",
  //       color: colorSet.getIndex(9),
  //       task: "Development",
  //     },
  //     {
  //       category: "Module #3",
  //       start: "2016-04-20",
  //       end: "2016-04-30",
  //       color: colorSet.getIndex(9),
  //       task: "Testing and QA",
  //     },
  //     {
  //       category: "Module #4",
  //       start: "2016-01-15",
  //       end: "2016-02-12",
  //       color: colorSet.getIndex(15),
  //       task: "Gathering requirements",
  //     },
  //     {
  //       category: "Module #4",
  //       start: "2016-02-25",
  //       end: "2016-03-10",
  //       color: colorSet.getIndex(15),
  //       task: "Development",
  //     },
  //     {
  //       category: "Module #4",
  //       start: "2016-03-23",
  //       end: "2016-04-29",
  //       color: colorSet.getIndex(15),
  //       task: "Testing and QA",
  //     },
  //   ];

  //   chart.dateFormatter.dateFormat = "yyyy-MM-dd";
  //   chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

  //   let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
  //   categoryAxis.dataFields.category = "category";
  //   categoryAxis.renderer.grid.template.disabled = true;
  //   categoryAxis.renderer.labels.template.paddingRight = 25;
  //   categoryAxis.renderer.minGridDistance = 10;
  //   categoryAxis.renderer.innerRadius = -60;
  //   categoryAxis.renderer.radius = 60;

  //   let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  //   dateAxis.renderer.minGridDistance = 70;
  //   dateAxis.baseInterval = { count: 1, timeUnit: "day" };

  //   dateAxis.renderer.tooltipLocation = 0;
  //   dateAxis.startLocation = -0.5;
  //   dateAxis.renderer.line.strokeDasharray = "1,4";
  //   dateAxis.renderer.line.strokeOpacity = 0.7;
  //   dateAxis.tooltip.background.fillOpacity = 0.2;
  //   dateAxis.tooltip.background.cornerRadius = 5;
  //   dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor(
  //     "alternativeBackground"
  //   );
  //   dateAxis.tooltip.label.paddingTop = 7;

  //   let labelTemplate = dateAxis.renderer.labels.template;
  //   labelTemplate.verticalCenter = "middle";
  //   labelTemplate.fillOpacity = 0.7;
  //   labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor(
  //     "background"
  //   );
  //   labelTemplate.background.fillOpacity = 1;
  //   labelTemplate.padding(7, 7, 7, 7);

  //   let categoryAxisLabelTemplate = categoryAxis.renderer.labels.template;
  //   categoryAxisLabelTemplate.horizontalCenter = "left";
  //   categoryAxisLabelTemplate.adapter.add(
  //     "rotation",
  //     function (rotation, target) {
  //       let position = dateAxis.valueToPosition(dateAxis.min);
  //       return dateAxis.renderer.positionToAngle(position) + 90;
  //     }
  //   );

  //   let series1 = chart.series.push(new CurveColumnSeries());
  //   series1.columns.template.height = am4core.percent(20);
  //   series1.columns.template.tooltipText =
  //     "{task}: [bold]{openDateX}[/] - [bold]{dateX}[/]";

  //   series1.dataFields.openDateX = "start";
  //   series1.dataFields.dateX = "end";
  //   series1.dataFields.categoryY = "category";
  //   series1.columns.template.propertyFields.fill = "color"; // get color from data
  //   series1.columns.template.propertyFields.stroke = "color";
  //   series1.columns.template.strokeOpacity = 0;

  //   let bullet = new am4charts.CircleBullet();
  //   series1.bullets.push(bullet);
  //   bullet.circle.radius = 3;
  //   bullet.circle.strokeOpacity = 0;
  //   bullet.propertyFields.fill = "color";
  //   bullet.locationX = 0;

  //   let bullet2 = new am4charts.CircleBullet();
  //   series1.bullets.push(bullet2);
  //   bullet2.circle.radius = 3;
  //   bullet2.circle.strokeOpacity = 0;
  //   bullet2.propertyFields.fill = "color";
  //   bullet2.locationX = 1;

  //   chart.scrollbarX = new am4core.Scrollbar();
  //   chart.scrollbarX.align = "center";
  //   chart.scrollbarX.width = am4core.percent(90);

  //   let cursor = new CurveCursor();
  //   chart.cursor = cursor;
  //   cursor.xAxis = dateAxis;
  //   cursor.yAxis = categoryAxis;
  //   cursor.lineY.disabled = true;
  //   cursor.lineX.strokeDasharray = "1,4";
  //   cursor.lineX.strokeOpacity = 1;

  //   dateAxis.renderer.tooltipLocation2 = 0;
  //   categoryAxis.cursorTooltipEnabled = false;

  //   return () => {
  //     chart.dispose();
  //   };
  // }, [age]);

  // useEffect(() => {
  //   let chart2 = am4core.create("chartdiv", SerpentineChart);
  //   chart2.curveContainer.padding(50, 20, 50, 20);
  //   chart2.levelCount = 4;
  //   chart2.yAxisRadius = am4core.percent(25);
  //   chart2.yAxisInnerRadius = am4core.percent(-25);
  //   chart2.maskBullets = false;

  //   let colorSet = new am4core.ColorSet();
  //   colorSet.saturation = 0.5;

  //   chart2.data = [
  //     {
  //       category: "Module #1",
  //       start: "2019-01-10",
  //       end: "2019-01-13",
  //       color: colorSet.getIndex(0),
  //       task: "Gathering requirements",
  //     },
  //     {
  //       category: "Module #1",
  //       start: "2019-02-05",
  //       end: "2019-04-18",
  //       color: colorSet.getIndex(0),
  //       task: "Development",
  //     },
  //     {
  //       category: "Module #2",
  //       start: "2019-01-08",
  //       end: "2019-01-10",
  //       color: colorSet.getIndex(5),
  //       task: "Gathering requirements",
  //     },
  //     {
  //       category: "Module #2",
  //       start: "2019-01-12",
  //       end: "2019-01-15",
  //       color: colorSet.getIndex(5),
  //       task: "Producing specifications",
  //     },
  //     {
  //       category: "Module #2",
  //       start: "2019-01-16",
  //       end: "2019-02-05",
  //       color: colorSet.getIndex(5),
  //       task: "Development",
  //     },
  //     {
  //       category: "Module #2",
  //       start: "2019-02-10",
  //       end: "2019-02-18",
  //       color: colorSet.getIndex(5),
  //       task: "Testing and QA",
  //     },
  //     {
  //       category: "",
  //     },
  //     {
  //       category: "Module #3",
  //       start: "2019-01-01",
  //       end: "2019-01-19",
  //       color: colorSet.getIndex(9),
  //       task: "Gathering requirements",
  //     },
  //     {
  //       category: "Module #3",
  //       start: "2019-02-01",
  //       end: "2019-02-10",
  //       color: colorSet.getIndex(9),
  //       task: "Producing specifications",
  //     },
  //     {
  //       category: "Module #3",
  //       start: "2019-03-10",
  //       end: "2019-04-15",
  //       color: colorSet.getIndex(9),
  //       task: "Development",
  //     },
  //     {
  //       category: "Module #3",
  //       start: "2019-04-20",
  //       end: "2019-04-30",
  //       color: colorSet.getIndex(9),
  //       task: "Testing and QA",
  //       disabled2: false,
  //       image2: "/wp-content/uploads/assets/timeline/rachel.jpg",
  //       location: 0,
  //     },
  //     {
  //       category: "Module #4",
  //       start: "2019-01-15",
  //       end: "2019-02-12",
  //       color: colorSet.getIndex(15),
  //       task: "Gathering requirements",
  //       disabled1: false,
  //       image1: "/wp-content/uploads/assets/timeline/monica.jpg",
  //     },
  //     {
  //       category: "Module #4",
  //       start: "2019-02-25",
  //       end: "2019-03-10",
  //       color: colorSet.getIndex(15),
  //       task: "Development",
  //     },
  //     {
  //       category: "Module #4",
  //       start: "2019-03-23",
  //       end: "2019-04-29",
  //       color: colorSet.getIndex(15),
  //       task: "Testing and QA",
  //     },
  //   ];

  //   chart2.dateFormatter.dateFormat = "yyyy-MM-dd";
  //   chart2.dateFormatter.inputDateFormat = "yyyy-MM-dd";
  //   chart2.fontSize = 11;

  //   let categoryAxis = chart2.yAxes.push(new am4charts.CategoryAxis());
  //   categoryAxis.dataFields.category = "category";
  //   categoryAxis.renderer.grid.template.disabled = true;
  //   categoryAxis.renderer.labels.template.paddingRight = 25;
  //   categoryAxis.renderer.minGridDistance = 10;
  //   categoryAxis.renderer.innerRadius = -60;
  //   categoryAxis.renderer.radius = 60;

  //   let dateAxis = chart2.xAxes.push(new am4charts.DateAxis());
  //   dateAxis.renderer.minGridDistance = 70;
  //   dateAxis.baseInterval = { count: 1, timeUnit: "day" };
  //   dateAxis.renderer.tooltipLocation = 0;
  //   dateAxis.startLocation = -0.5;
  //   dateAxis.renderer.line.strokeDasharray = "1,4";
  //   dateAxis.renderer.line.strokeOpacity = 0.6;
  //   dateAxis.tooltip.background.fillOpacity = 0.2;
  //   dateAxis.tooltip.background.cornerRadius = 5;
  //   dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor(
  //     "alternativeBackground"
  //   );
  //   dateAxis.tooltip.label.paddingTop = 7;

  //   let labelTemplate = dateAxis.renderer.labels.template;
  //   labelTemplate.verticalCenter = "middle";
  //   labelTemplate.fillOpacity = 0.7;
  //   labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor(
  //     "background"
  //   );
  //   labelTemplate.background.fillOpacity = 1;
  //   labelTemplate.padding(7, 7, 7, 7);

  //   let series = chart2.series.push(new CurveColumnSeries());

  //   series.columns.template.height = am4core.percent(20);
  //   series.columns.template.tooltipText =
  //     "{task}: [bold]{openDateX}[/] - [bold]{dateX}[/]";

  //   series.dataFields.openDateX = "start";
  //   series.dataFields.dateX = "end";
  //   series.dataFields.categoryY = "category";
  //   series.columns.template.propertyFields.fill = "color";
  //   series.columns.template.propertyFields.stroke = "color";
  //   series.columns.template.strokeOpacity = 0;

  //   let bullet = series.bullets.push(new am4charts.CircleBullet());
  //   bullet.circle.radius = 3;
  //   bullet.circle.strokeOpacity = 0;
  //   bullet.propertyFields.fill = "color";
  //   bullet.locationX = 0;

  //   // More chart configuration...

  //   return () => {
  //     chart2.dispose();
  //   };
  // }, [age]);


  const downloadQRCode = async () => {
    const doc = new jsPDF();
    let yOffset = 10; // Initial Y offset
    const pageHeight = doc.internal.pageSize.height; // Height of the page

    for (let i = 0; i < journeys.length; i++) {
        const qrCodeData = `https://onearvo.tech/${skuid}×${i + 1}`;
        console.log('QR Code URL:', qrCodeData);
        const canvas = qrCodeRefs.current[i].querySelector('canvas');
        const imgData = canvas.toDataURL('image/png');

        // Check if we need to add a new page
        if (yOffset + 60 > pageHeight) { // Adjust the height as needed
            doc.addPage();
            yOffset = 10; // Reset Y offset for the new page
        }

        // Add the QR code image
        doc.addImage(imgData, 'PNG', 10, yOffset, 50, 50);

        // Add the title and index next to the QR code
        doc.setFontSize(10);
        doc.text(`Title: ${journeys[i].title}`, 70, yOffset + 15); // Adjust positioning as needed
        doc.text(`Product Journey Number: ${i + 1}`, 70, yOffset + 25); // Adjust positioning as needed

        yOffset += 60; // Adjust the offset for the next entry
    }

    doc.save('qrcodes.pdf');
};



const handleBatchChange = (event) => {
  setSelectedBatch(event.target.value);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

const reloadData = () => {
  if (reloadRef.current) {
    setIsSpinning(true);
    reloadRef.current();
    setTimeout(() => setIsSpinning(false), 200);
  }
};

const selectedTraceability = traceabilityData.find(item => item.BatchNumber === selectedBatch);
const maxIndex = journeys.length > 0 ? journeys[journeys.length - 1].index : 0;



return (
  <>
  <ToastContainer />
  <div className="traceability-container">
    {journeys.length === 0 ? (
      <div className="add-journey-container">
        <p>Add Product Journey to use Traceability feature</p>
        <button onClick={() => setShowModal(true)}>Add Product Journey</button>
      </div>
    ) : (
      <>
        <div className="productdetails-text">
          <p>Product Traceability</p>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
              <label className="trace-select-text" htmlFor="batch-select">Select Batch Number:</label>
              <select id="batch-select" className="trace-select-batch" value={selectedBatch} onChange={handleBatchChange}>
                <option value="" disabled>Select Batch Number</option>
                {batchNumbers.map((batch, index) => (
                  <option key={index} value={batch}>
                    {batch}
                  </option>
                ))}
              </select>
              <img
                src={refreshIcon}
                alt="Refresh"
                style={{ cursor: 'pointer', marginLeft: '10px', width: '14px', height: '14px' }}
                onClick={reloadData}
                className={isSpinning ? 'spin' : ''}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginBottom: '10px' }}>
              <button className="trace-download-qr" onClick={downloadQRCode}>Download QR Code</button>
              <button className="trace-edit-button" onClick={() => setShowModal(true)}>Edit Product Journey</button>
            </div>
          </div>
        </div>
        <div className="productdetails-header">
  <label
    htmlFor="map-select"
    style={{
      fontFamily: "Baumans",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "18.91px",
      letterSpacing: "0.02em",
    }}
  >
    Map
  </label>
  {selectedTraceability && (
    <span 
    style={{ 
      marginLeft: '10px', 
      fontFamily: "Roboto", 
      fontSize: "16px", 
      fontWeight: "400", 
      lineHeight: "18.91px", 
      letterSpacing: "0.02em",
      color: selectedTraceability.JourneyNumber >= maxIndex ? 'green' : 'rgb(255, 173, 22)'
    }}
  >
    {selectedTraceability.JourneyNumber >= maxIndex ? 'Batch is Completed' : 'Batch in Progress'}
  </span>
  )}
  <select
    style={{
      borderRadius: "20px",
      height: "30px",
      marginLeft: "4px",
      padding: "5px",
    }}
    id="map-select"
    value={age}
    onChange={(e) => setAge(parseInt(e.target.value))}
    className="select"
  >
    <option value={2}>Icon View</option>
    {/* <option value={1}>Horizontal View</option> */}
    {/* <option value={3}>Vertical View</option> */}
  </select>
</div>
        {loading ? (
  batchNumbers.length === 0 ? (
    <div className="no-batch-message">
      Congratulations for creating your first journey. Please scan to see batch progress.
    </div>
  ) : (
    <div className="loader">
      <img src={loadingGif} alt="Loading..." />
    </div>
  )
) : age === 1 ? (
  <div id="chartdiv" style={{ width: "100%", height: "650px" }}></div>
) : age === 2 ? (
  <div id="chartdiv1" style={{ width: "100%", height: "650px" }} />
) : (
  <div id="chartdiv2" style={{ width: "100%", height: "650px" }} />
)}
      </>
    )}
  </div>

  {showModal && (
    <ProductJourney
      onClose={() => {
        setShowModal(false);
        fetchProductJourney();
      }}
      skuid={skuid}
    />
  )}
  <div style={{ display: 'none' }}>
    {journeys.map((journey, index) => (
      <div key={index} ref={(el) => qrCodeRefs.current[index] = el}>
        <QRCode value={`https://onearvo.tech/${skuid}×${index + 1}`} size={100} />
      </div>
    ))}
  </div>
  <AlgoData
    skuid={skuid}
    onBatchNumbers={setBatchNumbers}
    onTraceabilityData={setTraceabilityData}
    onJourneyData={setJourneys}
    onTransactionNotes={(notes) => {
      setTransactionNotes(notes);
      setLoading(false);
    }}
    reloadRef={reloadRef}
  />
  <div style={{
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}}>

<span style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize:"14px", fontFamily:"Baumans"
}}>Powered By <img style={{width:"76px"}} src={Algorand} alt="Algorand" /></span>
  </div>
  {selectedBatch && selectedTraceability && (
   <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', marginBottom: '20px' }}>
   <thead>
     <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'left' }}>
       <th style={{ padding: '12px', borderBottom: '1px solid #ddd', width:'16.66%' }}>Images</th>
       <th style={{ padding: '12px', borderBottom: '1px solid #ddd', width: '16.66%' }}>Date</th>
       <th style={{ padding: '12px', borderBottom: '1px solid #ddd', width: '14.66%' }}>Time</th>
       <th style={{ padding: '12px', borderBottom: '1px solid #ddd', width: '16.66%' }}>Title</th>
       <th style={{ padding: '12px', borderBottom: '1px solid #ddd', width: '16.66%'}}>Algo Transaction Hash </th>
       <th style={{ padding: '12px', borderBottom: '1px solid #ddd', width: '16.66%' }}>Status</th>
     </tr>
   </thead>
   <tbody>
     {Array.from({ length: maxIndex }).map((_, index) => (
       <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
         <td style={{ padding: '12px', width: '16.66%' }}>
           {selectedTraceability.ImageTrace ? (
             selectedTraceability.ImageTrace[index] ? (
               selectedTraceability.ImageTrace[index].length > 0 ? (
                 <img src={selectedTraceability.ImageTrace[index]} alt="Captured" style={{ width: '50px', height: '50px' }} />
               ) : 'Not Captured'
             ) : 'N/A'
           ) : 'N/A'}
         </td>
         <td style={{ padding: '12px', width: '16.66%' }}>
           {selectedTraceability.CreatedOnList[index] ? formatDate(selectedTraceability.CreatedOnList[index]) : 'N/A'}
         </td>
         <td style={{ padding: '12px', width: '16.66%' }}>
           {selectedTraceability.CreatedOnList[index] ? formatTime(selectedTraceability.CreatedOnList[index]) : 'N/A'}
         </td>
         <td style={{ padding: '12px', width: '16.66%' }}>
           {journeys[index] ? journeys[index].title : 'N/A'}
         </td>
         <td style={{ padding: '12px', width: '16.66%' }}>
  {selectedTraceability.TransactionHash && selectedTraceability.TransactionHash[index] ? (
    <a href={`https://app.dappflow.org/explorer/transaction/${selectedTraceability.TransactionHash[index]}`} target="_blank" rel="noopener noreferrer">
      {selectedTraceability.TransactionHash[index].substring(0, 18)}...
    </a>
  ) : 'N/A'}
</td>

         <td style={{ padding: '12px', width: '16.66%', color: selectedTraceability.JourneyNumber >= (index + 1) ? 'green' : 'red' }}>
           {selectedTraceability.JourneyNumber >= (index + 1) ? 'Completed' : 'Pending'}
         </td>
       </tr>
     ))}
   </tbody>
 </table>  
  )}
</>
);
}

export default Traceability;