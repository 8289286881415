import React, { useState } from "react";
import "./Usertable.css";

import searchIcon from "../../assets/Searchsuper.png";
import brandIcon from "../../assets/plus.png";
import tenantIcon from "../../assets/plus.png";
import DownArrow from "../../assets/dropdownblue.png";
import Status from "../Status/Status";

const initialOrderData = [
  {
    brandName: "Kandavika",
    tenantNum: 1,
    tier: "Tier 1",
    status: "Deactivated",
    tenantName: [
      { name: "Tenant1", isActive: true },
      { name: "Tenant2", isActive: false },
    ],
    credits: 200,
  },
  {
    brandName: "Genesis",
    tenantNum: 2,
    tier: "Tier 1",
    status: "Deactivated",
    tenantName: [
      { name: "Tenant23", isActive: true },
      { name: "Tenant424", isActive: false },
    ],
    credits: 200,
  },
  {
    brandName: "Primavision",
    tenantNum: 3,
    tier: "Tier 1",
    status: "Deactivated",
    tenantName: [
      { name: "Tenant65", isActive: true },
      { name: "Tenan23", isActive: false },
    ],
    credits: 200,
  },
  {
    brandName: "Arvo",
    tenantNum: 4,
    tier: "Tier 1",
    status: "Active",
    tenantName: [
      { name: "Tenant534", isActive: true },
      { name: "Tenant65", isActive: false },
    ],
    credits: 200,
  },
  {
    brandName: "XYZX",
    tenantNum: 5,
    tier: "Tier 3",
    status: "Active",
    tenantName: [
      { name: "Tenant8678", isActive: true },
      { name: "DWECSW12", isActive: false },
    ],
    credits: 200,
  },
];

function getStatusStyle(status) {
  const baseStyle = {
    borderRadius: "8px",
    padding: "4px 15px",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18.75px",
    letterSpacing: "0.02em",
  };

  const styles = {
    Deactivated: {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
    Active: {
      backgroundColor: "#DDFFE2",
      color: "#119927",
      ...baseStyle,
    },
  };

  return <span style={styles[status]}>{status}</span>;
}

function Usertable({ ticketsdata }) {
  console.log(ticketsdata);
  const [expandedRow, setExpandedRow] = useState(null);
  const [orderData, setOrderData] = useState(initialOrderData);
  const [showBrandModal, setShowBrandModal] = useState(false);
  const [showTenantModal, setShowTenantModal] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");

  const toggleRow = (index) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleDeleteRow = (index) => {
    if (window.confirm("Are you sure you want to delete this brand?")) {
      const updatedOrderData = [...orderData];
      updatedOrderData.splice(index, 1);
      setOrderData(updatedOrderData);
      setExpandedRow(null);
    }
  };

  const handleDeactivateTenant = (tenantName) => {
    console.log(`Deactivating tenant: ${tenantName}`);
  };

  const handleDeleteTenant = (tenantName, brandName) => {
    if (
      window.confirm(
        `Are you sure you want to delete the tenant ${tenantName}?`
      )
    ) {
      const updatedOrderData = orderData.map((order) => {
        if (order.brandName === brandName) {
          return {
            ...order,
            tenantName: order.tenantName.filter(
              (tenant) => tenant.name !== tenantName
            ),
          };
        }
        return order;
      });
      setOrderData(updatedOrderData);
    }
  };

  const handleBrandButtonClick = () => {
    setShowBrandModal(true);
  };

  const handleBrandModalClose = () => {
    setShowBrandModal(false);
  };

  const handleTenantButtonClick = () => {
    setShowTenantModal(true);
  };

  const handleTenantModalClose = () => {
    setShowTenantModal(false);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  function calculateTimeDifference(start, end) {
    const startTimestamp = new Date(start).getTime(); // Example start timestamp
    const endTimestamp = new Date(end).getTime();

    console.log(startTimestamp, endTimestamp);

    const timeDifference = endTimestamp - startTimestamp;
    const seconds = Math.floor((timeDifference / 1000) % 60);
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (days > 0) {
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  }

  return (
    <>
      <div className="usertable-search-container">
        <div className="usertable-search-box">
          <input type="text" placeholder="Search..." />
          <img
            src={searchIcon}
            alt="Search"
            className="usertable-search-icon"
          />
        </div>
        {/* <div className="usertable-button-container">
          <button
            className="usertable-btn usertable-btn-primary"
            onClick={handleBrandButtonClick}
          >
            <img src={brandIcon} alt="Brand" className="usertable-btn-icon" />
            New Brand
          </button>
          <button
            className="usertable-btn usertable-btn-secondary"
            onClick={handleTenantButtonClick}
          >
            <img src={tenantIcon} alt="Tenant" className="usertable-btn-icon" />
            New Tenant
          </button>
        </div> */}
      </div>
      <div>
        <table className="user-manager-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Product SkuID</th>
              <th>Product Name</th>
              <th>Status</th>
              <th>Time</th>
              <th>Time Lapsed</th>
              <th style={{ textAlign: "center" }}>Location</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ticketsdata.ticket.map((order, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    {" "}
                    <img src={order.productImage[0]} height={60} width={60} />
                  </td>
                  <td>{order.productId}</td>
                  <td>{order.productName}</td>
                  <td>{getStatusStyle(order.status)}</td>
                  <td>{new Date(order.timestamp).toLocaleDateString()}</td>
                  <td>
                    {calculateTimeDifference(order.timestamp, Date.now())}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {order.location.city}, {order.location.country}
                  </td>
                  <td onClick={() => toggleRow(index)}>
                    <img
                      src={DownArrow}
                      alt="Expand"
                      className={`user-manager-expand-icon ${
                        expandedRow === index ? "rotated" : ""
                      }`}
                    />
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr className="spacer expanded">
                    <td
                      colSpan={8}
                      style={{ padding: 0, verticalAlign: "top" }}
                    >
                      <Status
                        status={order.status}
                        // handleDeleteRow={() => handleDeleteRow(index)}
                        // handleDeactivateTenant={handleDeactivateTenant}
                        // handleDeleteTenant={(tenantName) =>
                        //   handleDeleteTenant(tenantName, order.brandName)
                        // }
                        rowIndex={index}
                        tenantData={[order]}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {showTenantModal && (
        <div className="tenant-modal-overlay">
          <div className="tenant-modal">
            <button
              className="user-manager-close-button"
              onClick={handleTenantModalClose}
            >
              Close
            </button>
            <p>Add New Tenant</p>
            <form>
              <div className="user-manager-form-group">
                <label htmlFor="brandName">Brand Name</label>
                <select id="brandName" onChange={handleBrandChange}>
                  <option value="">Select Brand</option>
                  {orderData.map((order, index) => (
                    <option key={index} value={order.brandName}>
                      {order.brandName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="user-manager-form-group">
                <label>Existing Tenants</label>
                {selectedBrand ? (
                  orderData
                    .find((order) => order.brandName === selectedBrand)
                    ?.tenantName.map((tenant, tenantIndex) => (
                      <input
                        key={tenantIndex}
                        type="text"
                        value={tenant.name}
                        readOnly
                      />
                    ))
                ) : (
                  <p
                    style={{
                      fontSize: "12px",
                      fontFamily: "Roboto",
                      color: "red",
                    }}
                  >
                    Please select a brand first.
                  </p>
                )}
              </div>
              <div className="user-manager-form-group">
                <label htmlFor="newTenantName">New Tenant Name</label>
                <input type="text" id="newTenantName" />
              </div>
              <div className="user-manager-form-group user-manager-confirm-box">
                <button type="submit">Confirm</button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showBrandModal && (
        <div className="brand-modal-overlay">
          <div className="brand-modal">
            <button
              className="user-manager-close-button"
              onClick={handleBrandModalClose}
            >
              Close
            </button>
            <p> Add New Brand</p>
            <form>
              <div className="user-manager-form-group">
                <label htmlFor="brandName">Brand Name</label>
                <input
                  type="text"
                  id="brandName"
                  placeholder="Add Brand Name"
                />
              </div>

              <div className="user-manager-form-group">
                <div>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    placeholder="Add First Name"
                  />
                </div>
                <div>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Add Last Name"
                  />
                </div>
              </div>

              <div className="user-manager-form-group">
                <div>
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" placeholder="Add email id" />
                </div>
                <div>
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    placeholder="Add Phone Number"
                  />
                </div>
              </div>

              <div className="user-manager-form-group">
                <label htmlFor="tier">Tier Select</label>
                <select id="tier">
                  <option value="">Select Tier</option>
                  <option value="tier1">Tier 1</option>
                  <option value="tier2">Tier 2</option>
                  <option value="tier3">Tier 3</option>
                  <option value="tier3">Free Tier</option>
                </select>
              </div>

              <div className="user-manager-form-group user-manager-confirm-box">
                <button type="submit">Confirm</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Usertable;
