import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';

// Define initial state
const initialState = {
  value: 0,
  nfcsessions: [], 
  products: [],
  selectedOptions: "", 
  selectedbrand:'', 
  credits:0,
  tenentData:[], // Ensure this is part of the initial state
  tenantObject:[],
};

export const setSelectedOptions = createAction('counter/setSelectedOptions', (option) => ({
  payload: option
}));

export const settenantObjectOptions = createAction('counter/settenantObjectOptions', (option) => ({
  payload: option
}));

export const setSelectedbrand = createAction('counter/setSelectedbrand', (option) => ({
  payload: option
}));

export const setcredits= createAction('counter/setcredits', (option) => ({
  payload: option
}));

// Async action creator to fetch tenant data
export const fetchTenantData = createAsyncThunk('counter/fetchTenantData', async (tenant) => {
  try {
    const response = await axios.get(`https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/brand?brandname=${tenant}`);
    return response.data; // Modify as per your response structure
  } catch (error) {
    throw new Error('Error fetching tenant data:');
  }
});

// Async action creator to fetch NFC sessions
export const fetchNfcSessions = createAsyncThunk('counter/fetchNfcSessions', async (tenant) => {
  try {
    const response = await axios.get(`https://nwtgrfetjc.execute-api.ap-south-1.amazonaws.com/prod/nfcsession/?tenant=${tenant}`);
    return response.data.Items;
  } catch (error) {
    throw new Error('Error fetching NfcSessions:');
  }
});

// Async action creator to fetch products
export const fetchProducts = createAsyncThunk('counter/fetchProducts', async (tenant) => {
  try {
    const response = await axios.get(`https://xc5ph8i3p0.execute-api.ap-south-1.amazonaws.com/prod/scan/?tenant=${tenant}`);
    return response.data.Items;
  } catch (error) {
    throw new Error('Error fetching products:');
  }
});

// Define your counter slice
export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNfcSessions.fulfilled, (state, action) => {
      state.nfcsessions = action.payload;
    });
    builder.addCase(fetchTenantData.fulfilled, (state, action) => {
      state.tenentData = action.payload;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload;
    });
    builder.addCase(setSelectedOptions, (state, action) => {
      state.selectedOptions = action.payload;
    });
    builder.addCase(setSelectedbrand, (state, action) => {
      state.selectedbrand = action.payload;
    });
    builder.addCase(settenantObjectOptions, (state, action) => {
      state.tenantObject = action.payload;
    });
    builder.addCase(setcredits, (state, action) => {
      state.credits = action.payload;
    });
  },
});

// Export your action creators
export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// Export your reducer
export default counterSlice.reducer;
