import React, { useState, useEffect, useMemo, useRef } from "react";
import "./ChatBox.css";
import sendbtn from "../../../assets/Send.png";

const calculateDaysAgo = (date) => {
  const today = new Date();
  const ticketDate = new Date(date);
  const timeDiff = Math.abs(today.getTime() - ticketDate.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
};

const formatDateTime = (date, time) => {
  const dateObj = new Date(`${date} ${time}`);
  const formattedDate = dateObj.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const formattedTime = dateObj.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });
  return `${formattedDate} at ${formattedTime}`;
};

const getColorForLetter = (letter) => {
  const letters = "ABCDEF0123456789";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

const calculateTimeDifference = (start, end) => {
  const startTimestamp = new Date(start).getTime();
  const endTimestamp = new Date(end).getTime();
  const timeDifference = endTimestamp - startTimestamp;
  const seconds = Math.floor((timeDifference / 1000) % 60);
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (days > 0) {
    return `${days}d ${hours}h`;
  } else if (hours > 0) {
    return `${hours}h ${minutes}m `;
  } else if (minutes > 0) {
    return `${minutes}m `;
  } else {
    return `${seconds}s`;
  }
};

const ChatBox = ({
  email,
  id,
  status,
  subject,
  name,
  time,
  date,
  data,
  onClose,
}) => {
  const selectedTicket = data.find((ticket) => ticket.id === id);

  const senderColor = useMemo(
    () => getColorForLetter(email.slice(0, 1).toUpperCase()),
    [email]
  );
  const [newMessage, setNewMessage] = useState("");
  const [sentMessages, setSentMessages] = useState([]);
  const [ticketStatus, setTicketStatus] = useState("open");
  const [isClosing, setIsClosing] = useState(false);

  const chatMessagesRef = useRef(null);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const timestamp = new Date().getTime();
      const newConversation = {
        time: timestamp,
        message: newMessage,
        sender: "arvo",
      };
      setSentMessages([...sentMessages, newConversation]);
      setNewMessage("");

      selectedTicket.chatMessage.push(newConversation);
      sendTicketToAPI(selectedTicket);
    }
  };

  const handleStatusChange = async(e) => {
    const newStatus = e.target.value;
    setTicketStatus(e.target.value);
    console.log(newStatus);
    console.log(ticketStatus);
    if (newStatus === "closed") {
      setIsClosing(true);
      setTimeout(() => setIsClosing(false), 3000); // Show the closing message for 3 seconds
    }
    await sendTicketToAPI(selectedTicket, newStatus);

    onClose();
    window.location.reload();



  };

  const sendTicketToAPI = async (selectedTicket, newStatus) => {
    console.log(selectedTicket)
    const data = JSON.parse(localStorage.getItem("user"));
    const postdata = {
      id: selectedTicket.id,
      credtedby: selectedTicket.email,
      Status: newStatus === "closed" ? "closed" : "open",
      time: selectedTicket.timestamp,
      createdAt:Date.now(),
      brand: selectedTicket.brandName,
      ticketHeading: selectedTicket.subject,
      conversation: selectedTicket.chatMessage,
    };
    console.log(postdata)
    try {
      const response = await fetch(
        "https://bxcswtw14g.execute-api.ap-south-1.amazonaws.com/prod/arvoTickets",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postdata),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        handleSendMessage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [newMessage]);

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [sentMessages, selectedTicket]);

  const sortedMessages = [...(selectedTicket?.chatMessage || [])].sort(
    (a, b) => a.time - b.time
  );

  return (
    <div className="support-chat-box">
      <div className="support-chat-box-header">
        <button className="support-close-button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className="support-chat-box-content">
        <div>
          <h3>{subject}</h3>
          <p>Created by: {email}</p>
          <p>Status: {status}</p>
        </div>
        <div>
          <h4>Change Status</h4>
              {" "}
          <select
            className="status-dropdown"
            value={ticketStatus}
            onChange={handleStatusChange}
          >
                      <option value="open">Open</option>
                      <option value="closed">Close</option>
                   {" "}
          </select>
        </div>
             {" "}
      </div>
           {" "}
      {isClosing && (
        <div className="closing-message">
                    We are in the process of closing this ticket. Thank you for
          your patience.        {" "}
        </div>
      )}
      <div className="support-chat-messages" ref={chatMessagesRef}>
        {sortedMessages.map(({ message, sender, time }, index) => (
          <div
            key={index}
            className={`${
              sender === "arvo"
                ? "support-admin-message"
                : "support-chat-message"
            }`}
          >
            {sender !== "arvo" ? (
              <div key={index} className="brand-message">
                <div
                  className="support-sender-icon"
                  style={{ backgroundColor: senderColor }}
                >
                  {email.slice(0, 1).toUpperCase()}
                </div>

                <div className="support-message-details">
                  <div className="support-sender-name">
                    {selectedTicket?.name}
                  </div>
                  <div className="support-message-time">
                    {calculateTimeDifference(time, Date.now())}
                  </div>
                  <div className="support-message-text">{message}</div>
                </div>
              </div>
            ) : (
              <div key={index} className="user-message">
                <div
                  className="chat-admin-icon"
                  style={{ backgroundColor: "#0956CC" }}
                >
                  S
                </div>
                <div className="admin-message-details">
                  <div className="sender-name">Super Admin</div>
                  <div className="message-time">
                    {calculateTimeDifference(time, Date.now())}
                  </div>
                  <div className="message-text">{message}</div>
                </div>
              </div>
            )}
          </div>
        ))}
        {/* {sentMessages.map(({ message, time }, index) => (
          <div key={index} className="user-message">
            <div
              className="chat-admin-icon"
              style={{ backgroundColor: "#0956CC" }}
            >
              S
            </div>
            <div className="admin-message-details">
              <div className="sender-name">Super Admin</div>
              <div className="message-time">
                {calculateTimeDifference(time, Date.now())}
              </div>
              <div className="message-text">{message}</div>
            </div>
          </div>
        ))} */}
      </div>
      <div className="support-chat-input-form">
        <input
          type="text"
          className="support-chat-input"
          placeholder="Type your message here..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button
          type="button"
          className="support-send-chat-button"
          onClick={handleSendMessage}
        >
          <img src={sendbtn} alt="Send" />
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
