import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./pieChartBox.css";
import location from "../../../assets/location.png";

am4core.useTheme(am4themes_animated);

const Graphs = ({ data, chartRef, tooltipss }) => {
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = tooltipss.current;
    }
  }, [data]);

  useEffect(() => {
    var chart = am4core.create("piediv", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0;
    chart.innerRadius = am4core.percent(30);
    chart.radius = am4core.percent(50);
    chart.depth = 30;
    chart.angle = 10;
    chart.data = tooltipss.current;
    var series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "counts";
    series.dataFields.category = "tooltip";
    series.labels.template.fontSize = 10;
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [tooltipss.current]);

  return <div id="piediv" className="chartContainer"></div>;
};

const ScansByDevices = ({ data, tooltipss }) => {
  const latlong = data.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);
    return {
      latitude,
      longitude,
      size: 4,
      tooltip: item.geodata.city,
      fill: "#F00",
    };
  });

  const latlong1 = data.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);
    return {
      latitude,
      longitude,
      size: 4,
      tooltip: item.geodata.region,
      fill: "#F00",
    };
  });

  const countryCodeToName = {
    // ... (the rest of the country code to name mapping)
  };

  const latlong2 = data.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);
    const countryCode = item.geodata.country;
    const countryName = countryCodeToName[countryCode];
    return {
      latitude,
      longitude,
      size: 4,
      tooltip: countryName || countryCode,
      fill: "#F00",
    };
  });

  useEffect(() => {
    const tooltipCounts = data.length
      ? data.reduce((counts, current) => {
          const { tooltip } = current;
          if (tooltip) {
            counts[tooltip] = (counts[tooltip] || 0) + 1;
          }
          return counts;
        }, {})
      : latlong.reduce((counts, current) => {
          const { tooltip } = current;
          if (tooltip) {
            counts[tooltip] = (counts[tooltip] || 0) + 1;
          }
          return counts;
        }, {});

    tooltipss.current = Object.entries(tooltipCounts).map(
      ([tooltip, count]) => ({
        tooltip,
        counts: count,
      })
    );
  }, [data, latlong]);

  return null;
};

const PieChartBox = ({ nfcsessions }) => {
  const chartRef = useRef(null);
  const tooltipss = useRef([]);

  const session = nfcsessions || [];

  const latlong = session.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);
    return {
      latitude,
      longitude,
      size: 4,
      tooltip: item.geodata.city,
      fill: "#F00",
    };
  });

  const latlong1 = session.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);
    return {
      latitude,
      longitude,
      size: 4,
      tooltip: item.geodata.region,
      fill: "#F00",
    };
  });

  const countryCodeToName = {
    // ... (the rest of the country code to name mapping)
  };

  const latlong2 = session.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);
    const countryCode = item.geodata.country;
    const countryName = countryCodeToName[countryCode];
    return {
      latitude,
      longitude,
      size: 4,
      tooltip: countryName || countryCode,
      fill: "#F00",
    };
  });

  const [data, setData] = useState(latlong);
  const [age, setAge] = useState(1);
  const [name, setName] = useState("cities");

  const handleChange = (event) => {
    setAge(Number(event.target.value));
    if (event.target.value == 2) {
      setName("states/region");
      setData(latlong1);
    } else if (event.target.value == 3) {
      setName("countries");
      setData(latlong2);
    } else {
      setData(latlong);
      setName("cities");
    }
  };

  return (
    <div className="customBox" id="chart">
      <div
        className="titleContainer"
        style={{ position: "relative", top: "4px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img
              src={location}
              alt=""
              style={{ height: "17px", marginRight: "5px" }}
            />
            <p
              style={{
                fontFamily: "Baumans",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "18.91px",
                letterSpacing: "0.02em",
                marginTop: "9px",
                position: "relative",
                top: "5px",
              }}
            >
              Scans by {name}
            </p>
          </div>
          <div className="form-control" style={{}}>
            <label
              htmlFor="map-select"
              style={{
                fontFamily: "Baumans",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "18.91px",
                letterSpacing: "0.02em",
              }}
            >
              Map
            </label>
            <select
              style={{
                borderRadius: "20px",
                height: "30px",
                marginLeft: "4px",
                padding: "5px",
              }}
              id="map-select"
              value={age}
              onChange={handleChange}
              className="select"
            >
              <option value={1}>City</option>
              <option value={2}>State</option>
              <option value={3}>Country</option>
            </select>
          </div>
        </div>
      </div>
      <br />
      <Graphs data={data} chartRef={chartRef} tooltipss={tooltipss} />
      <ScansByDevices data={data} tooltipss={tooltipss} />
    </div>
  );
};

export default PieChartBox;
