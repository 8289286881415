import React from "react";
import "./Region.css";
import Region2charts from "../chartRegion2/Region2charts";

function Region2({ nfcsessions }) {
  return (
    <div className="region-container">
      <div className="region-content">
        <Region2charts />
      </div>
    </div>
  );
}
export default Region2;
