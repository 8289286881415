import React from "react";
import { useNavigate } from "react-router-dom";
import './Settings.css';
import Subscription from "./Subcription/Subcription";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchTenantData } from "../redux/counterSlice";
import { useDispatch } from "react-redux";



function Settings() {

const dispatch=useDispatch()
React.useEffect(()=>{
  const data = JSON.parse(localStorage.getItem("user"));
  dispatch(fetchTenantData(data.idToken.payload.name));
},[])
  
  const navigate = useNavigate(); 

  const handleNavigation = () => {
    navigate('/pricing');
  };
  const handleSupportNavigation = () => {
    navigate('/support');
  };
  const handlePrivacy = () => {
    navigate('/privacy-policies');
  };
  const handleTermCondition = () => {
    navigate('/terms-contitions');
  };
  const handleDocument = () => {
    navigate('/dev-docs');
  };


  const handelViewBill = () => {
    toast.warn(
      "No billing history",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
      );
  };

  return (
    <div className="settings">
      <ToastContainer />
      <p className="settings-header">Settings</p>
      <div className="settings-section">
        <h2 className="section-title">Account Settings</h2>
        <ul className="options-list">
          <li onClick={()=>navigate('/changePassword')}>Change Password</li>
          {/* <li>Update Profile</li> */}
          {/* <li>Privacy Settings</li> */}
        </ul>
      </div>
      <div className="settings-section">
        <h2 className="section-title">Billing and Subscription</h2>
        <ul className="options-list">
          <li onClick={handelViewBill}>View Billing History</li>
          <li onClick={handleNavigation} style={{ cursor: 'pointer' }}>Manage Subscription</li>
          {/* <li>Payment Methods</li> */}
        </ul>
      </div>
      <div className="settings-section">
        <h2 className="section-title">Support and Help</h2>
        <ul className="options-list">
          <li onClick={handleSupportNavigation} style={{ cursor: 'pointer' }}>Ticket Status</li>
          {/* <li>FAQs</li> */}
          <li onClick={handlePrivacy}>Privacy Policy</li>
          <li onClick={handleTermCondition}>Terms and Conditions</li>
          <li onClick={handleDocument}>Development Documents</li>
        </ul>
      </div>
      {/* <div className="settings-section">
        <h2 className="section-title">Currency Change Settings</h2>
        <ul className="options-list">
          <li>Change Currency</li>
          <li>View Exchange Rates</li>
          <li>Set Default Currency</li>
        </ul>
      </div> */}
    </div>
  );
}

export default Settings;
