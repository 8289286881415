import React, { useEffect, useState } from "react";
import "./Cards.css";
import arrowImage from "../../assets/arrowleft.png";
import scan from "../../assets/barcode-scan.png";
import warning from "../../assets/warning.png";
import customers from "../../assets/group.png";
import Scans from "./scantype/Scans";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Cards({ nfcsessions, selectedtenant, selectedOptions }) {
  const navigation = useNavigate();

  const [avg, setavg] = useState(0);
  const current = nfcsessions.map((item) => {
    const ipadress = item.geodata.ip;
    return {
      ipadress,
    };
  });

  function calculateIncreasePercentage(a, b) {
  
    const percentageIncrease = ((Number(b) - a) / a) * 100;
    // Check if the result is NaN or infinite
    if (isNaN(percentageIncrease) || !isFinite(percentageIncrease)) {
      return 0;
    }
    return percentageIncrease;
  }
  const tenant = localStorage.getItem("tenant").toLocaleLowerCase();

  function isDateNearToday(dateString, days) {
    const date = new Date(dateString);
    const today = new Date();
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() - days); // Get the target date by subtracting days from today

    return date < today && date >= targetDate; // Check if the date is within the range
  }

  // Get today's date
  const today = new Date();

  // Initialize variables to keep track of the nearest date and its difference
  let nearestDate;
  let minDifference = Infinity;

  if (selectedtenant && selectedtenant.coutervals) {
    // Iterate over each element in the array
    selectedtenant.coutervals.forEach((element, index) => {
      // Parse the date string into a Date object
      const date = new Date(element.time);

      // Calculate the difference in milliseconds between the parsed date and today's date
      const difference =
        Math.abs(date.getDate() - today.getDate()) +
        Math.abs(date.getMonth() - today.getMonth());
  
      // Check if this difference is smaller than the current minimum difference
      if (difference > 0 && difference < minDifference) {
        // Update the nearest date and its difference
        nearestDate = element;
        minDifference = difference;
      }
    });
  }

  // Now, nearestDate contains the element with the nearest date to today's date

  // Specify the number of previous days you want to consider
  const previousDays = 7; // Change this value as needed

  // Filter the array to get the element with a time near today
  //const filteredElement = selectedtenant.coutervals.find(obj => isDateNearToday(obj.time, previousDays))||[];
  if (selectedtenant && Array.isArray(selectedtenant.coutervals)) {
    // Filter the array to get the element with a time near today
    var filteredElement = selectedtenant.coutervals.find((obj) =>
      isDateNearToday(obj.time, previousDays)
    );
  }

  useEffect(() => {
    const ipCounts = current.reduce((counts, { ipadress }) => {
      counts[ipadress] = (counts[ipadress] || 0) + 1;
      return counts;
    }, {});

    const uniqueIPAddresses = Object.keys(ipCounts);
    const totalOccurrences = uniqueIPAddresses.reduce(
      (sum, ipAddress) => sum + ipCounts[ipAddress],
      0
    );
    const totalUniqueIPAddresses = uniqueIPAddresses.length;

    setavg(
      totalUniqueIPAddresses > 0 ? totalOccurrences / totalUniqueIPAddresses : 0
    );

    // console.log('Total occurrences:', totalOccurrences);
    // console.log('Total unique IP addresses:', totalUniqueIPAddresses);
    // console.log('Average occurrences per unique IP address:', avg);
    localStorage.setItem("avgUser", avg);
  }, [avg, nfcsessions]);

  const [increasePercentageinscan, setincreasePercentageinscan] = useState(1);
  const [increasePercentageinavgscan, setincreasePercentageinavgscan] =
    useState(1);
  // const filteredObject = {
  //   cals: filteredElement.cals.filter(item => selectedOptions.includes(item.title)),
  //   time: filteredElement.time
  // };

  let filteredObject = {
    cals: [],
    time: null,
  };

  const selectedOption = selectedOptions == "" ? "All" : selectedOptions;

  if (nearestDate && nearestDate.cals) {
    filteredObject = {
      cals: nearestDate.cals.filter(
        (item) => item && item.title && selectedOption.includes(item.title)
      ),
      time: nearestDate.time,
    };
  }

  useEffect(() => {
    console.warn(filteredObject)
    setincreasePercentageinscan(
      calculateIncreasePercentage(
        filteredObject.cals[0]?.numOccurrences !== undefined
          ? filteredObject.cals[0].numOccurrences
          : 0,
        nfcsessions.length
      )
    );

    setincreasePercentageinavgscan(
      calculateIncreasePercentage(
        filteredObject.cals[0]?.avgScans !== undefined
          ? filteredObject.cals[0].avgScans
          : 0,
        avg.toFixed(2)
      )
    );
  }, [selectedOptions, avg, filteredObject, nfcsessions]);

  // const increasePercentageinscan = calculateIncreasePercentage(selectedtenant.controlersCouts[0], nfcsessions.length);
  //const increasePercentageinavgscan = calculateIncreasePercentage(selectedtenant.controlersCouts[1], avg.toFixed(2));
  const increasePercentageinconterfiet = calculateIncreasePercentage(
    selectedtenant.controlersCouts[2],
    32
  );

  const sortedData = nfcsessions
    .slice()
    .sort((a, b) => parseInt(a.timeline) - parseInt(b.timeline));

  // Group objects by Tagid
  const groupedData = {};
  sortedData.forEach((item) => {
    if (!groupedData[item.Tagid]) {
      groupedData[item.Tagid] = [item];
    } else {
      groupedData[item.Tagid].push(item);
    }
  });
  const filteredTagIds = {};

  for (const tagId in groupedData) {
    const tagIdData = groupedData[tagId];
    const latestData = tagIdData[tagIdData.length - 1];

    let isCounterDecreasing = false;
    for (let i = tagIdData.length - 2; i >= 0; i--) {
      const previousData = tagIdData[i];
      if (latestData.decimalValue < previousData.decimalValue) {
        isCounterDecreasing = true;
        break;
      }
    }

    if (isCounterDecreasing) {
      filteredTagIds[tagId] = tagIdData;
    }
  }
  let filteredObjects = [];
  localStorage.setItem("blacklistscan", JSON.stringify(filteredTagIds));

  // Iterate over each key-value pair in the object
  for (const key in filteredTagIds) {
    if (filteredTagIds.hasOwnProperty(key)) {
      // Filter the array of objects associated with the current key
      const objects = filteredTagIds[key].filter(
        (obj) => obj.title === selectedOptions
      );
   
      // Concatenate the filtered objects to the result array
      filteredObjects = filteredObjects.concat(key);
    }
  }

 
  if (selectedOptions == "All" || selectedOptions == "") {
    localStorage.setItem(
      "apival",
      JSON.stringify({
        title: "All",
        avgScans: avg,
        numOccurrences: nfcsessions.length,
      })
    );
  }

  // filteredObjects will contain an array of objects with the matching title

  return (
    <div className="cards-container">
      <div className="card card-one">
        <div className="top-container">
          <img src={scan} alt="Scan icon" className="total-scans-icon" />
          <div className="total-scans-label">Total Scans</div>
        </div>
        <div className="bottom-container">
          <div className="total-scans-number">{nfcsessions.length}</div>
          <div className="percentage-change-container">
            <div className="percentage-change">
              +{increasePercentageinscan.toFixed(1)}%
              
            </div>
            <img src={arrowImage} alt="Arrow" className="arrow-image" />
            <div className="from-yesterday-label">from yesterday</div>
          </div>
        </div>
      </div>
      <Scans nfcsessions={nfcsessions} />
      <div className="card card-three">
        <div className="top-container">
          <img src={customers} alt="Scan icon" className="total-scans-icon" />
          <div className="total-scans-label">Scans per Customer</div>
        </div>
        <div className="bottom-container">
          <div className="total-scans-number">{avg.toFixed(2)}</div>
          <div className="percentage-change-container">
            <div
              className="percentage-change"
              style={{
                color: increasePercentageinavgscan > 0 ? "green" : "red",
              }}
            >
              {increasePercentageinavgscan > 0
                ? `+${increasePercentageinavgscan.toFixed(1)}%`
                : `-${increasePercentageinavgscan.toFixed(1)}%`}
            </div>
            <img
              src={arrowImage}
              alt="Arrow"
              className="arrow-image"
              style={{
                transform:
                  increasePercentageinavgscan > 0
                    ? "rotate(0deg)"
                    : "rotate(90deg)",
              }}
            />
            <div className="from-yesterday-label">from yesterday</div>
          </div>
        </div>
      </div>
      <div className="card card-four">
        <div className="top-container">
          <img src={warning} alt="Scan icon" className="total-scans-icon" />
          <div className="total-scans-label">Counterfeit Reports</div>
        </div>
        <div className="bottom-container">
          <div className="total-scans-number">{filteredObjects.length}</div>
          <div className="percentage-change-container">
            {/* <div className="percentage-change" style={{color:increasePercentageinconterfiet>0?'red':'green'}}>{increasePercentageinconterfiet>0?`+${increasePercentageinconterfiet.toFixed(1)}%`:`-${increasePercentageinconterfiet.toFixed(1)}%`}</div> */}

            <div
              className="from-yesterday-label"
              onClick={() => navigation("/counterfeits")}
            >
              {filteredObjects.length != 0 ? "check complete report " : null}
              {filteredObjects.length != 0 ? (
                <img
                  src={arrowImage}
                  alt="Arrow"
                  className="arrow-image"
                  style={{ transform: "rotate(0deg)" }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
