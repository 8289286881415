import React, { useState, useRef } from "react";
import "./Map.css"; // Ensure this CSS file is correctly linked for styling
import Maps from "./AmChartsMapAnalytics";
import IndianMapChart from "./IndiaMap";
import location from "../../../assets/location.png";
const Map = ({ nfcsessions }) => {
  const [age, setAge] = useState(1);

  const handleChange = (event) => {
    setAge(Number(event.target.value)); // Convert string to number
  };
  //const nfcsessions = JSON.parse(localStorage.getItem('nfcsessions')) || [];

  const transformedData = useRef([]);
  transformedData.current = nfcsessions.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);

    return {
      latitude,
      longitude,
      size: 4,
      tooltip: item.geodata.city /* specify your tooltip logic here */,
      fill: "#F00" /* specify your fill logic here */,
    };
  });

  localStorage.setItem("latlong", JSON.stringify(transformedData.current));
  const indiantransformData = useRef([]);

  const cityState = nfcsessions.map((item) => {
    const state = item.geodata.region;
    const city = item.geodata.city;
    return { state, city };
  });

  // console.log("......", cityState);

  const result = [];

  // Loop through the data array
  cityState.forEach((item) => {
    const { state, city } = item;

    // Check if the state already exists in the result array
    const stateIndex = result.findIndex((entry) => entry.state === state);

    if (stateIndex === -1) {
      // If the state doesn't exist, add a new entry with an array containing the current city and count 1
      result.push({ state, city: [{ city, count: 1 }] });
    } else {
      // If the state exists, check if the city exists in the state's array
      const cityIndex = result[stateIndex].city.findIndex(
        (entry) => entry.city === city
      );

      if (cityIndex === -1) {
        // If the city doesn't exist, add a new entry with count 1
        result[stateIndex].city.push({ city, count: 1 });
      } else {
        // If the city exists, increment its count
        result[stateIndex].city[cityIndex].count++;
      }
    }
  });

  const indianStates = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const indianDataforTable = result
    .filter((item) => indianStates.includes(item.state))
    .map((item) => {
      // Change "Jammu and Kashmir" to "Jammu & Kashmir"
      const state =
        item.state === "Jammu and Kashmir" ? "Jammu & Kashmir" : item.state;
      return { state, city: item.city };
    });

  // const defaultData = data || "Your default or internally generated data here";

  // Assuming nfcsessions is an array of objects with a 'geodata' property containing 'region'
  const regionCounts = nfcsessions.reduce((counts, item) => {
    const region = item.geodata.region;

    if (region) {
      // If the region exists, increment its count or initialize it to 1
      counts[region] = (counts[region] || 0) + 1;
    }

    return counts;
  }, {});

  // Now, transform the counts into the desired structure
  const indiantransform = Object.entries(regionCounts).map(
    ([region, count]) => ({
      name: region,
      value: count,
    })
  );

  //    const indiantransformed = indiantransform.map(item => ({
  //     [item.name]: { value: item.value },
  //   }));

  indiantransformData.current = indiantransform.reduce((acc, current) => {
    const { name, value } = current;
    acc[name] = { value };
    return acc;
  }, {});

  // Filter out the Indian states from the data
  const indianData = [];
  indianStates.forEach((state) => {
    if (indiantransformData.current[state]) {
      // Change "Jammu and Kashmir" to "Jammu & Kashmir"
      const key = state === "Jammu and Kashmir" ? "Jammu & Kashmir" : state;
      indianData[key] = indiantransformData.current[state];
    }
  });


  return (
    <div className="map-container" id="chart">
      <div className="header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={location}
            alt=""
            style={{ height: "17px", marginRight: "5px" }}
          />
          <p
            style={{
              fontFamily: "Baumans",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "18.91px",
              letterSpacing: "0.02em",
            }}
          >
            Marketing 
          </p>
        </div>
     
      </div> 
            <IndianMapChart data={indianData} />
    </div>
  );
};

export default Map;
