import React from "react";
import "./Status.css";
import brandImage from "../../../assets/7b93c90dfb3f23843517dc882082c49e.jpeg";
import report from "../../../assets/report.png";
import Timeline from "../Timeline/Timeline";

interface StatusProps {
  productName: string;
  itemNames: string[];
  status: "Pending" | "Delivered" | "Download";
  address : string;
}

const Status: React.FC<StatusProps> = ({ productName, itemNames, status, address }) => {
  return ( <div className="status-wrapper">
    <div className="status-container">
      <div className="status-left-section">
        <img
          src={brandImage}
          alt="Product"
          className="status-brand-image"
        />
        <div className="status-product-details">
          <h4 className="status-product-name">{productName}</h4>
          <div>
              <p className="status-address">
                {address}
              </p>
          </div>
        </div>
      </div>
      <div className="status-right-section">
        <div className="timeline-status-container">
          <Timeline status={status} itemNames={itemNames} />
        </div>
        <div className="tracking-id">
          Tracking ID: <span className="tracking-id-number">342342343242</span>
        </div>
      </div>
    </div>
        <div className="actions-container">
          <button className="cancel-order-button">Cancel Order</button>
        </div>
       </div>
  );
};

export default Status;