// src/config.js

export default {
    region: "ap-south-1",
    userPoolId: "ap-south-1_d85PwMBBv",
    userPoolWebClientId: "64a2d0ric5du4dpcfo8j0ksvgj",
    identityPoolId: "YOUR_IDENTITY_POOL_ID",
    clientSecret: 'YOUR_CLIENT_SECRET',
  };
  
