import React from "react";
import './Settings.css'
import Subscription from "./Subcription/Subcription";

function Settings() {

  return (
    <div className="settings">
      <span className="settings-header">Settings</span>
      <div className="settings-section">
        <p className="section-title">Account Settings</p>
        <ul className="options-list">
          {/* Add account settings options here */}
          <li>Change Password</li>
          <li>Update Profile</li>
          <li>Privacy Settings</li>
        </ul>
      </div>
      <div className="settings-section">
        <p className="section-title">Support and Help</p>
        <ul className="options-list">
          {/* Add support and help options here */}
          <li>Contact Support</li>
          <li>FAQs</li>
          <li>Training Videos</li>
        </ul>
      </div>
    </div>
  );
}

export default Settings;