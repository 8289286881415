import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './Support.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Open from './open/Open';
import Closed from './closed/Closed';
import AllTickets from './AllTickets/AllTickets';
import TabWithCount from './TabWithCount/TabWithCount';
import Chatbot from "react-chatbot-kit";
import config from "../chatbotConfig";
import MessageParser from "../MessageParser";
import ActionProvider from "../ActionProvider";
import "react-chatbot-kit/build/main.css";

function Support() {
  const [ticketData, setTicketData] = useState([]);
  const [showChatbot, setShowChatbot] = useState(false);
  const chatbotRef = useRef(null);

  const handleClickOutside = (event) => {
    if (chatbotRef.current && !chatbotRef.current.contains(event.target)) {
      setShowChatbot(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const datas = JSON.parse(localStorage.getItem('user') || '{}');
        const response = await axios.get(`https://bxcswtw14g.execute-api.ap-south-1.amazonaws.com/prod/arvoTickets/?id=${datas.idToken.payload.name}`);
        setTicketData(response.data.Items);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  console.log(ticketData)

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const getDeadline = (timestamp) => {
    const date = new Date(timestamp);
    date.setDate(date.getDate() + 2);
    return date.getTime();
  };

  const convertTicketData = (ticketData) => {
    return ticketData.map((ticket) => ({
      name: ticket.credtedby.split('@')[0],
      id: ticket.TicketID,
      time: formatTime(ticket.time),
      date: formatDate(ticket.time),
      timestamp: ticket.time,
      email: ticket.credtedby,
      subject: ticket.ticketHeading,
      brandName: ticket.brand,
      status: ticket.Status,
      deadline: getDeadline(ticket.time),
      chatMessage: ticket.conversation,
    }));
  };

  const formattedTicketData = convertTicketData(ticketData);
  console.log(formattedTicketData)

  useEffect(() => {
    const tabList = document.querySelector('.react-tabs__tab-list');
    if (tabList) {
      const totalTabWidth = Array.from(tabList.children)
        .filter((child) => child.nodeName !== '#text')
        .reduce((acc, tab) => acc + tab.offsetWidth, 0);
      const tabListElement = tabList;
      tabListElement.style.width = `${totalTabWidth}px`;
      tabListElement.style.height = '36px';
    }
  }, []);

  return (
    <div className="superadmin-tickets" style={{ paddingBottom: '20px', paddingTop: '20px' }}>
      <div style={{ display: "flex", justifyContent: 'space-between' }}>
        <span className="superadmin-ticket-header">Tickets</span>
        <button onClick={() => setShowChatbot(!showChatbot)} className='raise-ticket'>Raise Ticket</button>
        {showChatbot && (
          <div className="chatbot-container" ref={chatbotRef}>
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
          </div>
        )}
      </div>
      <Tabs style={{ marginTop: '20px' }}>
        <TabList style={{ marginBottom: '30px' }}>
          <Tab style={{ display: 'flex', justifyContent: 'center' }}>
            <TabWithCount tabName="Open" status="open" statusColor="orange" ticketData={formattedTicketData} />
          </Tab>

          <Tab style={{ display: 'flex', justifyContent: 'center' }}>
            <TabWithCount tabName="Closed" status="closed" statusColor="#67d269" ticketData={formattedTicketData} />
          </Tab>
          <Tab>All</Tab>
        </TabList>
        <TabPanel>
          <Open data={formattedTicketData} />
        </TabPanel>
        <TabPanel>
          <Closed data={formattedTicketData} />
        </TabPanel>
        <TabPanel>
          <AllTickets data={formattedTicketData} />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default Support;
