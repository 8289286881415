import React from "react";
import "./Logistic.css";
import Logisticimg from "../../../../assets/Logistic.png";
import trendup from "../../../../assets/Trend Up.png";
import trendown from "../../../../assets/Trend Down.png";

function Logistic({filteredData}) {
  // Calculate the total length of orderdetials across all objects
 // console.log(filteredData)
const totalOrderDetailsLength = filteredData
? filteredData.reduce((total, obj) => total + obj.orderdetials.length, 0)
: 0;
// Initialize total sum
let totalQrCodeDownloaded = 0;
let totalQrCodeorder = 0;

// Initialize counters
let deliveredCount = 0;
let cancelledCount = 0;
let pendingCount = 0;


// Iterate over the array of objects  
filteredData.forEach((item) => {
  // Check if orderdetails array is defined and not empty
  if (item.orderdetials && item.orderdetials.length > 0) {
    // Iterate over the orderdetails array
    item.orderdetials.forEach((detail) => {
      // Check if the status is 'delivered'
      if (detail.status === 'Delivered') {
        // Increment deliveredCount   
        deliveredCount++;
      }
      // Check if the status is 'cancel'
      else if (detail.status === 'cancel') {
        // Increment cancelledCount
        cancelledCount++;
      }
      else {
        // Increment cancelledCount
        pendingCount++;
      }
    });
  }
});

// Now deliveredCount contains the count of delivered orders and cancelledCount contains the count of cancelled orders
console.log('Delivered Orders Count:', deliveredCount);
console.log('Cancelled Orders Count:', cancelledCount);
// Iterate over the array of objects
filteredData.forEach((item) => {
  // Check if creditHistory is defined and not empty
  if (item.creditHistory && item.creditHistory.length > 0) {
    // Iterate over the creditHistory array
    item.creditHistory.forEach((historyItem) => {
      // Check if credit is 'used'
      if (historyItem.credit === 'used') {
        totalQrCodeorder+=1;
        // Convert qrcodedownloaded to a number and add it to the total sum
        totalQrCodeDownloaded += parseInt(historyItem.qrcodedownloaded);
      }
    });
  }
});

// Now totalQrCodeDownloaded contains the sum of all qrcodedownloaded where credit is 'used'
console.log('Total QR Codes Downloaded:', totalQrCodeDownloaded);
  console.log(totalQrCodeDownloaded);
  return (
    <div
      style={{
        height: "500px",
        width: "60%",
        border: "none",
        borderRadius: "11px",
        padding: "10px",
        background: "white",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={Logisticimg} style={{ width: "19px", marginRight: "5px" }} alt="" />
        <span className="logistic-header">Logistic</span>
      </div>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
       <div
        style={{
            height: "50%",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
            <span className="logistic-main-header">NFC Orders</span>
          <span className="logistic-font-main"> {totalOrderDetailsLength}</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ width: "16px" }} src={trendup} alt="" />
            <span className="logistic-percent">21%</span>
          </div>
          <span className="logistic-past-timeline">from past month</span>
        </div>
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
            <span className="logistic-main-header">Pending Orders</span>
          <span className="logistic-font-main"> {pendingCount}</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ width: "16px" }} src={trendown} alt="" />
            <span className="logistic-percent">14%</span>
          </div>
          <span className="logistic-past-timeline">from past month</span>
        </div>
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
            <span className="logistic-main-header">Cancelled Orders</span>
          <span className="logistic-font-main"> {cancelledCount}</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ width: "16px" }} src={trendown} alt="" />
            <span className="logistic-percent">9%</span>
          </div>
          <span className="logistic-past-timeline">from past month</span>
        </div>
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
            <span className="logistic-main-header">Delivered</span>
          <span className="logistic-font-main"> {deliveredCount}</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ width: "16px" }} src={trendup} alt="" />
            <span className="logistic-percent">21%</span>
          </div>
          <span className="logistic-past-timeline">from past month</span>
        </div>
    
      </div>
      <div
        style={{
          width: "95%",
          height: "1px",
          backgroundColor: "black",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
        <div
        style={{
            height: "50%",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
            <span className="logistic-main-header">QR Orders</span>
          <span className="logistic-font-main"> {totalQrCodeorder}</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ width: "16px" }} src={trendup} alt="" />
            <span className="logistic-percent">21%</span>
          </div>
          <span className="logistic-past-timeline">from past month</span>
        </div>
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
            <span className="logistic-main-header">Downloads</span>
          <span className="logistic-font-main"> {totalQrCodeDownloaded}</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ width: "16px" }} src={trendown} alt="" />
            <span className="logistic-percent">14%</span>
          </div>
          <span className="logistic-past-timeline">from past month</span>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Logistic;