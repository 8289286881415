import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import barcodeTypes from "./barcode";

// Styled Components
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background-color: #f0f0f0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%; /* Increased width */
  background-color: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 12px 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  color: white;
  box-shadow: 4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset;
  border: none;
  border-radius: 24px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  font-family: Roboto;

  &:hover {
    background: linear-gradient(92.49deg, #1a4e9f 0%, #0956cc 100%);
  }
`;


const Message = styled.p`
  margin-top: 20px;
  font-size: 16px;
  color: ${(props) => (props.error ? "red" : "green")};
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: white;
  padding: 30px; /* Increased padding for larger appearance */
  border-radius: 20px; /* Smoother corners */
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 600px; /* Increased width for larger popup */
  height:640px;
  position: relative;
  z-index: 10000;
`;

const DownloadButton = styled.a`
  display: inline-block;
  padding: 12px 24px;
  background: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px; /* More space from the top */
  right: 20px; /* Adjusted position */
  background: none;
  border: none;
  font-size: 25px; /* Larger for better visibility */
  font-weight: bold;
  cursor: pointer;
  color: #333;
`;

const FilePreview = styled.iframe`
  width: 100%;
  height: 400px; /* Increased height for better viewing */
  margin-top: 20px;
`;

const QRCodePreview = styled.img`
  width: 100%;
  max-height: 400px; /* Increased preview size */
  object-fit: contain;
  margin-top: 20px;
`;

const QRCodeForm = () => {
  const [formData, setFormData] = useState({
    url: "",
    num_codes: 1,
    format: "pdf",
    center_text: "",
    barcode_type: "qrcode",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setDownloadUrl("");
  
    try {
      const response = await axios.post(
        "https://g8x0ma2o9f.execute-api.ap-south-1.amazonaws.com/prod/createqrcodedatamatrix",
        formData
      );
  
      if (response.status === 200) {
        if (typeof response.data === "object" && response.data.downloadUrl) {
          setMessage("QR codes generated successfully!");
          setDownloadUrl(response.data.downloadUrl);
          setShowPopup(true); // Show popup when successful
        } else if (response.data.body) {
          const parsedBody = JSON.parse(response.data.body);
          if (parsedBody.downloadUrl) {
            setMessage("QR codes generated successfully!");
            setDownloadUrl(parsedBody.downloadUrl);
            setShowPopup(true); // Show popup when successful
          } else {
            setMessage("Failed to generate QR codes.");
          }
        } else {
          setMessage("Unexpected response format or missing data.");
        }
      } else {
        setMessage("API responded with an error status.");
      }
    } catch (error) {
      console.error("Error generating QR codes:", error);
      setMessage("An error occurred while generating QR codes.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper style={{backgroundColor:'#e4edfa'}}>
      <Container style={{backgroundColor:'white'}}>
        <h1 style={{fontFamily:"Baumans"}}>Generate ARVO Codes</h1>
        <form onSubmit={handleSubmit} noValidate>
          <FormGroup>
            <Label htmlFor="url">URL</Label>
            <Input
              type="url"
              name="url"
              value={formData.url}
              onChange={handleInputChange}
              required
              pattern="https://.*"
              placeholder="Enter a valid URL starting with https://"
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="num_codes">Number of Codes</Label>
            <Input
              type="number"
              name="num_codes"
              value={formData.num_codes}
              onChange={handleInputChange}
              required
              min="1"
              placeholder="Number of codes"
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="format">Format</Label>
            <Select
              name="format"
              value={formData.format}
              onChange={handleInputChange}
            >
              <option value="pdf">PDF</option>
              <option value="png">PNG</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="center_text">Center Text</Label>
            <Input
              type="text"
              name="center_text"
              value={formData.center_text}
              onChange={handleInputChange}
              required
              placeholder="Enter center text"
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="barcode_type">Barcode Type</Label>
            <Select
              name="barcode_type"
              value={formData.barcode_type}
              onChange={handleInputChange}
            >
              {barcodeTypes.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </Select>
          </FormGroup>

          <Button type="submit" disabled={loading}>
            {loading ? "Generating..." : "Generate QR Codes"}
          </Button>
        </form>

        {message && <Message>{message}</Message>}

        {showPopup && (
          <PopupOverlay>
            <PopupContent>
              <CloseButton onClick={() => setShowPopup(false)}>&times;</CloseButton>
              <h2>ARVO Code</h2>
              {formData.format === "pdf" ? (
                <FilePreview src={downloadUrl} title="QR Code Preview" />
              ) : (
                <QRCodePreview src={downloadUrl} alt="QR Code" />
              )}

              <DownloadButton href={downloadUrl} download target="_blank">
                Download ARVO Code
              </DownloadButton>
            </PopupContent>
          </PopupOverlay>
        )}
      </Container>
    </Wrapper>
  );
};

export default QRCodeForm;
