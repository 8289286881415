import React from 'react'
import logout from '../../../../assets/Logout.png';
import './Profile.css'

function Profile() {
  return (
    
      <div>

      </div>
  )
}

export default Profile
