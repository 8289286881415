import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './CompanySearch.css'; // Import the CSS file

const CompanySearch = ({ onSelectCompany }) => {
  const [searchLetter, setSearchLetter] = useState('');
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState('');
  const dropdownRef = useRef(null);

  const handleSearch = async (query) => {
    try {
      const response = await axios.get(`https://en.wikipedia.org/w/api.php`, {
        params: {
          action: 'query',
          list: 'search',
          srsearch: `${query} company`,
          format: 'json',
          origin: '*'
        }
      });
      setCompanies(response.data.query.search);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setError('Error fetching companies');
    }
  };

  const handleChange = (e) => {
    const query = e.target.value;
    setSearchLetter(query);
    if (query.length >= 2) { // Change the condition to >= 2
      handleSearch(query);
      onSelectCompany({ title: query });
    } else {
      setCompanies([]);
      // Set brand name only if user hasn't selected any option from the dropdown
      onSelectCompany({ title: query });
    }
  };
  

  const handleSelect = (company) => {
    onSelectCompany({ title: company.title });
    setSearchLetter(company.title);
    setCompanies([]);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCompanies([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="company-search" ref={dropdownRef}>
      <input
        type="text"
        value={searchLetter}
        onChange={handleChange}
        placeholder="Enter your brand name"
        className="box-signup-input"
      />
      {error && <p className="error-message">{error}</p>}
      {companies.length > 0 && (
        <ul className="company-dropdown">
          {companies.map((company, index) => (
            <li key={index} onClick={() => handleSelect(company)} className="company-dropdown-item">
              {company.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CompanySearch;
