import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css"; // Import CSS file for styling
import Chart from "react-apexcharts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
// import { CircleBullet } from "@amcharts/amcharts4/core";

const TicketDashboard = ({ ticketsdata }) => {
  const [values, setvalues] = useState([0, 0, 0, 0]);

  const [donutChartData, setDonutChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["Pending", "Closed", "Escalated"],
      legend: {
        position: "bottom", // Position the legend below the chart
      },
    },
  });

  const [chartDatapie, setchartDatapie] = useState({
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (ticketsdata && ticketsdata.ticket) {
      const statusCount = ticketsdata.ticket.reduce((acc, cur) => {
        acc[cur.status] = (acc[cur.status] || 0) + 1;
        return acc;
      }, {});

      const series = ["escalated", "closed", "irrelevant" ,"pending"].map(
        (label) => statusCount[label] || 0
      );
      setvalues(series);
      console.log(series);

      setDonutChartData({
        series,
        options: {
          chart: {
            type: "donut",
          },
          labels: ["Escalated", "Closed", "Irrelevant","Pending"],
          legend: {
            position: "bottom",
          },
        },
      });

      // Extract the city values and count their occurrences
      const cityCounts = ticketsdata.ticket
        .map((ticket) => ticket.location.city)
        .reduce((acc, city) => {
          acc[city] = (acc[city] || 0) + 1;
          return acc;
        }, {});

      // Create series from the counts
      const series2 = Object.values(cityCounts);

      // Create labels from the city names
      const labels2 = Object.keys(cityCounts);

      console.log(cityCounts, series2, labels2);

      setchartDatapie({
        series: series2,
        options: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: labels2,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      });
    }
  }, [ticketsdata]);

  useEffect(() => {
    // Create chart instance
    if (ticketsdata && ticketsdata.ticket) {
      // Group the data by productName and count occurrences
      const groupedData = ticketsdata.ticket.reduce((acc, item) => {
        const { productName, productImage } = item;
        acc[productName] = acc[productName] || {
          name: productName,
          steps: 0,
          href: productImage[0],
        };
        acc[productName].steps++;
        return acc;
      }, {});

      // Convert grouped data to array format
      const result = Object.values(groupedData);
      console.log(result);

      am4core.useTheme(am4themes_animated);

      // Create chart instance
      let chart = am4core.create("chartdivticketproduct", am4charts.XYChart);

      // Hide initial fade-in
      chart.hiddenState.properties.opacity = 0;

      // Padding
      chart.paddingBottom = 30;

      // Data
      chart.data = result;

      // X Axis
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "name";
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.labels.template.dy = 35;
      categoryAxis.renderer.tooltip.dy = 35;

      // Y Axis
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.fillOpacity = 0.3;
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.min = 0;
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.baseGrid.strokeOpacity = 0;

      // Column Series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "steps";
      series.dataFields.categoryX = "name";
      series.tooltipText = "{valueY.value}";
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.dy = -6;
      series.columnsContainer.zIndex = 100;

      // Column Template
      let columnTemplate = series.columns.template;
      columnTemplate.width = am4core.percent(50);
      columnTemplate.maxWidth = 66;
      columnTemplate.column.cornerRadius(60, 60, 10, 10);
      columnTemplate.strokeOpacity = 0;

      // Heat Rules
      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        dataField: "valueY",
        min: am4core.color("#e5dc36"),
        max: am4core.color("#5faa46"),
      });
      series.mainContainer.mask = undefined;

      // XY Cursor
      let cursor = new am4charts.XYCursor();
      chart.cursor = cursor;
      cursor.lineX.disabled = true;
      cursor.lineY.disabled = true;
      cursor.behavior = "none";

      // Circle Bullet
      let bullet = columnTemplate.createChild(am4charts.CircleBullet);
      bullet.circle.radius = 30;
      bullet.valign = "bottom";
      bullet.align = "center";
      bullet.isMeasured = true;
      bullet.mouseEnabled = false;
      bullet.verticalCenter = "bottom";
      bullet.interactionsEnabled = false;

      // Hover State
      let hoverState = bullet.states.create("hover");
      let outlineCircle = bullet.createChild(am4core.Circle);
      outlineCircle.adapter.add("radius", function (radius, target) {
        let circleBullet = target.parent;
        return circleBullet.circle.pixelRadius + 10;
      });

      // Image
      let image = bullet.createChild(am4core.Image);
      image.width = 60;
      image.height = 60;
      image.horizontalCenter = "middle";
      image.verticalCenter = "middle";
      image.propertyFields.href = "href";

      image.adapter.add("mask", function (mask, target) {
        let circleBullet = target.parent;
        return circleBullet.circle;
      });

      // Previous Bullet
      let previousBullet;
      chart.cursor.events.on("cursorpositionchanged", function (event) {
        let dataItem = series.tooltipDataItem;

        if (dataItem.column) {
          let bullet = dataItem.column.children.getIndex(1);

          if (previousBullet && previousBullet != bullet) {
            previousBullet.isHover = false;
          }

          if (previousBullet != bullet) {
            let hs = bullet.states.getKey("hover");
            hs.properties.dy = -bullet.parent.pixelHeight + 30;
            bullet.isHover = true;

            previousBullet = bullet;
          }
        }
      });

      return () => {
        chart.dispose();
      };
    }
  }, [ticketsdata]);

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018-09-19T00:00:00.000Z",
          "2018-09-19T01:30:00.000Z",
          "2018-09-19T02:30:00.000Z",
          "2018-09-19T03:30:00.000Z",
          "2018-09-19T04:30:00.000Z",
          "2018-09-19T05:30:00.000Z",
          "2018-09-19T06:30:00.000Z",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });

  const lineChartData1 = {
    options: {
      chart: {
        id: "chart1",
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
      },
    },
    series: [
      {
        name: "Avg. FRT",
        data: [65, 59, 80, 81, 56, 55, 40],
      },
    ],
  };

  return (
    <div>
      <div className={styles.dashboardContainer} style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* Box with Current Date */}
          <div
            className={`${styles.box} ${styles.topLeft} ${styles.border}`}
            style={{ height: "100px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 className={`${styles.headingLables}`}>Date</h3>
              <h4>{new Date().toDateString()}</h4>
            </div>
          </div>
          {/* Box with Average Tickets Reply Time */}
          <div
            className={`${styles.box} ${styles.topRight} ${styles.border}`}
            style={{ width: "350px", height: "350px" }}
          >
            <h3 className={`${styles.headingLables}`}>
              Average Tickets Reply Time
            </h3>
            <div id="chartdivticketproduct" style={{ width: "100%", height: "250px" }}></div>
            {/* <Chart options={lineChartData1.options} series={lineChartData1.series} type="line" height={250} />    */}
            {/* <canvas id="chart3"></canvas> */}
            {/* You can add dynamic values for Avg. FRT, Response Time, and Resolution Time here */}
          </div>
        </div>
        {/* Main Box with Donut Chart */}

        <div className={`${styles.box} ${styles.leftBox} ${styles.border}`}>
          <h2 className={`${styles.headingLables}`}>Tickets By Status</h2>
          <Chart
            options={donutChartData.options}
            series={donutChartData.series}
            type="donut"
            height={400}
          />
        </div>

        <div className={`${styles.box} ${styles.rightBox} ${styles.border}`}>
          <h2 className={`${styles.headingLables}`}>Ticket Counts</h2>
          <div className={styles.subBox}>
            <p>Pending</p>
            <p>{values[3]}</p>
          </div>    
          <div className={styles.subBox1}>
            <p>Closed</p>
            <p>{values[1]}</p>
          </div>
          <div className={styles.subBox2}>
            <p>Unassigned</p>
            <p>{values[3]-values[1]-values[0]}</p>
          </div>
          <div className={styles.subBox3}>
            <p>Escalated</p>
            <p>{values[0]}</p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          className={`${styles.box}`}
          style={{ width: "50%", height: "350px" }}
        >
          <h2 className={`${styles.headingLables}`}>Ticket by location</h2>

          <Chart
            options={chartDatapie.options}
            series={chartDatapie.series}
            type="pie"
            width={400}
          />
        </div>
        <div
          className={`${styles.box}`}
          style={{ width: "50%", height: "350px" }}
        >
          <h2 className={`${styles.headingLables}`}>Tickets by Day of week</h2>

          <Chart
            options={chartData.options}
            series={chartData.series}
            type="area"
            height={250}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketDashboard;
