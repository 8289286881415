import React from 'react';
import './Modal.css'; // Ensure you have this CSS file for styling

const ProductModal = ({ isOpen, onClose, selectedProduct = {} }) => {
  if (!isOpen) return null;

  const {
    link = [], // Assuming it's an array with at least one link
    photo = [],
    headingcolor = '#000',
  } = selectedProduct;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="mobile-screen-container">
          <div className="mobile-screen-header" style={{ backgroundColor: headingcolor }}>
            <h3>Product Page</h3>
          </div>
          <div className="mobile-screen-body">
            <iframe
              src={link[0]}
              title="Product Page"
              className="mobile-screen-iframe"
              sandbox="allow-scripts allow-same-origin"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
