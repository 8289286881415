import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import ReloadIcon from "../assets/loading.png";
import DownArrowIcon from "../assets/dropdown.png";
import Cards from "./cards/Cards";
import Region from "./analyticaldata/region/Region";
import Region2 from "./analyticaldata/Region2/Region2";
import Graph from "./analyticaldata/graph/Graph";
import Piechart from "./analyticaldata/piechart/Piechart";
import CustomTable from "./analyticaldata/tables";
import axios from "axios";
//import type { RootState } from '../redux/store'
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  fetchNfcSessions,
  fetchProducts,
} from "../redux/counterSlice";
import CustomDatePicker from "./datepicker/CustomDatePicker";
import Sales from "../Products/ProductDetail/Sales/Sales";

function SalesDashboard() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Last 365 days");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(null);
  // const [selectedOption, setSelectedOption] = useState("Today");
  const [endDate, setEndDate] = useState(null);
  const [buttonText, setButtonText] = useState("");
  const nfcsessions = useSelector((state) => state.counter.nfcsessions);
  const selectedOptions = useSelector((state) => state.counter.selectedOptions);
  const product = useSelector((state) => state.counter.products);
  const dropdownRef = useRef(null);
  const tenentData =useSelector((state)=> state.counter.tenentData)
  const tenantdata = JSON.parse(localStorage.getItem("tenantdata"));
  const tenant = localStorage.getItem("tenant").toLowerCase();
  var selectedtenant = tenentData.find(function (order) {
    return order.employeeid === tenant;
  });
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setShowDatePicker(false); // Close the date picker when toggling dropdown
  };
  
  const onCancelDatePicker = () => {
    setShowDatePicker(false); // Close the date picker when canceling
  };
  

  const onSelectDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    setShowDatePicker(false);
    setDropdownOpen(false);

    if (start && end) {
      const startDateString = start.toLocaleDateString();
      const endDateString = end.toLocaleDateString();
      setButtonText(`${startDateString} - ${endDateString}`);
    } else {
      setButtonText("Custom Date Range");
    }
  };

  const count = useSelector((state) => state.counter.value);
  const [isRotating, setIsRotating] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch fetchNfcSessions action when the component mounts
    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant)); // Replace "tenant" with your actual tenant value
  }, [dispatch]);

  const [timeval, settimeval] = useState(Date.now());

  // const filterNfcSessions = () => {
  //   if (!selectedOptions || selectedOptions === "All") {
  //     // If selectedOptions is undefined or 'ALL', return all nfcsessions
  //     return nfcsessions;
  //   } else {
  //     // Otherwise, filter nfcsessions based on selectedOptions
  //     return nfcsessions.filter((item) => item.title === selectedOptions);
  //   }
  // };

  const getDateComponents = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1, // Months are 0-based
      day: date.getDate(),
    };
  };
 
  const filterNfcSessions = () => {
    if (!selectedOptions || selectedOptions === "All") {
     
      const today = Date.now();
      // Get current date components
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Months are 0-based
      const currentDay = currentDate.getDate();

      // Filter nfcsessions based on selectedOption
      switch (selectedOption) {
        case "Today":
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            return (
              year === currentYear &&
              month === currentMonth &&
              day === currentDay
            );
          });

        case "Last 15 Days":
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            const itemDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript
            const fifteenDaysAgo = new Date(
              currentDate.getTime() - 15 * 24 * 60 * 60 * 1000
            );
            return itemDate >= fifteenDaysAgo && itemDate <= currentDate;
          });

        case "Last 30 Days":
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            const itemDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript
            const thirtyDaysAgo = new Date(
              currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
            );
            return itemDate >= thirtyDaysAgo && itemDate <= currentDate;
          });

        case "Last 90 Days":
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            const itemDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript
            const ninetyDaysAgo = new Date(
              currentDate.getTime() - 90 * 24 * 60 * 60 * 1000
            );
            return itemDate >= ninetyDaysAgo && itemDate <= currentDate;
          });
        case "Last 365 Days":
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            const itemDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript
            const threeSixtyFiveDaysAgo = new Date(
              currentDate.getTime() - 365 * 24 * 60 * 60 * 1000
            );
            return itemDate >= threeSixtyFiveDaysAgo && itemDate <= currentDate;
          });
        case "Custom Date Range":
          // Apply custom date range filtering here
    
          // Replace customStartDate and customEndDate with your custom date range values
          const customStartDate = new Date(startDate);
          const customEndDate = new Date(endDate);
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            const itemDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript
            return itemDate >= customStartDate && itemDate <= customEndDate;
          });
        default:
          return nfcsessions;
      }
    } else {
      // Otherwise, filter nfcsessions based on selectedOptions and timeline
      let filteredData = nfcsessions.filter(
        (item) => item.title === selectedOptions
      );

      // Apply additional filtering based on time ranges
      const today = Date.now();
      const fifteenDaysAgo = today - 15 * 24 * 60 * 60 * 1000; // 15 days in milliseconds
      const thirtyDaysAgo = today - 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
      const ninetyDaysAgo = today - 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds
      const threeSixtyFiveDaysAgo = today - 365 * 24 * 60 * 60 * 1000; // 365 days in milliseconds

  
      // Filter nfcsessions based on different time ranges
      if (selectedOption === "Today") {
        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return timelineTimestamp >= today;
        });
      } else if (selectedOption === "Last 15 Days") {
        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return (
            timelineTimestamp >= fifteenDaysAgo && timelineTimestamp <= today
          );
        });
      } else if (selectedOption === "Last 30 Days") {
        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return (
            timelineTimestamp >= thirtyDaysAgo && timelineTimestamp <= today
          );
        });
      } else if (selectedOption === "Last 90 Days") {
        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return (
            timelineTimestamp >= ninetyDaysAgo && timelineTimestamp <= today
          );
        });
      } else if (selectedOption === "Last 365 Days") {
        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return (
            timelineTimestamp >= threeSixtyFiveDaysAgo &&
            timelineTimestamp <= today
          );
        });
      } else if (selectedOption === "Custom Date Range") {
        // Apply custom date range filtering here
        // Replace customStartDate and customEndDate with your custom date range values
        const customStartDate = new Date(startDate).getTime(); // Example start date
        const customEndDate = new Date(endDate).getTime(); // Example end date

        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return (
            timelineTimestamp >= customStartDate &&
            timelineTimestamp <= customEndDate
          );
        });
      }

      return filteredData;
    }
};

  const reloadData = () => {
    setIsRotating(true); // Start rotating
    // Simulate data reload, replace with actual data fetching logic
    setTimeout(() => {
      setIsRotating(false); // Stop rotating after a delay (adjust as needed)
    }, 2000); // Delay should match the duration of the rotation animation
  };
 

  const today = Date.now();
  const fifteenDaysAgo = today - 15 * 24 * 60 * 60 * 1000; // 15 days in milliseconds
  const thirtyDaysAgo = today - 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

  // Filter the array based on the timeline value
  const filteredDataLast15Days = nfcsessions.filter((item) => {
    const timelineTimestamp = parseInt(item.timeline);
    return timelineTimestamp >= fifteenDaysAgo && timelineTimestamp <= today;
  });

  const filteredDataLast30Days = nfcsessions.filter((item) => {
    const timelineTimestamp = parseInt(item.timeline);
    return timelineTimestamp >= thirtyDaysAgo;
  });

  const filteredDataToday = nfcsessions.filter((item) => {
    const timelineTimestamp = parseInt(item.timeline);
    return timelineTimestamp >= today;
  });

  const ninetyDaysAgo = today - 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds
  const threeSixtyFiveDaysAgo = today - 365 * 24 * 60 * 60 * 1000; // 365 days in milliseconds

  // Custom date range example
  const customStartDate = new Date("2024-03-01").getTime(); // Example start date
  const customEndDate = new Date("2024-03-15").getTime(); // Example end date

  // Filter the array based on the timeline value for the last 90 days
  const filteredDataLast90Days = nfcsessions.filter((item) => {
    const timelineTimestamp = parseInt(item.timeline);
    return timelineTimestamp >= ninetyDaysAgo;
  });

  // Filter the array based on the timeline value for the last 365 days
  const filteredDataLast365Days = nfcsessions.filter((item) => {
    const timelineTimestamp = parseInt(item.timeline);
    return timelineTimestamp >= threeSixtyFiveDaysAgo;
  });

  // Filter the array based on a custom date range
  const filteredDataCustomDateRange = nfcsessions.filter((item) => {
    const timelineTimestamp = parseInt(item.timeline);
    return (
      timelineTimestamp >= customStartDate && timelineTimestamp <= customEndDate
    );
  });

  // console.log(filteredDataLast365Days,filteredDataLast90Days,filteredDataCustomDateRange,filteredDataToday)

  // if(selectedOption=== "Last 15 days"){

  // }
  // else if(selectedOption=== "Last 30 days"){

  // }  else if(selectedOption=== "Last 90 days"){

  // }
  // else if(selectedOption=== "Last 365 days"){}
  // else{

  // }

  const filteredNfcSessions = filterNfcSessions();

  const titleCounts = {};
  nfcsessions.forEach((item) => {
    if (item.tenant === tenant) {
      const title = item.title;
      titleCounts[title] = (titleCounts[title] || 0) + 1;
    }
  });

  // Extract unique titles
  const uniqueTitles = Object.keys(titleCounts);

  // Calculate the length of the unique titles array
  const uniqueTitlesCount = uniqueTitles.length;

  const [apiCalled, setApiCalled] = useState(true); // Add state for tracking API call

  const resultArray = product.map((item) => {
    const numOccurrences = nfcsessions.filter(
      (obj) => obj.title === item.title
    );
    const current = numOccurrences.map((item) => {
      const ipadress = item.geodata.ip;
      return {
        ipadress,
      };
    });
    const ipCounts = current.reduce((counts, { ipadress }) => {
      counts[ipadress] = (counts[ipadress] || 0) + 1;
      return counts;
    }, {});

    const uniqueIPAddresses = Object.keys(ipCounts);
    const totalOccurrences = uniqueIPAddresses.reduce(
      (sum, ipAddress) => sum + ipCounts[ipAddress],
      0
    );
    const totalUniqueIPAddresses = uniqueIPAddresses.length;
    
    const setavg =
    totalUniqueIPAddresses > 0
      ? totalOccurrences / totalUniqueIPAddresses
      : 0;

  return {
    title: item.title,
    numOccurrences: numOccurrences.length,
    avgScans: setavg,
  };

    })

    const apival= JSON.parse(localStorage.getItem('apival'));
  useEffect(() => {
    //const timestamp = 1713180155291;
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns 0-based index
    const day = ("0" + date.getDate()).slice(-2);

    const formattedDate = `${year}-${month}-${day}`;
    // console.log("Date extracted:", formattedDate);

      const populatedResultArray = resultArray;
    
      // console.log(populatedResultArray)
      if (apiCalled == true && populatedResultArray.length === product.length) {
        const apivalArray = Array.isArray(apival) ? apival : [apival];
        const postdata = {
          cals: [...populatedResultArray, ...apivalArray],
        id: tenant,
        time: formattedDate,
      };
    

      setApiCalled(false);
      try {
        const response = axios.put(
          "https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/brand",
          JSON.stringify(postdata),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          // Handle successful API response
       //   console.log("API call successful");

          // Set flag to prevent further API calls
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [resultArray,apival]);

  const [lastApiCallTime, setLastApiCallTime] = useState(new Date());
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    updateLastApiCallTime();

    const interval = setInterval(() => {
      setCurrentTime(new Date()); // Update current time every second
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const updateLastApiCallTime = () => {
    // Simulated API call
    // Replace this with your actual API call logic
    // For demonstration purposes, let's set the last API call time to the current time
    const now = new Date();
    setLastApiCallTime(now);
    setCurrentTime(now);
  };
  const reloaddata = () => {
    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant));
    settimeval(Date.now());
    updateLastApiCallTime();
  };

  const timeDifference = Math.abs(
    currentTime.getTime() - lastApiCallTime.getTime()
  );
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  const options = [
    "Today",
    "Last 15 days",
    "Last 30 days",
    "Last 90 days",
    "Last 365 days",
    "Custom Date Range",
  ];

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        event.target instanceof Node &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Set default selected option to "Today"
    setSelectedOption("Last 365 Days");
    setButtonText("Last 365 Days");
  
    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant));
  }, [dispatch, tenant]);
  

const type =localStorage.getItem('subtype');
console.log(type)
//  console.log(product);

  return (
    <div >
      <span style={{fontFamily:"Roboto", color:'#0956CC'}}>{type=='type2'?'This section offers a customized dashboard for your Marketplace analytics. Connect your Marketplace accounts to access key metrics and keep a track on your products.':'Upgrade your plan to connect with major marketplaces like Amazon, Flipkart, eBay, and more. Access all essential insights in one comprehensive dashboard, covering all the key metrics you need.'}</span>
      <div className={type=='type2'?"blur-free-tier-content-alts":"blur-free-tier-content-tabs"}>
      <div className="dashboard-header">
        {/* <div className="right-side-content">
          <span className="last-updated" onClick={reloaddata}>
            {minutesDifference === 0 && <span>Updated just now</span>}
            {minutesDifference > 0 && minutesDifference <= 30 && (
              <span>Last updated {minutesDifference} minutes ago</span>
            )}
            {minutesDifference > 30 && (
              <span>Updated more than 30 minutes ago</span>
            )}
          </span>
          <img
            onClick={reloaddata}
            src={ReloadIcon}
            alt="Reload"
            className="reload-icon"
          />
         <div className="dailydropdown" ref={dropdownRef}>
          <button
            className="update-button"
            onClick={toggleDropdown}
          >
            {buttonText}
            <img
              src={DownArrowIcon}
              alt="Dropdown"
              className="dailydropdown-icon"
            />
          </button>

          {dropdownOpen && (
  <div className="dailydropdown-content">
    {options.map((option) => (
      <div
        key={option}
        className="dailydropdown-item"
        onClick={() => {
          if (option === "Custom Date Range") {
            setShowDatePicker(true);
            setButtonText(option);
          } else {
            setSelectedOption(option);
            setButtonText(option);
          }
          setDropdownOpen(false);
        }}
      >
        {option}
      </div>
    ))}
  </div>
)}
        </div>
        </div> */}
      </div>
      <div>
        <Cards
          nfcsessions={filteredNfcSessions}
          selectedtenant={selectedtenant}
          selectedOptions={selectedOptions}
          product={product} 
        />
      </div>
      <div>
      <div className="pie-graph-container">
        {/* <Graph nfcsession={filteredNfcSessions}
         product={product} /> */}
        {/* <Piechart nfcsessions={filteredNfcSessions} /> */}
      </div>
      <Region2 nfcsessions={filteredNfcSessions} />
      
      </div>
   
      <div>
      <div>
      <Region nfcsessions={filteredNfcSessions} product={product} />
     
      </div>

        <CustomTable 
        product={product}
        nfcsessions={nfcsessions}
        selectedOptions={selectedOptions} 
        tenantdata={selectedtenant.orderdetials} />
      </div>

      {showDatePicker && (
        <>
        <CustomDatePicker 
  onSelectDateRange={onSelectDateRange} 
  onCancel={onCancelDatePicker} // Pass onCancelDatePicker function
/>    </>  )}
</div>
    </div>
  );
}

export default SalesDashboard;
