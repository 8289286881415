import React, { useState, useEffect } from "react";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import "./CINSelector.css";

const CINSelector = () => {
  const [cinList, setCinList] = useState([]);
  const [filteredCinList, setFilteredCinList] = useState([]);
  const [selectedRefNo, setSelectedRefNo] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [quantity, setQuantity] = useState("");
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  // Fetch CIN list on component mount
  useEffect(() => {
    const fetchCinList = async () => {
      try {
        const response = await axios.get(
          "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/records"
        );
        setCinList(response.data);
        setFilteredCinList(response.data);
      } catch (error) {
        console.error("Error fetching CIN list:", error);
      }
    };
    fetchCinList();
  }, []);

  // Filter CIN list based on user input
  const handleFilterChange = (event) => {
    const filterText = event.target.value;
    setSelectedRefNo(filterText);
    setShowDropdown(true); // Show dropdown while typing
    const filtered = cinList.filter((cin) =>
      cin.RefNo.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredCinList(filtered);
  };

  // Handle RefNo selection
  const handleRefNoSelect = (refNo) => {
    setSelectedRefNo(refNo.replace(/\//g, "-"));
    setShowDropdown(false); // Hide dropdown when an option is selected
    const items = cinList
      .filter((cin) => cin.RefNo === refNo)
      .map((cin) => cin.Item);
    setFilteredItems([...new Set(items)]); // Remove duplicate items if any
    setSelectedItem("");
    setQuantity("");
    setIsDownloadEnabled(false);
  };

  // Handle Item selection
  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setQuantity("");
    setIsDownloadEnabled(false);
  };

  // Handle quantity input
  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
    setIsDownloadEnabled(event.target.value > 0); // Enable download button if quantity is greater than 0
  };

  // Function to download files and zip them
  const handleDownload = async () => {
    const zip = new JSZip();
    const folderName = `${selectedRefNo}-${selectedItem}-${Date.now()}`;
    const zipFolder = zip.folder(folderName);

    const urls = {
      qrWithEmbeddedBarcodeUrl: "https://qrcodeclassifiermodel.s3.amazonaws.com/generated_files/qr_with_datamatrix_20241106T074640727Z.png",
      dataMatrixOnlyUrl: "https://qrcodeclassifiermodel.s3.amazonaws.com/generated_files/datamatrix_20241106T074640727Z.png",
      pdfUrl: "https://qrcodeclassifiermodel.s3.amazonaws.com/generated_files/qr_and_datamatrix_20241106T074640727Z.pdf",
    };

    try {
      // Download each file and add it to the zip folder
      for (const [fileName, url] of Object.entries(urls)) {
        const response = await axios.get(url, { responseType: "blob" });
        const fileExtension = url.split(".").pop();
        zipFolder.file(`${fileName}.${fileExtension}`, response.data);
      }

      // Generate the zip file and trigger download
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `${folderName}.zip`);
    } catch (error) {
      console.error("Error downloading or zipping files:", error);
    }
  };

  const downloadButtonStyles = {
    background: "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
    boxShadow:
      "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset, 0px 4px 4px 0px #00000040",
    color: "white",
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    height: "40px",
    borderRadius: "24px",
    fontFamily: "'Roboto', sans-serif",
    transition: "background-color 0.3s ease",
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="cin-selector-container">
        <h2 className="cin-selector-title">Challan Identification Number (CIN)</h2>

        <div className="cin-selector-dropdown-container">
          <div className="cin-selector-dropdown">
            <label className="cin-selector-label">Ref No:</label>
            <input
              type="text"
              placeholder="Type to filter RefNo"
              value={selectedRefNo}
              onChange={handleFilterChange}
              onFocus={() => setShowDropdown(true)} // Show dropdown on input focus
              className="cin-selector-input"
            />
            {showDropdown && filteredCinList.length > 0 && (
              <div className="cin-selector-dropdown-menu">
                {filteredCinList.map((cin) => (
                  <div
                    key={cin.MessageId}
                    className="cin-selector-dropdown-item"
                    onClick={() => handleRefNoSelect(cin.RefNo)}
                  >
                    {cin.RefNo}
                  </div>
                ))}
              </div>
            )}
          </div>

          {selectedRefNo && (
            <div className="cin-selector-dropdown">
              <label className="cin-selector-label">Item:</label>
              <select
                value={selectedItem}
                onChange={(e) => handleItemSelect(e.target.value)}
                className="cin-selector-select"
              >
                <option value="">Select Item</option>
                {filteredItems.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {selectedItem && (
          <div className="cin-selector-quantity-input">
            <label className="cin-selector-label">Enter Qty:</label>
            <input
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              min="1"
              className="cin-selector-input"
            />
          </div>
        )}

        {isDownloadEnabled && (
          <button
            onClick={handleDownload}
            style={downloadButtonStyles}
          >
            Download Codes
          </button>
        )}
      </div>
    </div>
  );
};

export default CINSelector;
