import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css"; // Import custom CSS for styling

const CustomDatePicker = ({ onSelectDateRange, onCancel }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const applyDateRange = () => {
    onSelectDateRange(startDate, endDate);
  };

  // Get today's date
  const today = new Date();

  return (
    <div className="custom-date-picker-overlay">
      <div className="custom-date-picker">
        <p className="date-picker-select-date">Select Dates</p>
        <div>
          <p className="date-picker-mini-header">From</p>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Select start date"
            className="date-picker-box"
            maxDate={today} // Disable future dates
          />
        </div>
        <div>
          <p className="date-picker-mini-header">To</p>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="Select end date"
            className="date-picker-box"
            maxDate={today} // Disable future dates
          />
        </div>
        <div className="button-group">
          <button className="date-picker-apply-button" onClick={applyDateRange}>
            Apply
          </button>
          <button className="date-picker-cancel-button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;
