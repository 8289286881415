import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999999;
  width: 95%;
  height: 95%;
  overflow-y: auto;
  display: flex;
`;

const PdfViewer = styled.div`
  width: 65%;
  height: 100%;
  position: relative;
`;

const DetailsSection = styled.div`
  width: 35%;
  margin-top: 50px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const DetailItem = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.span`
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const PrintButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

const DownloadButton = styled(PrintButton)`
  background-color: #28a745;
  margin-right: 10px;

  &:hover {
    background-color: #218838;
  }
`;

const PdfPopup = ({ pdfDataUrl, onClose, numberOfCopies, numberOfPages, batchId, batchDate, expiryDate }) => {
  const [loading, setLoading] = useState(true);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);

  const fetchPdf = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(pdfDataUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      setPdfBlobUrl(blobUrl);
    } catch (error) {
      console.error("Failed to fetch the PDF:", error);
    } finally {
      setLoading(false);
    }
  }, [pdfDataUrl]);

  useEffect(() => {
    fetchPdf();
  }, [fetchPdf]);

  const handlePrint = useCallback(() => {
    if (pdfBlobUrl) {
      // Create a new window and load the PDF into it
      const newWindow = window.open(pdfBlobUrl, '_blank');
      
      if (newWindow) {
        newWindow.addEventListener('load', () => {
          // Automatically trigger the print dialog after the PDF loads
          newWindow.print();
        });
      }
    }
  }, [pdfBlobUrl]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfBlobUrl;
    link.download = 'document.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Overlay onClick={onClose} />
      <Popup>
        <PdfViewer>
          {loading ? (
            <p>Loading PDF...</p>
          ) : (
            <iframe
              src={pdfBlobUrl}
              width="100%"
              height="100%"
              style={{ border: 'none' }}
              title="PDF Viewer"
            />
          )}
        </PdfViewer>
        <DetailsSection>
          <DetailItem>
            <Label>Number of Copies: </Label> {numberOfCopies}
          </DetailItem>
          <DetailItem>
            <Label>Number of Pages: </Label> {numberOfPages}
          </DetailItem>
          <DetailItem>
            <Label>Batch ID: </Label> {batchId}
          </DetailItem>
          <DetailItem>
            <Label>Batch Date: </Label> {batchDate}
          </DetailItem>
          <DetailItem>
            <Label>Expiry Date: </Label> {expiryDate}
          </DetailItem>
          <ButtonContainer>
            <DownloadButton onClick={handleDownload}>Download</DownloadButton>
            <PrintButton onClick={handlePrint}>Print</PrintButton>
          </ButtonContainer>
        </DetailsSection>
      </Popup>
    </>
  );
};

export default PdfPopup;
