import DatamapsIndia from "react-datamaps-india";

const IndianMapChart = ({data}) => {
// console.log (data)

  return (
    <div style={{ height: '580px', width: '60%', overflow: 'hidden', position: 'absolute', top:'45px' }}>
    <DatamapsIndia
      regionData={  data    }
      hoverComponent={({ value }) => {
        return (
          <>
            <p>State:{value.name}- with {value.value} Scans</p>
            {value.score ? <p>{value.score}</p> : ""}
            {/* {value.count ? <p>Audit Count:{value.count}</p> : ""} */}
          </>
        );
      }}
      mapLayout={{
      
       // title: "Live Trafic In India",
        // legendTitle: "Legend Title",
        startColor: "#e2eeff",
        endColor: "#1A4E9F",
        hoverTitle: "Count",
        // noDataColor: '#f5f5f5',
        // height: "00px",

        borderColor: "#8D8D8D",
        hoverBorderColor: "#8D8D8D",
        hoverColor: "coral",
        height: '10px',
        width: 10
      }}
    />

    
  </div>
  );
};

export default IndianMapChart;
