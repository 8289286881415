import React, { useEffect, useState } from "react";
import trendup from "../../../../assets/Trend Up.png";
import lineup from "../../../../assets/Line 2@2x.png";
import "./Sessions.css";
import Sessionsimg from '../../../../assets/sessions.png';

function Sessions() {
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  // Retrieve the username from localStorage
  const username = localStorage.getItem("selectedBrandEmail") || ""; // Fallback to empty string if not found

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await fetch(`https://lurt43xrql.execute-api.ap-south-1.amazonaws.com/prod/sessions?username=${username}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSessionData(data);
      } catch (error) {
        console.error('Error fetching session data:', error);
      } finally {
        setLoading(false);
      }
    };

    // Fetch session data only if username is available
    if (username) {
      fetchSessionData();
    } else {
      setLoading(false);
    }
  }, [username]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const totalLogins = sessionData?.TotalLogins || 0; // Default to 0 if TotalLogins is undefined
  const lastSessionStartTime = sessionData?.sessionStartTime ? new Date(sessionData.sessionStartTime).toLocaleString() : 'N/A'; // Show N/A if sessionStartTime is not available

  return (
    <div
      style={{
        height: "190px",
        width: "27%",
        border: "none",
        borderRadius: "11px",
        padding: "10px",
        background: "white",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={Sessionsimg} style={{ width: '19px', marginRight: '5px' }} alt="" />
        <span className="session-header">Sessions</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="session-past-timeline" style={{fontSize:'14px'}}>{totalLogins} logins</span>
          <br />
          <span className="session-past-timeline" style={{fontSize:'14px'}}>
            Last Session Start Time: {lastSessionStartTime}
          </span>
        </div>
        <div>
          <img src={lineup} style={{ width: "90px" }} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Sessions;
