import React, { useState, useEffect, useRef } from "react";
import ReactApexChart from 'react-apexcharts'
const Sentiment = ({reviewdata}) => {

    const [sentimentCount, setsentimentCount]=useState([])
    useEffect(()=>{
        if (!reviewdata || !reviewdata.reviews) {
            return; // Handle the case where reviewdata is undefined or does not have the reviews property
          }

          // Initialize counts
let positiveCount = 0;
let neutralCount = 0;
let negativeCount = 0;

// Iterate through the reviews array to count sentiment occurrences
reviewdata.reviews.forEach(review => {

    const sentiment = review.Sentiment.Sentiment.sentiment;
    if (sentiment === "positive") {
        positiveCount++;
    } else if (sentiment === "neutral") {
        neutralCount++;
    } else if (sentiment === "negative") {
        negativeCount++;
    }
});

// Create an array with the count values
const sentimentCounts = [positiveCount, neutralCount, negativeCount];

console.log(sentimentCounts)

setsentimentCount(sentimentCounts)



        },[reviewdata]);
        
     
    const options6 = {
        series: sentimentCount,
        chart: {
          height: 390,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
            barLabels: {
              enabled: true,
              useSeriesColors: true,
              margin: 8,
              fontSize: '16px',
              formatter: function (seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
              },
            },
          },
        },
        colors: ['#00FF00', '#39539E', '#FF0000'],
        labels: ['Positive Review', 'Neutral Review', 'Negative Review'],
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }],
      };
  
    return <ReactApexChart options={options6} series={options6.series} type="radialBar" height={230} />;
  };

  export default Sentiment;