import React from 'react';
import './Traceability.css';
import trace from '../../../../assets/trace.png'

interface DataItem {
  text: string;
  value: number;
}

function Traceability() {
  const data: DataItem[] = [
    { text: 'Finished processes', value: 170 },
    { text: 'Ongoing processes', value: 40 },
    { text: 'Initiated Processes', value: 60 },
  ];

  const totalValue = data.reduce((acc, curr) => acc + curr.value, 0);

  const colorGradients: { [key: string]: string } = {
    'Finished processes': 'linear-gradient(to right, #7FFF00, #32CD32)',
    'Ongoing processes': 'linear-gradient(to right, #FFA500, #FF6347)',
    'Initiated Processes': 'linear-gradient(to right, #FFFF00, #FFD700)',
  };

  return (
    <div
      style={{
        height: '190px',
        width: '40%',
        border: 'none',
        borderRadius: '11px',
        padding: '10px',
        background: 'white',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div style={{display:"flex", alignItems:"center"}}>
      <img src={trace} style={{width:'19px', marginRight:'5px'}} alt="" />

        <span className="traceability-header">Traceability</span>
        
      </div>
      <div style={{ marginTop: '30px', flexGrow: 1, paddingLeft:'20px', paddingRight:'20px'}}>
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginBottom: '15px',
            }}
          >
            <span className='traceability-sub-header' style={{ marginRight: '10px' }}>{item.text}</span>
            <div
              style={{
                flex: `${(item.value / totalValue) * 1.8}`,
                height: '12px',
                background: colorGradients[item.text],
                borderRadius: '5px',
              }}
            />
            <span className='traceability-sub-value' style={{ marginLeft: '4px' }}>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Traceability;
