class MessageParser {
  constructor(actionProvider) {
      this.actionProvider = actionProvider;
  }

  parse(message) {
      const lowerCaseMessage = message.toLowerCase();

      if (lowerCaseMessage.includes('hello')) {
          this.actionProvider.greet();
      }

      // Check if the message includes any text
      if (lowerCaseMessage.trim() !== '') {
          // Show the message and options for issue and demo
          this.actionProvider.showOptions();
      }

      if (lowerCaseMessage.includes('Raise Issue')) {
          this.actionProvider.handleJavascriptList();
      }

      if (lowerCaseMessage.includes('Watch tutorial')) {
        this.actionProvider.handlelerningList();
    }
  }
}

export default MessageParser;
  