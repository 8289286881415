import React, { useState, useRef } from "react";
import "./Map.css"; // Ensure this CSS file is correctly linked for styling
import PieChartComponent from "./PieChartComponent";
//import PieChartComponent from "./PiechartComponent";
import DraggablePieChart from "./DraggablePieChart";
import location from "../../../assets/location.png";
const Region2charts = ({ nfcsessions }) => {
  const [age, setAge] = useState(1);

  const handleChange = (event) => {
    setAge(Number(event.target.value)); // Convert string to number
  };
  //const nfcsessions = JSON.parse(localStorage.getItem('nfcsessions')) || [];






  return (
    <div className="map-container" id="chart">
      <div className="header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={location}
            alt=""
            style={{ height: "17px", marginRight: "5px" }}
          />
          <p
            style={{
              fontFamily: "Baumans",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "18.91px",
              letterSpacing: "0.02em",
            }}
          >
            Sales Overview
          </p>
        </div>
        <div className="form-control">
          <label
            htmlFor="map-select"
            style={{
              fontFamily: "Baumans",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "18.91px",
              letterSpacing: "0.02em",
            }}
          >
            Map
          </label>
          <select
            style={{
              borderRadius: "20px",
              height: "30px",
              marginLeft: "4px",
              padding: "5px",
            }}
            id="map-select"
            value={age}
            onChange={handleChange}
            className="select"
          >
            <option value={1}>Pie Chart</option>
            <option value={2}>Draggable Pie Chart</option>
          </select>
        </div>
      </div>
      {age === 1 ? (
        <PieChartComponent  />
      ) : (
          
            <DraggablePieChart  />
        
      
      )}
    </div>
  );
};

export default Region2charts;
