class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
  }

  showOptions = () => {
    const message = this.createChatBotMessage(
      "Sorry, we currently have the below options to choose from:",
      {
        widget: 'learningOptions'
      }
    );

    this.updateChatbotState(message);
  };

  handleJavascriptList = () => {
      const message = this.createChatBotMessage(
          "Please select an issue from the options below:",
          {
              widget: 'javascriptLinks'
          }
      );

      this.updateChatbotState(message);
  };

  handlelerningList = () => {
    const message = this.createChatBotMessage(
        "Please watch video:",
        {
            widget: 'lerningLinks'
        }
    );

    this.updateChatbotState(message);
};

handleticketsList = () => {
    const message = this.createChatBotMessage(
        "Please watch video:",
        {
            widget: 'lerningLinks'
        }
    );

    this.updateChatbotState(message);
};

  updateChatbotState(message) {
      // NOTICE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.

      this.setState((prevState) => ({
          ...prevState,
          messages: [...prevState.messages, message]
      }));
  }
}

export default ActionProvider;
