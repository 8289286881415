  import React, { useState, useEffect } from "react";
  import { useSwipeable } from "react-swipeable";
  import "./index.css";
  import arvologo from "../assets/brandlogo.png";
  import arvogrp from "../assets/arvobrandlogo.png";
  import arvotext from "../assets/arvo-text.png";
  import logout from "../assets/Logout.png";
  import CustomButtons from "./CustomButtons/CustomButtons";
  import { useSelector, useDispatch } from "react-redux";
  import { useNavigate } from "react-router-dom";
  import { signOut } from "../cognito/Auth";
  import Tooltip from "../Products/ProductDetail/toottip/Tooltip";
  import { fetchTenantData } from "../redux/counterSlice";
  import { FaBars, FaArrowRight } from "react-icons/fa";

  const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [hovered, setHovered] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
      const data = JSON.parse(localStorage.getItem("user"));
      dispatch(fetchTenantData(data.idToken.payload.name));
    }, [dispatch]);

    const tenantdata = useSelector((state) => state.counter.tenentData);
    const tenant = localStorage.getItem("tenant");
    localStorage.setItem("tenantdata", JSON.stringify(tenantdata));
    const selectedOrder = tenantdata.find((order) => order.employeeid === tenant);

    useEffect(() => {
      if (selectedOrder && selectedOrder.subtype) {
        localStorage.setItem("subtype", selectedOrder.subtype);
        localStorage.setItem("fname", selectedOrder.Username);
      }
    }, [selectedOrder]);

    const profilePhoto = selectedOrder?.profilephoto || arvologo;
    const type = localStorage.getItem("subtype");

    const redirectToPricing = () => {
      window.location.href = "/pricing";
    };

    const handleLogout = async () => {
      localStorage.setItem("tenantdata", " ");
      localStorage.setItem("tenant", " ");
      await signOut();
      navigate("/");
    };

    const confirmLogout = () => {
      if (window.confirm("Are you sure you want to logout?")) {
        handleLogout();
      }
    };

    const text = type === "type1" ? "Subscribe to Enterprise" : "Enterprise Tier";

    const swipeHandlers = useSwipeable({
      onSwipedLeft: () => setSidebarOpen(false),
      onSwipedRight: () => setSidebarOpen(true),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true,
    });

    return (
      <div {...swipeHandlers}>
        <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
        
          <div className="sidebar-logo-container">
            <img
              src={profilePhoto}
              alt="ARVO Logo"
              className="sidebar-logo"
              onClick={() => navigate("/profile", { state: { fromOnClick: true } })}
            />
          </div>
          <div className="sidebar-line"></div>
          <CustomButtons setSidebarOpen={setSidebarOpen} />
          <div className="sidebar-bottom">
            <Tooltip text={text}>
              <div
                className="free-tier-label"
                onClick={redirectToPricing}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <span className="free-tier-text">
                  {type === "type2" ? "Enterprise" : "Free Tier"}
                </span>
              </div>
            </Tooltip>
          </div>
          <div className="sidebar-bottom-arvo-logo">
            <div onClick={confirmLogout} className="nav-logout-container">
              <div className="nav-logout-image-circle">
                <img src={logout} alt="Logout" />
              </div>
              <div className="nav-logout-text">Logout</div>
            </div>
            <div className="powered-by">
              <span>Powered by</span>
              <div>
                <img src={arvogrp} alt="Arvo Group" className="arvo-text" />
                <img src={arvotext} alt="Arvo" className="arvo-logo-brand" />
              </div>
            </div>
            <div className="version-info">Version 1.0.7</div>
          </div>
        </div>
        <button className="sidebar-toggle" onClick={() => setSidebarOpen(!sidebarOpen)}>
          <FaBars />
        </button>
      </div>
    );
  };

  export default Sidebar;
