import React, { useState, useEffect } from 'react';
import './Notification.css';
import Navbarimgone from "./../../../assets/Rectangle 6.png";
import droparrow from "./../../../assets/down-chevron.png";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchNfcSessions,
  fetchProducts,
  setSelectedOptions
} from "../../../redux/counterSlice";

function Notification() {
  const [isOpen, setIsOpen] = useState(false);
  const nfcsessions = useSelector((state) => state.counter.nfcsessions);
  const product = useSelector((state) => state.counter.products);
  const dispatch = useDispatch();
  const tenant = localStorage.getItem("tenant").toLowerCase();
  
  useEffect(() => {
    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant));  
  }, [dispatch]);

  const options = ['All Products', ...product.map(item => item.title)];
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    localStorage.setItem("selectedProduct", option);
    const filter = product.filter(item => item.title === option);
    setSelectedPhoto(filter.length ? filter[0].photo[0] : null);
    dispatch(setSelectedOptions(option));
    setIsOpen(false); 
  };

  return (
    <div className="dropdown">
      <button onClick={() => setIsOpen(!isOpen)} className="navbar-dropdown-button">
        {selectedOption !== 'All Products' && selectedPhoto && (
          <img className='nav-btn-img-one' src={selectedPhoto} alt="" />
        )}
        {selectedOption}
        <img className='nav-btn-img-two' src={droparrow} alt="" />
      </button>
      {isOpen && (
        <div className="dropdown-content">
          {options.map((option, index) => (
            <div key={index} className="dropdown-item" onClick={() => handleSelectOption(option)}>
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Notification;
