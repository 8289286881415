import React, { useState, useEffect, useRef } from "react";
import "./Profile.css";
import userimg from "../assets/1234.jpg";
import editIcon from "../assets/Vector (1).png";
import Plusicon from "../assets/PlusCircle.png";
import tenant from "../assets/7b93c90dfb3f23843517dc882082c49e.jpeg";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTenantData,
  setcredits,
  settenantObjectOptions,
} from "../redux/counterSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";

const creditCards = [
  {
    id: 1,
    cardNumber: "**** **** **** 1234",
    expiryDate: "08/24",
    isPrimary: true,
  },
  {
    id: 2,
    cardNumber: "**** **** **** 5678",
    expiryDate: "09/25",
    isPrimary: false,
  },
];

const tenants = [
  { id: 1, name: "Genesis 1", img: tenant },
  { id: 2, name: "Genesis 1", img: tenant },
  { id: 3, name: "Genesis 1", img: tenant },
  { id: 4, name: "Genesis 1", img: tenant },
];

function Profile() {
  const tenantdata = useSelector((state) => state.counter.tenentData);
  const tenantObj = useSelector((state) => state.counter.tenantObject);
  const tenentData = useSelector((state) => state.counter.tenentData);
  const tenantdata1 = JSON.parse(localStorage.getItem("tenantdata"));
  const tenant = localStorage.getItem("tenant");
  useEffect(() => {
    localStorage.getItem("tenantdata", JSON.stringify(tenentData));
  }, [tenentData]);

  var selectedOrder = tenentData.find(function (order) {
    return order.employeeid === tenant;
  });

  const location = useLocation();

  // Check if the navigation state has the fromOnClick property
  const fromOnClick = location.state?.fromOnClick || false;

  const [cards, setCards] = useState(creditCards);
  const [userImg, setUserImg] = useState(userimg);
  const [userName, setUserName] = useState(tenant);
  const [brandName, setBrandName] = useState(
    selectedOrder ? selectedOrder.cognitoBrandName : ""
  );
  const [brandMission, setBrandMission] = useState(
    selectedOrder ? selectedOrder.brandmission : ""
  );
  const [brandStory, setBrandStory] = useState(
    selectedOrder ? selectedOrder.brandDetail : ""
  );
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [editor, setEditor] = useState(null); // Add a ref to the editor
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    if (fromOnClick === true) {
      setEditPopupOpen(true);
    } else {
      setEditPopupOpen(false);
    }
  }, []);

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));

    dispatch(fetchTenantData(data.idToken.payload.name));
  }, [dispatch]);

  const [settingimages, setsettingimages] = useState(false);
  const handleImageEdit = () => {
    fileInputRef.current?.click();
    setsettingimages(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleEditPopup = () => {
    setEditPopupOpen(!editPopupOpen);
  };

  const handleBrandMissionChange = (e) => {
    setBrandMission(e.target.value);
  };

  const handleBrandStoryChange = (e) => {
    setBrandStory(e.target.value);
  };

  const handleaddtenant = () => {
    toast.warn(
      `You are not eligible to create new tenant under the free tier plan`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
  };

  const [isLoading, setIsLoading] = useState(false);

  const handlesubmit = async () => {
    // Get the cropped image data
    const canvas = editor.getImageScaledToCanvas().toDataURL();
    const apidata = {
      employeeid: selectedOrder.employeeid,
      profilephoto: [
        settingimages ? canvas : selectedOrder ? selectedOrder.profilephoto : userImg,
      ],
      brandDetail: brandStory,
      brandmission: brandMission,
      cognitoBrandName: selectedOrder.cognitoBrandName,
      deliveryAddresses: selectedOrder.deliveryAddresses,
      shippingAddresses: selectedOrder.shippingAddresses,
      coutervals: selectedOrder.coutervals,
      creditHistory: selectedOrder.creditHistory,
      emailaddress: selectedOrder.emailaddress,
    };

    const data = JSON.parse(localStorage.getItem("user"));

    try {
      const response = await axios.post(
        "https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/brand",
        JSON.stringify(apidata),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        toast.success(`Profile Edited successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        await dispatch(fetchTenantData(data.idToken.payload.name));
        toggleEditPopup();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="users-profile"
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <ToastContainer />
      <div
        className="user-image-logout-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "7px",
          border: "1px solid #ccc",
          padding: "20px",
          position: "relative",
        }}
      >
        <div className="user-image-logout-container">
          <div className="profile-image-wrapper">
            <div className="profile-image">
              <img
                src={
                  settingimages
                    ? userImg
                    : selectedOrder
                    ? selectedOrder.profilephoto
                    : userImg
                }
                alt="User profile"
              />
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*"
            />
          </div>
        </div>
        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div className="userform-textarea-container">
            <label htmlFor="brandMission" className="form-label">
              Username:
            </label>
            &nbsp;&nbsp;
            {selectedOrder ? selectedOrder.Username.replace(/-/g, " ") : "Guest"}
          </div>

          <div className="userform-textarea-container">
            <label htmlFor="brandMission" className="form-label">
              Tenant Name:
            </label>
            &nbsp;&nbsp;
            {userName}
          </div>

          <div className="userform-textarea-container">
            <label htmlFor="brandMission" className="form-label">
              Brand Name:
            </label>
            &nbsp;&nbsp;
            {brandName}
          </div>

          <div className="userform-textarea-container">
            <label htmlFor="brandMission" className="form-label">
              Email:
            </label>
            &nbsp;&nbsp;
            {selectedOrder ? selectedOrder.emailaddress[0] : ""}
          </div>

          <div className="userform-textarea-container">
            <label htmlFor="brandMission" className="form-label">
              Brand's Mission:
            </label>
            &nbsp;&nbsp;
            {brandMission}
          </div>
          <div className="userform-textarea-container">
            <label htmlFor="brandStory" className="form-label">
              Brand's Story:
            </label>
            &nbsp;&nbsp;
            {brandStory}
          </div>
        </div>
        <img
          src={editIcon}
          alt="Edit profile"
          onClick={toggleEditPopup}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        />
      </div>

      <div
        className="user-tenant-container"
        style={{
          border: "1px solid #ccc",
          borderRadius: "7px",
          padding: "20px",
          width: "100%",
        }}
      >
        <div
          className="user-tenant-header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p>Tenant</p>
          <img
            className="user-tenant-add"
            src={Plusicon}
            onClick={handleaddtenant}
            alt="PLUS"
          />
        </div>
        <div className="tenant-cards" style={{ display: "flex", gap: "20px" }}>
          {tenentData.map((tenant, id) => (
            <div key={id} className="user-tenant-card">
              <img
                src={tenant.profilephoto}
                alt={tenant.employeeid}
                className="tenant-image"
              />
              <p className="user-tenant-name">{tenant.employeeid}</p>
              <button
                className="manage-button"
                style={{
                  borderRadius: "5px",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                }}
              >
                Manage
              </button>
            </div>
          ))}
        </div>
      </div>

      {editPopupOpen && (
        <div className="edit-popup">
          <h2>Edit Profile</h2>
          <div className="profile-image-wrapper">
            <div className="profile-image" style={{ margin: "30px" }}>
              <AvatarEditor
                ref={setEditor} // Set the editor ref
                image={userImg}
                width={200}
                height={200}
                border={50}
                scale={zoom}
              />
            </div>
            <button className="edit-image-button" onClick={handleImageEdit}>
              <img src={editIcon} alt="Edit profile" />
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*"
            />
          </div>
          <div
            className="userform-textarea-container"
            style={{ margin: "30px", marginTop: "30px" }}
          >
            <label
              htmlFor="editBrandMission"
              className="form-label"
              style={{ marginRight: "300px" }}
            >
              Zoom Image
            </label>
            <input
              type="range"
              value={zoom}
              min="1"
              max="3"
              step="0.1"
              onChange={(e) => setZoom(parseFloat(e.target.value))}
            />
            <br></br>
            <br></br>
            <br></br>
            <label htmlFor="editBrandMission" className="form-label">
              Brand's Mission
            </label>
            <textarea
              id="editBrandMission"
              placeholder="Describe Brand's Mission"
              className="form-textarea"
              value={brandMission}
              onChange={handleBrandMissionChange}
            ></textarea>
          </div>
          <div
            className="userform-textarea-container"
            style={{ margin: "30px" }}
          >
            <label htmlFor="editBrandStory" className="form-label">
              Brand's Story
            </label>
            <textarea
              id="editBrandStory"
              placeholder="Tell Brand's Story"
              className="form-textarea"
              value={brandStory}
              onChange={handleBrandStoryChange}
            ></textarea>
          </div>
          <div style={{ display: "flex", margin: "30px" }}>
            <button
              className="profile-submit-button"
              onClick={toggleEditPopup}
              style={{ marginRight: "10px", backgroundColor: "white" }}
            >
              Cancel
            </button>
            <button className="profile-submit-button" onClick={handlesubmit}>
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
