import React from "react";
import "./CounterfeitStatus.css";
import productImage from "../../assets/ghee.jpeg";
import reporticon from "../../assets/report.png";
import Timeline from "../CounterfeitTimeline/CounterfeitTimeline";

interface StatusProps {
  productName: string;
  itemNames: string[];
  report: "Seal Tampering" | "Duplicate Tracked";
  firstDot: string;
  secondDot: string;
}

const Status: React.FC<StatusProps> = ({
  productName,
  itemNames,
  report,
  firstDot,
  secondDot,
}) => {
  return (
    <div className="status-wrapper">
      <div className="status-container">
        <div className="status-left-section">
          <img
            src={productImage}
            alt="Product"
            className="status-product-image"
          />
          <div className="status-product-details">
            <h4 className="status-product-name">{productName}</h4>
            <div>
              {itemNames.map((itemName, index) => (
                <p key={index} className="status-item-name">
                  {itemName}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="status-right-section">
          <div className="counter-timeline-status-container">
            <Timeline
              report={report}
              itemNames={itemNames}
              firstDot={firstDot}
              secondDot={secondDot}
            />
          </div>
          {/* <div className="tracking-id">
            Tracking ID:{" "}
            <span className="tracking-id-number">342342343242</span>
          </div> */}
        </div>
      </div>
      <div className="actions-container">
        <button className="report-cancel-order-button">Track Location</button>
        <div className="report-problem-container">
          <img src={reporticon} alt="Report" className="report-icon" />
          <span className="report-problem-text">Alert ARVO</span>
        </div>
      </div>
    </div>
  );
};

export default Status;
