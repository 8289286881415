import React, { useState, useEffect, useMemo, useRef } from "react";
import "./SupportChatBox.css";
import sendbtn from "../../assets/Send.png";

const calculateDaysAgo = (date) => {
  const today = new Date();
  const ticketDate = new Date(date);
  const timeDiff = Math.abs(today.getTime() - ticketDate.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
};


function calculateTimeDifference(start, end) {
    
  const startTimestamp = new Date(start).getTime(); // Example start timestamp
const endTimestamp = new Date(end).getTime(); 

console.log(startTimestamp,endTimestamp)

  const timeDifference = endTimestamp - startTimestamp;
  const seconds = Math.floor((timeDifference / 1000) % 60);
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (days > 0) {
    return `${days}d ${hours}h`;
  } else if (hours > 0) {
    return `${hours}h ${minutes}m `;
  } else if (minutes > 0) {
    return `${minutes}m `;
  } else {
    return `${seconds}s`;
  }

}
const formatDateTime = (date, time) => {
  const dateObj = new Date(`${date} ${time}`);
  const formattedDate = dateObj.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const formattedTime = dateObj.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });
  return `${formattedDate} at ${formattedTime}`;
};

const getColorForLetter = (letter) => {
  const letters = "ABCDEF0123456789";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

const SupportChatBox = ({
  email,
  id,
  status,
  subject,
  name,
  time,
  date,
  data,
  onClose,
}) => {
  const selectedTicket = data.find((ticket) => ticket.id === id);

  console.log(selectedTicket);
  const senderColor = useMemo(
    () => getColorForLetter(email.slice(0, 1).toUpperCase()),
    [email]
  );
  const [newMessage, setNewMessage] = useState("");
  const [sentMessages, setSentMessages] = useState([]);

  const chatMessagesRef = useRef(null);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const timestamp = new Date().getTime();
      const datas = JSON.parse(localStorage.getItem("user"));
      const newConversation = {
        time: timestamp,
        message: newMessage,
        sender: datas.idToken.payload.email,
      };
      setSentMessages([...sentMessages, newConversation]);
      setNewMessage("");

      selectedTicket.chatMessage.push(newConversation);
      console.log(selectedTicket)
       sendTicketToAPI(
        selectedTicket
       );
    }
  };

  const sendTicketToAPI = async (selectedTicket) => {
  
    const data = JSON.parse(localStorage.getItem("user"));
    console.log("Authentication successful:", data.idToken.payload.email,data.idToken.payload.name);
    const postdata = {
      id: selectedTicket.id,
      credtedby: data.idToken.payload.email,
      Status: "open",
      time: selectedTicket.timestamp,
      createdAt:Date.now(),
      brand: selectedTicket.brandName,
      ticketHeading: selectedTicket.subject,
      conversation: selectedTicket.chatMessage,

    };
    console.log(postdata)
    try {
      const response = await fetch(
        "https://bxcswtw14g.execute-api.ap-south-1.amazonaws.com/prod/arvoTickets",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postdata),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        handleSendMessage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [newMessage]);

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [sentMessages, selectedTicket]);

  const sortedMessages = [...(selectedTicket?.chatMessage || [])].sort(
    (a, b) => a.time - b.time
  );

  return (
    <div className="support-chat-box">
      <div className="support-chat-box-header">
        <button className="support-close-button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className="support-chat-box-content" style={{display:'flex', flexDirection:'colum'}}>
        <h3>{subject}</h3>
        <p>Created by: {email}</p>
        <p>Status: {status}</p>
      </div>
      {status === "open" && (
        <div className="thankyou-message">
          Thank you for raising the ticket, our team will reach out to you soon!
        </div>
      )}
      <div className="support-chat-messages" ref={chatMessagesRef}>
        {sortedMessages.map(({ message, sender, time }, index) => (
          <div
            key={index}
            className={`${
              sender === "arvo"
                ? "support-admin-message"
                : "support-chat-message"
            }`}
          >
            {sender !== "arvo" && (
              <div
                className="support-sender-icon"
                style={{ backgroundColor: senderColor }}
              >
                {email.slice(0, 1).toUpperCase()}
              </div>
            )}
            <div className="support-message-details">
              <div className="support-sender-name">
                {sender === "arvo" ? "Super Admin" : selectedTicket?.name}
              </div>
              <div className="support-message-time">
              {calculateTimeDifference(time,Date.now())}
                {/* {calculateDaysAgo(date)} days ago ({formatDateTime(date, time)}) */}
              </div>
              <div className="support-message-text">{message}</div>
            </div>
          </div>
        ))}
        {/* {sentMessages.map(({ message, timestamp }, index) => (
          <div key={index} className="support-chat-message">
            <div
              className="support-sender-icon"
              style={{ backgroundColor: senderColor }}
            >
              {email.slice(0, 1).toUpperCase()}
            </div>
            <div className="support-message-details">
              <div className="support-sender-name">{selectedTicket?.name}</div>
              <div className="support-message-time">
                {/* {formatDateTime(
                  new Date(timestamp).toLocaleDateString(),
                  new Date(timestamp).toLocaleTimeString()
                )} 
                just now
              </div>
              <div className="support-message-text">{message}</div>
            </div>
          </div>
        ))} */}
      </div>
      <div className="support-chat-input-form">
        <input
          type="text"
          className="support-chat-input"
          placeholder="Type your message here..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button
          type="button"
          className="support-send-chat-button"
          onClick={handleSendMessage}
        >
          <img src={sendbtn} alt="Send" />
        </button>
      </div>
    </div>
  );
};

export default SupportChatBox;