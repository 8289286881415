import React, { useEffect } from 'react';
import axios from 'axios';

function AlgoData({ skuid, onBatchNumbers, onTraceabilityData, onJourneyData, onTransactionNotes, reloadRef }) {
  const apiUrl = 'https://k5m2gbtywb.execute-api.ap-south-1.amazonaws.com/prod/fetch-txn';

  const fetchTraceabilityData = async () => {
    try {
      const response = await axios.get(`https://f9qv0gdcpc.execute-api.ap-south-1.amazonaws.com/prod/traceability?skuid=${skuid}`);
      const traceabilityData = response.data;
      onTraceabilityData(traceabilityData);
      const batchNumbers = traceabilityData.map(item => item.BatchNumber);
      onBatchNumbers(batchNumbers);
      await fetchTransactionNotes(traceabilityData);
    } catch (error) {
      console.error('Error fetching traceability data:', error);
    }
  };

  const fetchJourneyData = async () => {
    try {
      const response = await axios.get(`https://1c6see68bj.execute-api.ap-south-1.amazonaws.com/prod/product-journey?skuid=${skuid}`);
      onJourneyData(response.data.ProductJourney);
    } catch (error) {
      console.error('Error fetching journey data:', error);
    }
  };

  const fetchTransactionNotes = async (traceabilityData) => {
    const notes = {};
    for (const item of traceabilityData) {
      for (const txHash of item.TransactionHash) {
        try {
          const response = await axios.get(`${apiUrl}?txid=${txHash}`);
          const note = response.data.note;
          notes[txHash] = note;
        } catch (error) {
          console.error(`Error fetching transaction note for ${txHash}:`, error);
          notes[txHash] = 'N/A';
        }
      }
    }
    onTransactionNotes(notes);
  };

  useEffect(() => {
    if (skuid) {
      fetchTraceabilityData();
      fetchJourneyData();
    }

    if (reloadRef) {
      reloadRef.current = () => {
        fetchTraceabilityData();
        fetchJourneyData();
      };
    }
  }, [skuid]);

  return null;
}

export default AlgoData;
