import React, { useState, useEffect } from "react";
import "./CustomButtons.css";
import SvgIcon from "./SvgIcon";
import activegaugeImg from "../../../assets/Gauge.png";
import activebarcodeImg from "../../../assets/grp33.png";
import activereceiptImg from "../../../assets/ticketact.png";
import gaugeImg from "../../../assets/Gaugeactive.png";
import barcodeImg from "../../../assets/grp11.png";
import receiptImg from "../../../assets/ticket.png";
import settingsImg from "../../../assets/settings.png";
import activesettingsImg from "../../../assets/settingsactive.png";
import { useNavigate, useLocation } from "react-router-dom";
import activeOrder from "../../../assets/Receipt.png";
import orderimg from "../../../assets/Receiptactive.png";


interface ButtonItem {
  text: string;
}

const buttonItems: ButtonItem[] = [
  { text: "Dashboard" },
  { text: "User Manager" }, // Changed "Product" to "User Manager"
  { text: "Tickets" },
  { text: "Orders" },
  { text: "Settings" },
];

const initialActiveStates = {
  Dashboard: true,
  "User Manager": false, // Changed key from "Product" to "User Manager"
  Tickets: false,
  Orders: false,
  Settings: false,
};

const CustomButtons: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeStates, setActiveStates] = useState(initialActiveStates);

  useEffect(() => {
    const currentPath = location.pathname.slice(1).toLowerCase();
    setActiveStates((prevStates) => ({
      ...prevStates,
      Dashboard: currentPath === "admin/dashboard" || currentPath === "/",
      "User Manager": currentPath === "admin/user-manager",
      Tickets: currentPath === "admin/tickets",
      Orders: currentPath === "admin/orders",
      Settings: currentPath === "admin/settings",
    }));
  }, [location.pathname]);

  const handleButtonClick = (text: string) => {
    setActiveStates((prevStates) => ({
      ...prevStates,
      Dashboard: text === "Dashboard",
      "User Manager": text === "User Manager",
      Tickets: text === "Tickets",
      Orders: text === "Orders",
      Settings: text === "Settings",
    }));

    let path = text.toLowerCase();
    path = `/admin/${path.replace(" ", "-")}`; // Replace spaces with hyphens in the path
    navigate(path); // Navigate to the new path.
  };

  const getImageForButton = (text: string) => {
    switch (text) {
      case "Dashboard":
        return activeStates.Dashboard ? activegaugeImg : gaugeImg;
      case "User Manager": // Changed case from "Product" to "User Manager"
        return activeStates["User Manager"] ? activebarcodeImg : barcodeImg;
      case "Tickets":
        return activeStates.Tickets ? activereceiptImg : receiptImg;
      case "Orders":
        return activeStates.Orders ? activeOrder : orderimg;
      case "Settings":
        return activeStates.Settings ? activesettingsImg : settingsImg;
      default:
        return gaugeImg;
    }
  };

  const getActiveState = (
    key: string,
    activeStates: typeof initialActiveStates
  ) => {
    return activeStates[key as keyof typeof initialActiveStates];
  };
  return (
    <div className="custom-btn">
      <div className="custom-buttons-container">
        <SvgIcon
          images={buttonItems.map((item) => getImageForButton(item.text))}
          activeStates={buttonItems.map((item) =>
            getActiveState(item.text, activeStates)
          )}
          onIconClick={handleButtonClick}
          buttonTexts={buttonItems.map((item) => item.text)}
        />
        <div className="buttons-text-container">
          {buttonItems.map((item, index) => (
            <div
              key={index}
              className="button-text-item"
              onClick={() => handleButtonClick(item.text)}
            >
              <span
                className={`button-text ${
                  getActiveState(item.text, activeStates) ? "active" : ""
                }`}
              >
                {item.text}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomButtons;
