import React from 'react';
import './checkout.css'; // Import your CSS file for styling
import P1 from '../../../assets/p1.png';
import P2 from '../../../assets/p2.png';
import P3 from '../../../assets/p3.png';

const Checkout = (props) => {
    const data=localStorage.getItem('submodal')
    console.log(data)
  return (
    <div className='dashboard'>
     
        <div className="containering" style={{marginBottom:'100px'}}>
          <div className="navigation">
            <div className="logo">
              <i className="icon icon-basket"></i>shop42
            </div>
            <div className="secure">
              <i className="icon icon-shield"></i>
              <span>Secure Checkout</span>
            </div>
          </div>
          <div className="notifications">
            Complete Your Purchase
          </div>
        </div>

      <section className="contentsss">
        <div className="containering"></div>
        <div className="details shadow">
          <div className="details__item">
            <div className="item__image">
              <img className="iphone" src={data=="Plan1"?P1:data=="Plan2"?P2:P3}alt="" />
            </div>{data=="Plan1"?
            <div className="item__details">
              <div className="item__title">
                Basic Plan
              </div>
              <div className="item__price">
                1,000 £
              </div>
              <div className="item__quantity">
                Quantity: 1
              </div>
              <div className="item__description">
                <ul style={{}}>
                  <li>1,000 Credits</li>
                  <li>1,000 QR Code </li>
                  <li>NFC</li>
                  <li>Live Support</li>
                </ul>
              </div>
            </div>:data=="Plan2"?<div className="item__details">
              <div className="item__title">
              Standard Plan
              </div>
              <div className="item__price">
                10,000
              </div>
              <div className="item__quantity">
                Quantity: 1
              </div>
              <div className="item__description">
                <ul style={{}}>
                <li>10,000 Credits</li>
                  <li>10,000 QR Code </li>
                  <li>NFC</li>
                  <li>Live Support</li>
                </ul>
              </div>
            </div>:<div className="item__details">
              <div className="item__title">
              Premium Plan
              </div>
              <div className="item__price">
                1,00,000
              </div>
              <div className="item__quantity">
                Quantity: 1
              </div>
              <div className="item__description">
                <ul style={{}}>
                <li>1,00,000 Credits</li>
                  <li>1,00,000 QR Code </li>
                  <li>NFC</li>
                  <li>Live Support</li>
                </ul>
              </div>
            </div>
            }
          </div>
        </div>
        <div className="discount"></div>
        <div className="containering">
          <div className="payment">
            <div className="payment__title">
              Payment Method
            </div>
            <div className="payment__types">
              <div className="payment__type payment__type--cc active">
                <i className="icon icon-credit-card"></i>Credit Card</div>
              <div className="payment__type payment__type--paypal">
                <i className="icon icon-paypal"></i>Paypal</div>
              <div className="payment__type payment__type--paypal">
                <i className="icon icon-wallet"></i>SEPA</div>
              <div className="payment__type payment__type--paypal">
                <i className="icon icon-note"></i>Invoice</div>
            </div>
            <div className="payment__info">
              <div className="payment__cc">
                <div className="payment__title">
                  <i className="icon icon-user"></i>Personal Information
                </div>
                <form>
                  <div className="form__cc">
                    <div className="row">
                      <div className="field">
                        <div className="title">Credit Card Number
                        </div>
                        <input type="text" className="input txt text-validated" value="4542 9931 9292 2293" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="field small">
                        <div className="title">Expiry Date
                        </div>
                        <select className="input ddl">
                          <option selected>01</option>
                          {/* Add other options here */}
                        </select>
                        <select className="input ddl">
                          {/* Add options here */}
                        </select>
                      </div>
                      <div className="field small">
                        <div className="title">CVV Code
                          <span className="numberCircle">?</span>
                        </div>
                        <input type="text" className="input txt" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="field">
                        <div className="title">Name on Card
                        </div>
                        <input type="text" className="input txt" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="payment__shipping">
                <div className="payment__title">
                  <i className="icon icon-plane"></i> Shiping Information
                </div>
                <div className="details__user">
                  <div className="user__name">John Doe
                    <br /> 13/03/1980</div>
                  <div className="user__address">Shipping Address: 22 Street, Address
                    <br /> Country</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="containering">
          <div className="actions">
            <a href="#" className="btn action__submit">Place your Order
              <i className="icon icon-arrow-right-circle"></i>
            </a>
            <a href="#" className="backBtn">Go Back to Shop</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Checkout;
