import React, { useEffect } from "react";
import "./NotificationPopup.css"; // Import CSS for styling
import { useParams, useNavigate } from "react-router-dom";

function NotificationPopup({ message, onClose }) {
  // Close the popup automatically after 5 seconds
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
      navigate('/orders');
    }, 7000); // Adjust the time as needed

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="notification-popup">
      <div className="notification-content">
        <span className="notification-message">{message}</span>
        <button className="notification-close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
}

export default NotificationPopup;
