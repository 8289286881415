import React, { useEffect, useState } from "react";
import "./Salesoverview.css";
import SalesOver from "../../../../assets/Salesoverview.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchNfcSessions, fetchProducts } from "../../../../redux/counterSlice";
import ProductModal from "./ProductModal"; // Import the modal component

function ProductDetailsAdmin({ filteredData }) {
  const nfcsessions = useSelector((state) => state.counter.nfcsessions);
  const products = useSelector((state) => state.counter.products); // Changed to 'products' for clarity
  const dispatch = useDispatch();
  let employeeIds = [];

  if (filteredData && Array.isArray(filteredData)) {
    employeeIds = filteredData.map(data => data.employeeid);
  }

  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null); // State to hold the selected product
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  useEffect(() => {
    if (selectedEmployeeId) {
      dispatch(fetchNfcSessions(employeeIds[0]));
      dispatch(fetchProducts(employeeIds[0]));
    }
  }, [dispatch, employeeIds, selectedEmployeeId]);

  const handleProductClick = (prod) => {
    setSelectedProduct(prod);
    setIsModalOpen(true); // Open modal on product click
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <div
      style={{
        height: "190px",
        width: "50%",
        border: "none",
        borderRadius: "11px",
        padding: "10px",
        background: "white",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        overflowY: "auto",
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="revenue-header">
          <img src={SalesOver} style={{ width: '19px', marginRight: '5px' }} alt="" />
          Product Details
        </div>
      </div>

      {products.length > 0 ? (
        products.map((prod, index) => (
          <div 
            key={index} 
            style={{ display: 'flex', alignItems: 'center', marginTop: '10px', paddingBottom: '10px', borderBottom: '1px solid #ddd', cursor: 'pointer' }}
            onClick={() => handleProductClick(prod)} // Handle product click
          >
            <img 
              src={prod.photo[0]} 
              alt={prod.title} 
              style={{ width: '50%', height: 'auto', borderRadius: '8px', marginRight: '10px' }} 
            />
            <div style={{ flex: 1 }}>
              <h3 style={{ margin: '10px 0', fontSize: '16px', color: '#333' }}>{prod.title}</h3>
              <p style={{ fontSize: '14px', color: '#666' }}>
                Last Modified: {new Date(prod.lastmodification).toLocaleString()}
              </p>
              <p style={{ fontSize: '14px', color: '#999' }}>Product {index + 1}</p>
            </div>
          </div>
        ))
      ) : (
        <p style={{ textAlign: 'center', color: '#666' }}>No products available.</p>
      )}

      {/* Modal for displaying product details */}
      {selectedProduct && (
        <ProductModal 
          isOpen={isModalOpen} 
          onClose={closeModal} 
          selectedProduct={selectedProduct} 
        />
      )}
    </div>
  );
}

export default ProductDetailsAdmin;
