import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Inventory from "./Inventory/Inventory";
import Analysis from "./Sales";
import Chatbot from "react-chatbot-kit";
import config from "./chatbotConfig";
import MessageParser from "./MessageParser";
import QRCodeForm from "./Products/genQRandBarcode/QRCodeForm";
import ActionProvider from "./ActionProvider";
import "react-chatbot-kit/build/main.css";
import ImageQrCode from "./Products/LablePrinting/ImageQrCode";
import ChangePassword from "./auth/ChangePassword/ChangePassword";
import ForgotPassword from "./auth/forgotPassword/ForgotPassword";
import Login from "./auth/Login/Login";
import Products from "./Products/Products";
import PrivateRoute from './PrivateRoute';
import Orders from "./Order/Order";
import Counterfeits from "./Counterfeits/Counterfeits";
import Settings from "./Settings/Settings";
import AdminSettings from "./superadmin/Settings/Settings";
import Tag from "./Products/RequestTag/Tag";
import AiTags from "./Products/RequestTag/AiTags";
import Invoice from "./Products/RequestTag/components/Invoice";
import ProductDetails from "./Products/ProductDetail/ProductDetails";
import Form from "./Products/ProductForm/Form";
import Formedit from "./Products/ProducteditForm/Formedit";
import Profile from "./Profile/Profile";
import SidebarAdmin from "./superadmin/Sidebar";
import DashboardAdmin from "./superadmin/Dashboard";
import NavbarAdmin from "./superadmin/Navbar";
import AdminOrder from "./superadmin/Order/Order";
import Sidebar from "./Sidebar";
import BrandTickets from './Tickets/BrandTickets';
import UserManager from "./superadmin/UserManager";
import Dashboard from "./Dashboard";
import SingUp from "./auth/SignUp/SignUp";
import Ticket from './superadmin/Tickets/Tickets';
import Checkout from "./Settings/Subcription/checkout/checkout";
import Navbar from "./Navbar";
import Pricing from "./Settings/Pricing/Pricing";
import chat from "./assets/chat.png";
import Support from "./Support/Support";
import Privacy from "./Settings/Privacy/Privacy";
import Terms from "./Settings/TermsCondition/Terms";
import Document from "./Settings/Docs/Document";

function AdminRoutes() {
  return (
    <>
      <SidebarAdmin />
      <div className="content">
        <NavbarAdmin />
        <Routes>
          <Route path="/dashboard" element={<PrivateRoute component={DashboardAdmin} />} />
          <Route path="/user-manager" element={<PrivateRoute component={UserManager} />} />
          <Route path="/orders" element={<PrivateRoute component={AdminOrder} />} />
          <Route path="/tickets" element={<PrivateRoute component={Ticket} />} />
          <Route path="/settings" element={<PrivateRoute component={AdminSettings} />} />
        </Routes>
      </div>
    </>
  );
}

function App() {
  const [showChatbot, setShowChatbot] = React.useState(false);
  const chatbotRef = useRef(null);
  const chatImgRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      chatbotRef.current &&
      !chatbotRef.current.contains(event.target) &&
      chatImgRef.current &&
      !chatImgRef.current.contains(event.target)
    ) {
      setShowChatbot(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<SingUp />} />
          <Route path="/forgetPassword" element={<ForgotPassword />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/admin/*" element={<AdminRoutes />} />
          <Route
            path="/*"
            element={
              <>
                <Sidebar />
                <div className="content">
                  <Navbar />
                  <Routes>
                    <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
                    <Route path="/support" element={<PrivateRoute component={Support} />} />
                    <Route path="/Analysis" element={<PrivateRoute component={Analysis} />} />
                    <Route path="/inventory/:id" element={<PrivateRoute component={Inventory} />} />
                    <Route path="/product" element={<PrivateRoute component={Products} />} />
                    <Route path ='/QRCodeForm' element={<PrivateRoute component={QRCodeForm}/>}/>
                    <Route path="/product/:title/:id" element={<PrivateRoute component={ProductDetails} />} />
                    <Route path="/counterfeits" element={<PrivateRoute component={Counterfeits} />} />
                    <Route path="/addproduct" element={<PrivateRoute component={Form} />} />
                    <Route path="/editproduct/:title/:id" element={<PrivateRoute component={Formedit} />} />
                    <Route path="/tickets" element={<PrivateRoute component={BrandTickets} />} />
                    <Route path="/lable-printing/:title/:id" element={<PrivateRoute component={ImageQrCode} />} />
                    <Route path="/orders" element={<PrivateRoute component={Orders} />} />
                    <Route path="/orderInvoice" element={<PrivateRoute component={Invoice} />} />
                    <Route path="/productcart" element={<PrivateRoute component={Tag} />} />
                    <Route path="/productcart-ai-lables" element={<PrivateRoute component={AiTags} />} />
                    <Route path="/settings" element={<PrivateRoute component={Settings} />} />
                    <Route path="/profile" element={<PrivateRoute component={Profile} />} />
                    <Route path="/Checkout" element={<PrivateRoute component={Checkout} />} />
                    <Route path="/pricing" element={<PrivateRoute component={Pricing} />} />
                    <Route path="/privacy-policies" element={<PrivateRoute component={Privacy} />} />
                    <Route path="/terms-contitions" element={<PrivateRoute component={Terms} />} />
                    <Route path="/dev-docs" element={<PrivateRoute component={Document} />} />
                  </Routes>
                  <img
                    src={chat}
                    className="chat-support-chat-img"
                    alt="Chat"
                    onClick={() => setShowChatbot(!showChatbot)}
                    ref={chatImgRef}
                    style={{borderRadius:"50%"}}
                  />
                  {showChatbot && (
                    <div className="chatbot-container" ref={chatbotRef}>
                      <Chatbot
                        config={config}
                        messageParser={MessageParser}
                        actionProvider={ActionProvider}
                      />
                    </div>
                  )}
                </div>
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
