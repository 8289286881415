import React, { useState } from 'react';
import './Slider.css';

const ImageSlider = ({images}) => {
    const [selectedImage, setSelectedImage] = useState(images[0]);
    
  
    const handleImageClick = (image) => {
      setSelectedImage(image);
    };
  
    return (
      <div className="slider-container">
        <div className="selected-image">
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              className="selected-image-img"
            />
          )}
        </div>
        <div className="image-options">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Option ${index + 1}`}
              className={`image-option-img ${selectedImage === image ? 'selected selected-img' : ''}`}
              onClick={() => handleImageClick(image)}
            />
          ))}
        </div>
      </div>
    );
  };
  
  export default ImageSlider;