import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./FileUploader.css";

const FileUploader = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadStatus("");
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setUploadStatus("Please select a file first.");
      return;
    }

    setLoading(true); // Show loading spinner
    try {
      const fileData = await file.arrayBuffer();
      const base64File = btoa(
        String.fromCharCode(...new Uint8Array(fileData))
      );

      await axios.post(
        "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/upload",
        base64File,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setUploadStatus("File uploaded successfully!");
      setLoading(false); // Hide loading spinner

      // Show success toast after a 5-second delay
      setTimeout(() => {
        toast.success("File uploaded successfully!");
      }, 5000);
    } catch (error) {
      setUploadStatus("Failed to upload file.");
      setLoading(false); // Hide loading spinner
      console.error("Upload error:", error);
      toast.error("Failed to upload file.");
    }
  };

  const uploadButtonStyles = {
    background: "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
    boxShadow:
      "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset, 0px 4px 4px 0px #00000040",
    color: "white",
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    height: "40px",
    borderRadius: "24px",
    fontFamily: "'Roboto', sans-serif",
    transition: "background-color 0.3s ease",
  };

  return (
    <div className="file-uploader">
      <h2>Upload Supply Chain XLSX File</h2>
      <p className="instruction-text">Please upload the latest supply chain file to update the database.</p>
      <form onSubmit={handleSubmit} className="upload-form">
        <label className="file-label">
          <input
            type="file"
            accept=".xlsx"
            onChange={handleFileChange}
            className="file-input"
          />
          <span className="file-button">Choose File</span>
          {file && <span className="file-name">{file.name}</span>}
        </label>
        
        <button type="submit" style={uploadButtonStyles} disabled={loading}>
          {loading ? (
            <span className="loading-spinner"></span>
          ) : (
            "Upload File"
          )}
        </button>
      </form>

      {/* Toast container for displaying toast notifications */}
      <ToastContainer />
    </div>
  );
};

export default FileUploader;
