import React, { useState, useEffect } from "react";
import "./Products.css";
import addIcon from "../assets/plus.png";
import dots from "../assets/DotsThreeOutlineVertical.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import tickets from "./../assets/ticketact.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchNfcSessions,
  fetchProducts,
  fetchTenantData,
} from "../redux/counterSlice";
import ListView from "../assets/list.png";
import CardView from "../assets/SquaresFour.png";
import ListViewActive from "../assets/listactive.png";
import CardViewActive from "../assets/squareactive.png";

function Products() {
  const navigate = useNavigate();
  const product = useSelector((state) => state.counter.products);
  const dispatch = useDispatch();
  const [activeDropdown, setActiveDropdown] = useState(null); // Tracks the active dropdown
  const [viewType, setViewType] = useState("card");

  const tenant = localStorage.getItem("tenant").toLowerCase();

  function formatTimestampToReadableDate(timestamp) {
    const date = new Date(timestamp);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant));
    dispatch(fetchTenantData(data.idToken.payload.name));

    // Function to close the dropdown if clicking outside
    const closeDropdown = (event) => {
      if (
        !event.target.closest(".product-dropdown-menu") &&
        !event.target.closest(".small-icon-container")
      ) {
        setActiveDropdown(null);
      }
    };

    // Add when the component mounts
    document.addEventListener("click", closeDropdown);

    // Cleanup to remove when the component unmounts
    return () => document.removeEventListener("click", closeDropdown);
  }, [dispatch]);


  useEffect(()=>{
    if(product.length > 0 ){
      const arr=[]
      product.map((prod)=>arr.push(prod.photo[0]))
      localStorage.setItem('photolist',JSON.stringify(arr));
    }

  },[product])
  const toggleDropdown = (productId) => {
    setActiveDropdown(activeDropdown === productId ? null : productId);
  };

  const closeDropdownOptionClick = () => {
    setActiveDropdown(null); // Close the dropdown when an option is clicked
  };

  const closeDropdownOptionClickedit = (product) => {
    navigate(`/editproduct/${product.title}/${product.productId}`);
  };

  const closeDropdownOptionClickdelete = (product) => {
    const response = window.confirm(
      "Are you sure you want to delete this property?"
    );

    if (response) {
      try {
        const apiUrl = `https://xc5ph8i3p0.execute-api.ap-south-1.amazonaws.com/prod/upload/?id=${product.productId}`;

        const deleteResponse = fetch(apiUrl, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            // You may include additional headers if needed, like authentication headers.
          },
        });

        if (deleteResponse) {
          // Property deleted successfully

          toast.error(`${product.title} item deleted successfully!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            //transition: Bounce,
          });

          setTimeout(() => {
            window.location.reload();
            navigate("/product");
          }, 5000);

          // You can perform any additional actions after successful deletion
          // e.g., navigate to another page or update the UI.
        } else {
          // Handle API error for delete request
          console.error("Error deleting property:", deleteResponse);
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error during delete request:", error);
        navigate("/dashboard");
      }
    }
  };

  const toggleView = (type) => {
    setViewType(type);
  };

  const handeladdproduct = () => {
    if (product.length < 4) {
      navigate("/addproduct");
    } else {
      toast.warn(
        "You are only eligible to create 4 products in the free tier.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  return (
    <div className="products">
      <ToastContainer />
      <div className="products-header">
        <div className="products-text">
          <p>Product List</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "16px",
            }}
          >
            <img
              src={viewType === "card" ? CardViewActive : CardView}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => toggleView("card")}
            />
            <div
              style={{
                borderRight: "1px solid grey",
                height: "27px",
                margin: "5px",
              }}
            ></div>
            <img
              src={viewType === "list" ? ListViewActive : ListView}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => toggleView("list")}
            />
          </div>
          <button
            className="add-products-btn"
            onClick={handeladdproduct}
            id="add-product-btn"
          >
            <img src={addIcon} alt="Add" className="add-icon" />
            Add Products
          </button>
        </div>
      </div>
      {product.length === 0 && (
        <div className="no-products-message">
          <p>
            No products available. Click the "Add Products" button to get
            started.
          </p>
        </div>
      )}
      {product.length > 0 &&
        (viewType === "card" ? (
          <div className="product-cards">
            {product.map((product) => (
              <div
                key={product.productId}
                className="product-card"
                style={{ overflow: "hidden" }}
              >
                <div className="product-info">
                  <p
                    className="product-name"
                    onClick={() =>
                      navigate(
                        `/product/${product.title}/${product.productId}`,
                        {
                          state: {
                            productId: product.productId,
                            title: product.title,
                          },
                        }
                      )
                    }
                  >
                    {product.title}
                  </p>
                  <div
                    className="small-icon-container"
                    onClick={() => toggleDropdown(product.productId)}
                  >
                    <img src={dots} alt="Options" className="small-icon" />
                  </div>
                  <div
                    className={`product-dropdown-menu ${
                      activeDropdown === product.productId ? "show" : ""
                    }`}
                    onClick={closeDropdownOptionClick}
                  >
                    <p onClick={() => closeDropdownOptionClickedit(product)}>
                      Edit
                    </p>
                    <p onClick={() => closeDropdownOptionClickdelete(product)}>
                      Delete
                    </p>
                    <p
                      onClick={() =>
                        navigate(
                          `/product/${product.title}/${product.productId}`,
                          {
                            state: {
                              productId: product.productId,
                              title: product.title,
                            },
                          }
                        )
                      }
                    >
                      Details
                    </p>
                  </div>
                </div>
                <div
                  className="product-image-container"
                  onClick={() =>
                    navigate(`/product/${product.title}/${product.productId}`, {
                      state: {
                        productId: product.productId,
                        title: product.title,
                      },
                    })
                  }
                >
                  <img
                    onClick={() =>
                      navigate(`/product/${product.title}/${product.productId}`)
                    }
                    src={product.photo[0]}
                    alt={product.title}
                    className="product-image"
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="product-table">
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>SKU ID</th>
                  <th>Created On</th>
                  <th>Last Modified</th>
                  <th>Call to Action</th>
                </tr>
              </thead>
              <tbody>
                {product.map((products) => (
                  <tr key={products.productId}>
                    <td
                      onClick={() =>
                        navigate(
                          `/product/${products.title}/${products.productId}`
                        )
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "4px",
                          }}
                          src={products.photo[0]}
                          alt=""
                        />
                        <span
                          className="product-table-first"
                          style={{ marginLeft: "4px" }}
                        >
                          {products.title}
                        </span>
                      </div>
                    </td>
                    <td
                      onClick={() =>
                        navigate(
                          `/product/${products.title}/${products.productId}`
                        )
                      }
                      className="product-table-first"
                    >
                      {products.productId}
                    </td>
                    <td
                      onClick={() =>
                        navigate(
                          `/product/${products.title}/${products.productId}`
                        )
                      }
                      className="product-table-first"
                    >
                      {formatTimestampToReadableDate(products.createdAt)}
                    </td>
                    <td
                      onClick={() =>
                        navigate(
                          `/product/${products.title}/${products.productId}`
                        )
                      }
                      className="product-table-first"
                    >
                      {formatTimestampToReadableDate(products.lastmodification)}
                    </td>
                    <td>
                      <div>
                        <button
                          className="product-request-button"
                          style={{ marginRight: "5px" }}
                          onClick={() => closeDropdownOptionClickedit(products)}
                        >
                          Edit
                        </button>
                        <button
                          className="product-request-button"
                          onClick={() =>
                            closeDropdownOptionClickdelete(products)
                          }
                        >
                          Delete
                        </button>
                        <button
                          style={{ marginLeft: "5px" }}
                          className="product-request-button"
                          onClick={() =>
                            navigate(
                              `/product/${products.title}/${products.productId}`,
                              {
                                state: {
                                  productId: products.productId,
                                  title: products.title,
                                },
                              }
                            )
                          }
                        >
                          Details
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
    </div>
  );
}

export default Products;
