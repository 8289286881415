import React from 'react';
import './Timeline.css';

interface TimelineProps {
  status: 'Pending' | 'Delivered' | 'Download';
  itemNames: string[];
}


const Timeline: React.FC<TimelineProps> = ({ status, itemNames }) => {
  const isNFC = itemNames.includes("NFC213") || itemNames.includes("NFC423");
  const isQRCode = itemNames.includes("QR code");

  const getStatusClasses = () => {
    let classes = {
      accepted: "circle green",
      pickedUp: "circle",
      inTransit: "circle",
      delivered: "circle",
      lineToInTransit: "line",
      lineToEnd: "line",
    };

    if (isNFC) {
      // NFC item classes
      classes.pickedUp = "circle green";
      classes.inTransit = status === 'Delivered' ? "circle green" : "circle orange";
      classes.delivered = status === 'Delivered' ? "circle green" : "circle grey";
      classes.lineToInTransit = "line green";
      classes.lineToEnd = status === 'Delivered' ? "line green" : "line orange";
    } else if (isQRCode) {
      // QR Code item classes
      classes.inTransit = "circle grey"; // 'In Transit' represents 'Download' for QR Code
      classes.lineToInTransit = status === 'Download' ? "line green" : "line orange";
      classes.delivered = status === 'Download' ? "circle green" : "circle grey";
    }

    return classes;
  };

  const classes = getStatusClasses();

  return (
    <div className="timeline-container">
      {/* Accepted circle */}
      <div className={classes.accepted}>
        <div className="circle"></div>
        <div className="timeline-label">Accepted</div>
      </div>
      {/* Line to next circle */}
      <div className={classes.lineToInTransit}></div>
      {/* NFC or QR Code specific rendering */}
      {isNFC ? (
        <>
          {/* NFC-specific circles and lines */}
          <div className={classes.pickedUp}>
            <div className="circle"></div>
            <div className="timeline-label">Picked up</div>
          </div>
          <div className={classes.lineToInTransit}></div>
          <div className={classes.inTransit}>
            <div className="circle"></div>
            <div className="timeline-label">In Transit</div>
          </div>
          <div className={classes.lineToEnd}></div>
          <div className={classes.delivered}>
            <div className="circle"></div>
            <div className="timeline-label">Delivered</div>
          </div>
        </>
      ) : isQRCode ? (
        <>
          {/* QR Code specific circle */}
          <div className={classes.delivered}>
            <div className="circle"></div>
            <div className="timeline-label">Download</div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Timeline;