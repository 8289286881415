import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const PieChartComponent = () => {
  useEffect(() => {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart = am4core.create("chartdivpiechartcomponent", am4charts.PieChart);

    // Set initial opacity for fade-in effect
    chart.hiddenState.properties.opacity = 0;

    // Set chart data
    chart.data = [
      { country: "Lithuania", value: 260 },
      { country: "Czechia", value: 230 },
      { country: "Ireland", value: 200 },
      { country: "Germany", value: 165 },
      { country: "Australia", value: 139 },
      { country: "Austria", value: 128 }
    ];

    // Create series
    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.radiusValue = "value";
    series.dataFields.category = "country";
    series.slices.template.cornerRadius = 6;
    series.colors.step = 3;

    // Adjust series for hidden state
    series.hiddenState.properties.endAngle = -90;

    // Create legend
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, []);

  return (
    <div id="chartdivpiechartcomponent" style={{ width: "100%", height: "450px" }}></div>
  );
};

export default PieChartComponent;
