import React from 'react';
import './index.css';
import trendup from '../../../assets/Trend Up.png';

function Dcode() {
  return (
    <div className="dcode-container">
      <div className="dcode-card">
        <div className="dcode-card-header">
          <img src={trendup} className="dcode-card-img" alt="icon" />
          <span className="dcode-card-title">Total Scans</span>
        </div>
        <div className="dcode-card-content">
          <p>This is the content for card 1.</p>
          <span className="dcode-card-subtitle">Additional Info</span>
        </div>
      </div>

      <div className="dcode-card">
        <div className="dcode-card-header">
          <img src={trendup} className="dcode-card-img" alt="icon" />
          <span className="dcode-card-title">Total Downloads</span>
        </div>
        <div className="dcode-card-content">
          <p>This is the content for card 2.</p>
          <span className="dcode-card-subtitle">Additional Info</span>
        </div>
      </div>

      <div className="dcode-card">
        <div className="dcode-card-header">
          <img src={trendup} className="dcode-card-img" alt="icon" />
          <span className="dcode-card-title">Total Landing Pages</span>
        </div>
        <div className="dcode-card-content">
          <p>This is the content for card 3.</p>
          <span className="dcode-card-subtitle">Additional Info</span>
        </div>
      </div>

      <div className="dcode-card">
        <div className="dcode-card-header">
          <img src={trendup} className="dcode-card-img" alt="icon" />
          <span className="dcode-card-title">Total Users</span>
        </div>
        <div className="dcode-card-content">
          <p>This is the content for card 4.</p>
          <span className="dcode-card-subtitle">Additional Info</span>
        </div>
      </div>
    </div>
  );
}

export default Dcode;
