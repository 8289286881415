import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const ReviewMap = ({reviewdata}) => {
  useEffect(() => {
    // Themes begin 
    if (!reviewdata || !reviewdata.reviews) {
        return; // Handle the case where reviewdata is undefined or does not have the reviews property
      }
      const data = reviewdata.reviews.map(review => {
        const { location, Sentiment } = review;
        let latitude, longitude, title;
    
        // Check if location and location.city properties exist
        if (location && location.city) {
            title = location.city;
        } else {
            // Set default title if location.city is undefined
            title = '';
        }
    
        // Check if location and location.loc properties exist
        if (location && location.loc) {
            [latitude, longitude] = location.loc.split(',').map(parseFloat);
        } else {
            // Set default latitude and longitude if location.loc is undefined
            latitude = 0;
            longitude = 0;
        }
    
        let color;
    
        // Assign color based on sentiment
        if (Sentiment.sentiment === 'positive') {
            color = 'green';
        } else if (Sentiment.sentiment === 'neutral') {
            color = 'blue';
        } else if (Sentiment.sentiment === 'negative') {
            color = 'red';
        }
    
        return {
            title,
            latitude,
            longitude,
            color
        };
    });
    
    
  
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create map instance
    let chart = am4core.create("ReviewMap", am4maps.MapChart);

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    // Exclude Antarctica
    polygonSeries.exclude = ["AQ"];

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.polygon.fillOpacity = 0.6;

    // Create hover state and set alternative fill color
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = chart.colors.getIndex(0);

    // Add image series
    let imageSeries = chart.series.push(new am4maps.MapImageSeries());
    imageSeries.mapImages.template.propertyFields.longitude = "longitude";
    imageSeries.mapImages.template.propertyFields.latitude = "latitude";
    imageSeries.mapImages.template.tooltipText = "{title}";
    imageSeries.mapImages.template.propertyFields.url = "url";

    let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
    circle.radius = 3;
    circle.propertyFields.fill = "color";
    circle.nonScaling = true;

    let circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
    circle2.radius = 3;
    circle2.propertyFields.fill = "color";

    circle2.events.on("inited", function (event) {
      animateBullet(event.target);
    });

    function animateBullet(circle) {
      let animation = circle.animate(
        [
          { property: "scale", from: 1 / chart.zoomLevel, to: 5 / chart.zoomLevel },
          { property: "opacity", from: 1, to: 0 }
        ],
        1000,
        am4core.ease.circleOut
      );
      animation.events.on("animationended", function (event) {
        animateBullet(event.target.object);
      });
    }

    let colorSet = new am4core.ColorSet();

    imageSeries.data = data
 

    return () => {
      chart.dispose();
    };
  }, [reviewdata]);

  return <div id="ReviewMap" style={{ width: "100%", height: "500px" }}></div>;
};

export default ReviewMap;
