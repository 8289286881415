import React ,{useState, useEffect} from "react";
import "./Transactions.css";
import trendup from '../../../../assets/Trend Up.png';
import trenddown from '../../../../assets/Trend Down.png';
import transaction from '../../../../assets/transaction.png';

function Transactions({nfcsessions, product ,filteredData,selectedBrand}) {
  function formatNumber(number) {
    if (number >= 1000 && number < 10000) {
      return (number / 1000).toFixed(1) + 'k';
    } else if (number >= 10000) {
      return (number / 1000).toFixed(0) + 'k';
    } else {
      return number.toFixed(1);
    }
  }
  
  const [totalCredits, setTotalCredits] = useState(0);

  useEffect(() => {
    if (Array.isArray(filteredData)) {
      let credits = 0;
      filteredData.forEach(item => {
        if (item.hasOwnProperty('credits') && typeof item.credits === 'number') {
          credits += item.credits;
        }
      });
      setTotalCredits(credits);
    }
  }, [filteredData]);
  
  return (
    <div
      style={{
        height: "190px",
        width: "43%",
        border: "none",
        borderRadius: "11px",
        padding: "10px",
        background: "white",  
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >

          
           
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <div className="transaction-header">
    <img src={transaction} style={{ width: '19px', marginRight: '5px' }} alt="" />
    {selectedBrand!='All'? `${selectedBrand}'s Specific`:''}
  </div>
  
</div>

  

      <div style={{ display: "flex", justifyContent: "space-evenly", marginTop:'20px' }}>
        <div>
          <span className="transaction-sub-header">Credits</span>
          <div style={{ display:'flex', flexDirection:"column", marginTop:'4px'}}>
            <span className="transaction-font-main"> {formatNumber(totalCredits)}</span>
            <div style={{ display: "flex", alignItems:'center' }}>
            <img style={{width:'16px'}} src={trendup} alt="" />
            <span className="transaction-percent">15%</span>
            </div>
            <span className="transaction-past-timeline">from past hour</span>

        </div>
        </div>
        <div>
          <span className="transaction-sub-header">Scans</span>
          <div style={{ display:'flex', flexDirection:"column", marginTop:'4px'}}>
            <span className="transaction-font-main"> {nfcsessions.length}</span>
            <div style={{ display: "flex", alignItems:'center' }}>
            <img style={{width:'16px'}} src={trenddown} alt="" />
            <span className="transaction-percent">13%</span>
            </div>
            <span className="transaction-past-timeline">from past hour</span>

        </div>
        </div>
        <div>
          <span className="transaction-sub-header">Products</span>
          <div style={{ display:'flex', flexDirection:"column", marginTop:'4px'}}>
            <span className="transaction-font-main"> {product.length}</span>
            <div style={{ display: "flex", alignItems:'center' }}>
            <img style={{width:'16px'}} src={trendup} alt="" />
            <span className="transaction-percent">23%</span>
            </div>
            <span className="transaction-past-timeline">from past hour</span>

        </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
