import React, { useEffect, useState, useRef } from "react";
import "./Status.css";
import report from "../../assets/report.png";
import Timeline from "../Timeline/Timeline";
import productImage from "../../assets/ghee.jpeg";
import Chatbot from "react-chatbot-kit";
import config from "../../chatbotConfig";
import MessageParser from "../../MessageParser";
import ActionProvider from "../../ActionProvider";
import "react-chatbot-kit/build/main.css";

const Status = ({ productName, itemNames, status, order, handleClose }) => {
  const [showChatbot, setShowChatbot] = useState(false);
  const chatbotRef = useRef(null);

  const handleClickOutside = (event) => {
    if (chatbotRef.current && !chatbotRef.current.contains(event.target)) {
      setShowChatbot(false);
    }
  };
  const handleInputChange = () => {
    handleClose(order.requestBody.link);
  };
  const isNFC = itemNames[0].nfc.includes("NFC213") || itemNames[0].nfc.includes("AI-Tags");
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const nfcItems = order.requestBody.rows
    ? order.requestBody.rows.map((item) => item.nfc)
    : [];
  const productImageSrc = order.productImage || productImage;

  return (
    <div className="status-wrapper">
      <div className="status-container">
        <div className="status-left-section">
          <img
            src={productImageSrc}
            alt="Product"
            className="status-product-image"
          />
          <div className="status-product-details">
            <h4 className="status-product-name">{productName}</h4>
            <div>
              {itemNames &&
                Array.isArray(itemNames) &&
                itemNames.length > 0 &&
                itemNames.map((itemName, index) => (
                  <p key={index} className="status-item-name">
                    {itemName.nfc}
                  </p>
                ))}
            </div>
          </div>
        </div>
        <div className="status-right-section">
          <div className="timeline-status-container">
            <Timeline status={status} itemNames={nfcItems} />
          </div>
        </div>
      </div>
      <div className="actions-container">
        <div className="report-problem-container">
          {!isNFC && (
            <button className="redownload-qr" onClick={handleInputChange}>
              Re-Download QR Code
            </button>
          )}
          <img src={report} alt="Report" className="report-icon" />
          <span
            className="report-problem-text"
            onClick={() => setShowChatbot(!showChatbot)}
          >
            Report Problem
          </span>
          {showChatbot && (
            <div className="chatbot-container" ref={chatbotRef}>
              <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Status;
