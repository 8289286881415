import React from "react";
import Draggable from 'react-draggable';
import demo from "../../../assets/play.png";
import "./Seach.css";
import Tooltip from "../navtoottip/Tooltip";

function PopupModal({ onClose }) {
  return (
    <Draggable>
      <div className="search-popup-modal">
        <div className="search-popup-header">
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              cursor: "pointer",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: "#f00",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={onClose}
          >
            <span style={{ color: "#fff" }}>×</span>
          </div>
        </div>
        <div>
          <video controls style={{ width: '100%', marginTop: '20px' }}>
            <source src="https://aiclearningvideos.s3.ap-south-1.amazonaws.com/Demo+with+caption.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </Draggable>
  );
}

function Search() {
  const [showPopup, setShowPopup] = React.useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div>
      <Tooltip text="Demo">
        <div className="icon-circle" onClick={togglePopup}>
          <img src={demo} alt="Search" className="img-info" />
        </div>
      </Tooltip>
      {showPopup && <PopupModal onClose={togglePopup} />}
    </div>
  );
}

export default Search;
