import React from "react";
import "./LinkList.css";
import axios from 'axios'
import { createChatBotMessage } from "react-chatbot-kit";

// Import the SupportMessage component
const SupportMessage = () => (
  <div>
    Click <a style={{color:"white"}} href="/support">here</a> to see your ticket.
  </div>
);

const LinkList2 = (props) => {
  const { options, setState } = props;

  const handleClick = async (option) => {
    if (option.text === "Others") {
      const userMessage = prompt("Please enter the details of your issue:");
      if (userMessage) {
        const brandName = localStorage.getItem("tenant").toUpperCase();
        const ticketid = `TIC${brandName}${Date.now()}`;
        const userMessageticket = `Your TicketId : ${ticketid} with Issue ${userMessage} is created`;
        const message = createChatBotMessage(userMessageticket);

        // Add the SupportMessage component here
        const supportMessage = createChatBotMessage(<SupportMessage />);
        
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, message, supportMessage],
          awaitingUserInput: true,
          issueType: option.text,
        }));
        await sendTicketToAPI(userMessage, ticketid); 
      }
    } else {
      const brandName = localStorage.getItem("tenant").toUpperCase();
      const ticketid = `TIC${brandName}${Date.now()}`;
      const userMessageticket = `Your TicketId : ${ticketid} with ${option.text} is created`;
      const message = createChatBotMessage(userMessageticket);

      // Add the SupportMessage component here
      const supportMessage = createChatBotMessage(<SupportMessage />);
      
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message, supportMessage],
        awaitingUserInput: true,
        issueType: option.text,
      }));
      await sendTicketToAPI(option.text, ticketid);
      //   alert('Your ticket has been submitted.');
    }
  };

  const sendTicketToAPI = async (issueType, ticketid) => {
    console.log(issueType, ticketid);
    const data = JSON.parse(localStorage.getItem("user"));
    console.log("Authentication successful:", data.idToken.payload.email);
    const postdata = {
      id: ticketid,
      credtedby: data.idToken.payload.email,
      Status: "open",
      createdAt: Date.now(),
      brand: data.idToken.payload.name,
      ticketHeading: issueType,
      time:Date.now(),
      conversation: [
        {
          time: Date.now(),
          message: issueType,
          sender: data.idToken.payload.email,
        },
      ],
    };
    try {
      const response = await fetch(
        "https://bxcswtw14g.execute-api.ap-south-1.amazonaws.com/prod/arvoTickets",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postdata),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Success:", data);
      const usename= localStorage.getItem('fname');
      const parts = usename.split('-');
      const datas = JSON.parse(localStorage.getItem('user') || '{}');
      const emailData = {
        recipient_email:  datas.idToken.payload.email,
        brand_name: datas.idToken.payload.name,
        first_name:    parts[0],
        template_type: 'ticket_generation', // or 'password_change', 'ticket_generation', 'qr_code_download'
         "ticket_id": ticketid,
        "issue_description": issueType,
        date_raised: formatDate(),
        date_generated:  formatDate(),
       // description: propertyDetail.title,
      //attachment_url:data.imageUrl
      };

      try {
        const response = await axios.post(
          'https://4y53b9gp2k.execute-api.ap-south-1.amazonaws.com/prod/emails',
          emailData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('Email sent successfully!', response.data);
      } catch (error) {
        console.error('Error sending email:', error.response ? error.response.data : error.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function formatDate() {
    const date = new Date();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Suffix for the day
    const suffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // 4-20
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    return `${day}${suffix(day)} ${month} ${year}`;
}

  return (
    <div className="link-list-item">
      {options.map((option) => (
        <button
          key={option.id}
          className="link-list-item-url"
          onClick={() => handleClick(option)}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

export default LinkList2;
