import React from "react";

interface SvgIconProps {
  images: string[];
  activeStates: boolean[];
  onIconClick: (text: string) => void;
  buttonTexts: string[];
}

const SvgIcon: React.FC<SvgIconProps> = ({ images, activeStates, onIconClick, buttonTexts }) => {
  const imagePositions = [
    { x: "24%", y: "6%" },
    { x: "24%", y: "31%" },
    { x: "24%", y: "55%" },
    { x: "24%", y: "80%" },
    { x: "24%", y: "105%" },
  ];

  const svgHeight = 170;
  const imageHeight = 24;

  return (
<svg xmlns="http://www.w3.org/2000/svg" width="44" height="212" viewBox="0 0 44 212" fill="none">
  <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M44 22C44 29.5876 40.1588 36.2781 34.315 40.2329C32.6919 41.3313 32.6919 44.6687 34.315 45.7671C40.1588 49.7219 44 56.4124 44 64C44 71.5876 40.1588 78.2781 34.315 82.2329C32.6919 83.3313 32.6919 86.6687 34.315 87.7671C40.1588 91.7219 44 98.4124 44 106C44 113.588 40.1588 120.278 34.315 124.233C32.6919 125.331 32.6919 128.669 34.315 129.767C40.1588 133.722 44 140.412 44 148C44 155.588 40.1588 162.278 34.315 166.233C32.6919 167.331 32.6919 170.669 34.315 171.767C40.1588 175.722 44 182.412 44 190C44 202.15 34.1503 212 22 212C9.84974 212 0 202.15 0 190C0 182.412 3.84117 175.722 9.68502 171.767C11.3081 170.669 11.3081 167.331 9.68502 166.233C3.84117 162.278 0 155.588 0 148C0 140.412 3.84117 133.722 9.68502 129.767C11.3081 128.669 11.3081 125.331 9.68502 124.233C3.84117 120.278 0 113.588 0 106C0 98.4124 3.84117 91.7219 9.68502 87.7671C11.3081 86.6687 11.3081 83.3313 9.68502 82.2329C3.84117 78.2781 0 71.5876 0 64C0 56.4124 3.84117 49.7219 9.68503 45.7671C11.3081 44.6687 11.3081 41.3313 9.68503 40.2329C3.84117 36.2781 0 29.5876 0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22Z" fill="#E2EEFF"/>           {images.map((imageSrc, index) => (
        <g key={index} onClick={() => onIconClick(buttonTexts[index])}>
          <circle
            cx="22"
            cy={(parseFloat(imagePositions[index].y) / 100) * svgHeight + (imageHeight / 2)}
            r="19"
            fill="#0956CC"
            display={activeStates[index] ? "block" : "none"}
          />
          <image
            href={imageSrc}
            x={imagePositions[index].x}
            y={((parseFloat(imagePositions[index].y) / 100) * svgHeight).toString()}
            height={imageHeight.toString()}
            width={imageHeight.toString()}
          />
        </g>
      ))}
    </svg>
  );
};

export default SvgIcon;

