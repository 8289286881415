import React from 'react';
import './Terms.css';

function Terms() {
  return (
    <div className='terms-and-condition'>
      <div className='terms-header'>
        <span>Terms and Conditions</span>
      </div>
      <div className='terms-content'>
        <h2>Introduction</h2>
        <hr />
        <p>Welcome to ARVO Integrated Cloud! These terms and conditions outline the rules and regulations for the use of ARVO Integrated Cloud, located at <a href="https://arvo.services/">https://arvo.services/</a>. By accessing this platform, we assume you accept these terms and conditions. Do not continue to use ARVO Integrated Cloud if you do not agree to take all of the terms and conditions stated on this page.</p>

        <h2>Company Information</h2>
        <hr />
        <p>ARVO Integrated Cloud is a platform owned by OneArvo Ventures Private Limited, an Indian startup registered as a private limited company with the Registrar of Companies, New Delhi, Delhi, India.</p>

        <h2>Definitions</h2>
        <hr />
        <p>
          <ul>
            <li><strong>Company, We, Us, or Our</strong> refers to OneArvo Ventures Private Limited.</li>
            <li><strong>User, You, or Your</strong> refers to the individual or entity accessing our platform.</li>
            <li><strong>Platform</strong> refers to ARVO Integrated Cloud, accessible at <a target='_blank' href="https://arvo.services/">https://arvo.services/</a>.</li>
            <li><strong>Services</strong> refers to the offerings provided by ARVO Integrated Cloud, including but not limited to AI, IoT, and blockchain solutions for supply chain traceability and authenticity.</li>
          </ul>
        </p>

        <h2>Use of the Platform</h2>
        <hr />
        <p>By accessing our platform, you warrant and represent that:</p>
        <ul>
          <li>You are at least 18 years of age.</li>
          <li>You will use the platform in compliance with these terms and all applicable laws and regulations.</li>
          <li>You will not use the platform for any illegal or unauthorized purpose.</li>
        </ul>

        <h2>Intellectual Property Rights</h2>
        <hr />
        <p>Unless otherwise stated, ARVO and/or its licensors own the intellectual property rights for all material on this platform. All intellectual property rights are reserved. You may access this from ARVO Integrated Cloud for your own personal use subjected to restrictions set in these terms and conditions. You must not:</p>
        <ul>
          <li>Republish material from ARVO Integrated Cloud.</li>
          <li>Sell, rent, or sub-license material from ARVO Integrated Cloud.</li>
          <li>Reproduce, duplicate, or copy material from ARVO Integrated Cloud.</li>
          <li>Redistribute content from ARVO Integrated Cloud.</li>
        </ul>

        <h2>Privacy and Data Protection</h2>
        <hr />
        <p>We are committed to protecting your privacy. Our use of your personal information is governed by our Privacy Policy. By using our platform, you consent to the collection and use of your data as described in our Privacy Policy.</p>

        <h2>User Accounts</h2>
        <hr />
        <p>To access certain features of the platform, you may be required to create an account. You agree to provide accurate and complete information when creating your account and to keep this information up to date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

        <h2>Limitation of Liability</h2>
        <hr />
        <p>To the fullest extent permitted by law, ARVO will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
        <ul>
          <li>Your use of or inability to use our platform.</li>
          <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
          <li>Any interruption or cessation of transmission to or from our platform.</li>
          <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our platform by any third party.</li>
          <li>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the platform.</li>
        </ul>

        <h2>Governing Law</h2>
        <hr />
        <p>These terms and conditions are governed by and construed in accordance with the laws of India. Any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of New Delhi, Delhi, India.</p>

        <h2>Changes to Terms and Conditions</h2>
        <hr />
        <p>We may update our terms and conditions from time to time. We will notify you of any changes by posting the new terms and conditions on this page. You are advised to review these terms periodically for any changes. Changes to these terms and conditions are effective when they are posted on this page.</p>

        <h2>Contact Us</h2>
        <hr />
        <p>If you have any questions about these terms and conditions, please contact us at:</p>
        <p>OneArvo Ventures Private Limited<br />M23, CR Park<br />New Delhi, Delhi, India - 110019<br />Email: <a href="mailto:support@arvo.luxury">support@arvo.luxury</a></p>
      </div>
    </div>
  );
}

export default Terms;
