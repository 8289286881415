import ThreedChart from '../3dchart/t3dchart'
import './Graph.css'

function Graph({nfcsession,product}) {
  return (
    <div className="graph-containers">
      <div className="graph-contents">
        <ThreedChart  nfcsession={nfcsession}  product={product}/>
      </div>
    </div>
  )
}

export default Graph
