import React from "react";
import "./Privacy.css";

function Privacy() {
  return (
    <div className="privacy-policy">
      <div className="privacy-header">
        <span>Privacy Policy</span>
      </div>
      <div className="privacy-content">
        <h2>Introduction</h2>
        <hr />
        <p>Welcome to ARVO Integrated Cloud! This privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our platform, located at <a target="_blank" href="https://arvo.services/">https://arvo.services/</a>. By accessing or using our platform, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this privacy policy.</p>

        <h2>Company Information</h2>
        <hr />
        <p>ARVO Integrated Cloud is a platform owned by OneArvo Ventures Private Limited, an Indian startup registered as a private limited company with the Registrar of Companies, New Delhi, Delhi, India.</p>

        <h2>Information We Collect</h2>
        <hr />
        <p>We may collect and process the following types of information about you:</p>
        <ul>
          <li><strong>Personal Information:</strong> Information that can be used to identify you, such as your name, email address, phone number, and any other personal information you provide us directly through our platform.</li>
          <li><strong>Usage Data:</strong> Information about how you use our platform, such as your IP address, browser type, access times, pages viewed, and the referring URL.</li>
          <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to track the activity on our platform and hold certain information.</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <hr />
        <p>We use the information we collect in various ways, including to:</p>
        <ul>
          <li>Provide, operate, and maintain our platform.</li>
          <li>Improve, personalize, and expand our platform.</li>
          <li>Understand and analyze how you use our platform.</li>
          <li>Develop new products, services, features, and functionality.</li>
          <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the platform, and for marketing and promotional purposes.</li>
          <li>Process your transactions and manage your orders.</li>
          <li>Send you emails.</li>
          <li>Find and prevent fraud.</li>
          <li>Comply with legal obligations.</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <hr />
        <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</p>

        <h2>Data Protection</h2>
        <hr />
        <p>We take reasonable steps to protect your information from unauthorized access, use, alteration, or destruction. We implement a variety of security measures to maintain the safety of your personal information.</p>

        <h2>International Data Transfers</h2>
        <hr />
        <p>Your information, including personal data, may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. By using our platform, you consent to this transfer of information.</p>

        <h2>Your Data Protection Rights</h2>
        <hr />
        <p>Depending on your location, you may have the following rights regarding your personal data:</p>
        <ul>
          <li><strong>The right to access:</strong> You have the right to request copies of your personal data.</li>
          <li><strong>The right to rectification:</strong> You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
          <li><strong>The right to erasure:</strong> You have the right to request that we erase your personal data, under certain conditions.</li>
          <li><strong>The right to restrict processing:</strong> You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
          <li><strong>The right to object to processing:</strong> You have the right to object to our processing of your personal data, under certain conditions.</li>
          <li><strong>The right to data portability:</strong> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
        </ul>

        <h2>Changes to This Privacy Policy</h2>
        <hr />
        <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.</p>

        <h2>Contact Us</h2>
        <hr />
        <p>If you have any questions about this privacy policy, please contact us at:</p>
        <p>OneArvo Ventures Private Limited<br />M23, CR Park<br />New Delhi, Delhi, India - 110019<br />Email: <a href="mailto:support@arvo.services">support@arvo.services</a></p>
      </div>
    </div>
  );
}

export default Privacy;
