import React from "react";
import GaugeComponent from "react-gauge-component";
import './Nps.css';
import Emoji from '../../../../assets/Emoji.png';

function Nps() {
  return (
    <div
      style={{
        height: "190px",
        width: "30%",
        border: 'none',
        borderRadius: "11px",
        padding: "10px",
        background:'white',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'

      }}
    >
      <div style={{display:"flex", alignItems:"center"}}>
<img src={Emoji} style={{width:'19px', marginRight:'5px'}} alt="" />
      <span className="nps-header">NPS</span>
      </div>
      <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
        <div style={{width:'70%', height:'100%'}}>
          <GaugeComponent
            arc={{
              subArcs: [
                {
                  limit: 60,
                  color: "#ff3a3a",
                  showTick: true,
                  tooltip: {
                    text: "Low NPS!",
                  },
                },
                {
                  limit: 80,
                  color: "#FFD700",
                  showTick: true,
                  tooltip: {
                    text: "Ok NPS!",
                  },
                },
                {
                  limit: 100,
                  color: "#32CD32",
                  showTick: true,
                  tooltip: {
                    text: "High NPS!",
                  },
                },
              ],
            }}
            value={50}
            labels={{
              valueLabel: {
                fontSize: "34px",
                fill: "#fff",
                textShadow: "none",
                style: { fill: "#000000", textShadow:'none' },
              },
            }}
          />
        </div>
        <div style={{width:'30%', fontSize:'12px'}} >
          <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <div style={{ backgroundColor: '#ff3a3a', padding: '5px', borderRadius: '15px', width:'40px', height:'23px', display:'flex', alignItems:'center', justifyContent:'center'  }}>
          <span>0-6</span>
        </div>
            <div style={{display:'flex', alignItems:'flex-start'}}>
              <span style={{display:'flex', alignItems:'center', justifyContent:'center'}}> : 33%</span>
            </div>
          </div>
          <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'10px'}}>
        <div style={{ backgroundColor: '#FFD700', padding: '5px', borderRadius: '15px', width:'40px', height:'23px', display:'flex', alignItems:'center', justifyContent:'center'  }}>
          <span>8-9</span>
        </div>
            <div style={{display:'flex', alignItems:'flex-start'}}>
              <span style={{display:'flex', alignItems:'center', justifyContent:'center'}}> : 33%</span>
            </div>
          </div>
          <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'10px'}}>
        <div style={{ backgroundColor: '#32CD32', padding: '5px', borderRadius: '15px', width:'40px', height:'23px', display:'flex', alignItems:'center', justifyContent:'center'  }}>
          <span>9-10</span>
        </div>
            <div style={{display:'flex', alignItems:'flex-start'}}>
              <span style={{display:'flex', alignItems:'center', justifyContent:'center'}}> : 33%</span>
            </div>
          </div>
          
       
      </div>
      </div>
    </div>
  );
}

export default Nps;
