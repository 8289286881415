import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./pieChartBox.css";
import location from "../../../assets/pie-chart.png";

am4core.useTheme(am4themes_animated);

const PieChartBox = ({ nfcsessions }) => {
  const chartRef = useRef(null);
  const tooltipss = useRef([]);

  const session = nfcsessions || [];

  var latlong = session.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);

    return {
      latitude,
      longitude,
      size: 4,
      tooltip: item.geodata.city /* specify your tooltip logic here */,
      fill: "#F00" /* specify your fill logic here */,
    };
  });

  var latlong1 = session.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);

    return {
      latitude,
      longitude,
      size: 4,
      tooltip: item.geodata.region /* specify your tooltip logic here */,
      fill: "#F00" /* specify your fill logic here */,
    };
  });
  const countryCodeToName = {
    AF: "Afghanistan",
    AX: "Åland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    CV: "Cabo Verde",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, Democratic Republic of the",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Côte d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    SZ: "Eswatini",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea, Democratic People's Republic of",
    KR: "Korea, Republic of",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Moldova, Republic of",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MK: "North Macedonia",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine, State of",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthélemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan, Province of China",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela, Bolivarian Republic of",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  };
  const type=localStorage.getItem("subtype");
  var latlong2 = session.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);
    const countryCode = item.geodata.country; // Get the country code
    const countryName = countryCodeToName[countryCode]; // Get the corresponding country name

    return {
      latitude,
      longitude,
      size: 4,
      tooltip: countryName || countryCode, // Use the country name if available, otherwise use the country code
      fill: "#F00" /* specify your fill logic here */,
    };
  });

  
  // Example usage

  const [data, setdata] = useState(latlong);
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = tooltipss.current;
    }
  }, [data]);

  // Update tooltip counts when data changes
  useEffect(() => {
    const tooltipCounts = data.length ? 
    data.reduce((counts, current) => {
      const { tooltip } = current;
  
      // If tooltip exists, increment its count or initialize it to 1
      if (tooltip) {
        counts[tooltip] = (counts[tooltip] || 0) + 1;
      }
  
      return counts;
    }, {})
    : 
    latlong.reduce((counts, current) => {
      const { tooltip } = current;
  
      // If tooltip exists, increment its count or initialize it to 1
      if (tooltip) {
        counts[tooltip] = (counts[tooltip] || 0) + 1;
      }
  
      return counts;
    }, {});
  

    // Transform the counts into an array of objects
    tooltipss.current = Object.entries(tooltipCounts).map(([tooltip, count]) => ({
      tooltip,
      counts: count,
    }));
  }, [data,latlong]);

  const [age, setAge] = useState(1);
  const [name,setname]=useState('cities')

  const handleChange = (event) => {
    setAge(Number(event.target.value));
    if (event.target.value == 2) {
      setname('states/region')
      setdata(latlong1);
    } else if (event.target.value == 3) {
      setname('countries')
      setdata(latlong2);
    } else {
      setdata(latlong);
      setname('cities')
    }
    // Convert string to number
  };

  useEffect(() => {
    var chart = am4core.create("piediv", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0;
    chart.innerRadius = am4core.percent(30);
    chart.radius = am4core.percent(50);
    chart.depth = 30;
    chart.angle = 10;
    // chart.legend = new am4charts.Legend();
    chart.data = tooltipss.current;
    //tooltipss.current //JSON.stringify(outputArray)
    var series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "counts";
    series.dataFields.category = "tooltip";
    series.labels.template.fontSize = 10;
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [tooltipss.current]);

  const redirectToPricing = () => {
    window.location.href = "/pricing";
  };

  return (
    <div className="customBox " onClick={() => redirectToPricing()} id="chart">
      <div
        className="titleContainer"
        style={{ position: "relative", top: "4px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img
              src={location}
              alt=""
              style={{ height: "17px", marginRight: "5px" }}
            />
            <p
              style={{
                fontFamily: "Baumans",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "18.91px",
                letterSpacing: "0.02em",
                marginTop: "9px",
                position: "relative",
                top: "5px",
              }}
            >
              Scans by {name}
            </p>
          </div>
          <div className="form-control" style={{}}>
            <label
              htmlFor="map-select"
              style={{
                fontFamily: "Baumans",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "18.91px",
                letterSpacing: "0.02em",
              }}
            >
              Map
            </label>
            <select
              style={{
                borderRadius: "20px",
                height: "30px",
                marginLeft: "4px",
                padding: "5px",
              }}
              id="map-select"
              value={age}
              onChange={handleChange}
              className="select"
            >
              <option value={1}>City</option>
              <option value={2}>State</option>
              <option value={3}>Country</option>
            </select>
          </div>
        </div>
      </div>
      <br />
      <div id="piediv" className={`chartContainer  ${type==='type2'?"":'blur-free-tier-content'}`}></div>
    </div>
  );
};

export default PieChartBox;
