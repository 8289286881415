import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';
import LearningOptions from './Widgets/LearningOptions';
import LinkList from './Widgets/LinkList';
import LinkList2 from './Widgets/LinkList2';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';

const config = {
    initialMessages: [
        createChatBotMessage(
            "Hi, How can I help you? Select from the options below",
            {
                widget: 'learningOptions'
            }
        )
    ],
    state: {
        awaitingUserInput: false,
        issueType: '',
    },
    widgets: [
        {
            widgetName: 'learningOptions',
            widgetFunc: (props) => <LearningOptions {...props} />,
            mapStateToProps: ["someState"] // Provide necessary state props
        },
        {
            widgetName: 'javascriptLinks',
            widgetFunc: (props) => <LinkList2 {...props} />,
            props: {
                options: [
                    {
                        text: 'Issue with Product',
                        id: 1
                    },
                    {
                        text: 'Issue with QR Code',
                        id: 2
                    },
                    {
                        text: 'Others',
                        id: 3
                    }
                ],
            },
            mapStateToProps: ["someState"]
        },
        {
            widgetName: 'lerningLinks',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Demo video ',
                        url: 'https://aiclearningvideos.s3.ap-south-1.amazonaws.com/Demo+with+caption.mp4',
                        id: 1
                    },
                    
                ]
            },
            mapStateToProps: ["someState"] // Provide necessary state props
        }
    ],
    botName: 'ARVO Support',
    customStyles: {
        botMessageBox: {
            backgroundColor: '#0956CC'
        },
        chatButton: {
            backgroundColor: '#0956CC'
        }
    },
    actionProvider: ActionProvider,
    messageParser: MessageParser,
};

export default config;
