import React, { useState, useRef } from "react";
import "./index.css";
import arvologo from "../../assets/arvologo.png";
import arvogrp from "../../assets/arvobrandlogo.png";
import arvotext from "../../assets/arvo-text.png";
import CustomButtons from "./CustomButtons/CustomButtons";

const Sidebar = () => {
  
  const tenantdata = JSON.parse(localStorage.getItem("tenantdata"));
  const tenant = localStorage.getItem("tenant");

  var selectedOrder = tenantdata.find(function (order) {
    return order.employeeid === tenant;
  });

  return (
    <div className="sidebar">
      <div className="admin-sidebar-logo-container">
        <img
          src={
           arvologo
          }
          alt="ARVO Logo"
          className="admin-sidebar-logo"
        />
      </div>
      <div className="admin-sidebar-line"></div>
      <CustomButtons />
      <div className="admin-sidebar-bottom-arvo-logo">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>Powered by</span>
          <div>
            <img src={arvogrp} alt="" className="arvo-text" />
            <img src={arvotext} alt="" className="arvo-logo-brand" />
          </div>
        </div>
        <div style={{ fontSize: "8px", color: "white" }}>
          <span style={{ fontSize: "8px", color: "white" }}>Version 1.0.7</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
