import React, { useState, useRef } from "react";
import "./Map.css"; // Ensure this CSS file is correctly linked for styling
import Maps from "./AmChartsMapAnalytics";
import location from "../../../assets/location.png";
const Map = ({ nfcsessions ,product}) => {
  const [age, setAge] = useState(1);

  const handleChange = (event) => {
    setAge(Number(event.target.value)); // Convert string to number
  };
  //const nfcsessions = JSON.parse(localStorage.getItem('nfcsessions')) || [];

  const transformedData = useRef([]);
  transformedData.current = nfcsessions.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);

    return {
      latitude,
      longitude,
      size: 4,
      tooltip: item.geodata.city /* specify your tooltip logic here */,
      fill: "#F00" /* specify your fill logic here */,
    };
  });

  localStorage.setItem("latlong", JSON.stringify(transformedData.current));
  

 



  return (
    <div className="map-container" id="chart">
      <div className="header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={location}
            alt=""
            style={{ height: "17px", marginRight: "5px" }}
          />
          <p
            style={{
              fontFamily: "Baumans",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "18.91px",
              letterSpacing: "0.02em",
            }}
          >
            Product Sales
          </p>
        </div>
        <div className="form-control">
          <label
            htmlFor="map-select"
            style={{
              fontFamily: "Baumans",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "18.91px",
              letterSpacing: "0.02em",
            }}
          >
            Product
          </label>
          <select
            style={{
              borderRadius: "20px",
              height: "30px",
              marginLeft: "4px",
              padding: "5px",
            }}
            id="map-select"
            value={age}
            onChange={handleChange}
            className="select"
          >
            {product.map((item, index)=>(
 <option value={index+1}>{item.title}</option>
            ))}
         
          </select>
        </div>
      </div>
      
        <Maps data={transformedData.current} age={age} />
      
    </div>
  );
};

export default Map;
