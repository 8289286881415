import React from 'react'
import './Nfcorders.css';
import trendup from '../../../../assets/Trend Up.png';
import lineup from '../../../../assets/Line 2@2x.png';
import order from '../../../../assets/Order.png';

function Nfcorders({filteredData}) {


// Calculate the total length of orderdetials across all objects
const totalOrderDetailsLength = filteredData
? filteredData.reduce((total, obj) => total + obj.orderdetials.length, 0)
: 0;
  

  
  return (
    <div
      style={{
        height: "190px",
        width: "25%",
        border: "none",
        borderRadius: "11px",
        padding: "10px",
        background: "white",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{display:"flex", alignItems:"center"}}>
      <img src={order} style={{width:'19px', marginRight:'5px'}} alt="" />
        <span className="nfc-orders-header">Orders</span>
        
      </div>
      <div style={{ display: "flex", justifyContent: "space-evenly", alignItems:'center', marginTop:'30px' }}>
        <div style={{marginLeft:"10px", display:'flex', flexDirection:"column"}}>
            <span className="nfc-orders-font-main"> {totalOrderDetailsLength}</span>
            <div style={{ display: "flex", alignItems:'center' }}>
            <img style={{width:'16px'}} src={trendup} alt="" />
            <span className="nfc-orders-percent">10%</span>
            </div>
            <span className="nfc-orders-past-timeline">from past hour</span>

        </div>
        <div>
            <img src={lineup} style={{width:"90px"}} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Nfcorders
