import React, { useState, useEffect } from "react";
import "./ChangePassword.css";
import logo from "../../assets/arvologo.png";
import eyeclose from "../../assets/eyeclose.png";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import config from '../../cognito/config';
import axios from 'axios'; // Ensure axios is imported

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [buttonClass, setButtonClass] = useState("change-password-button-inactive");
  const navigate = useNavigate();

  const handleMailUs = () => {
    const subject = encodeURIComponent("Regarding Inquiry");
    const body = encodeURIComponent(
      `Hello,\n\nI would like to inquire about .\n\nPlease provide me with more information.\n\nThank you.`
    );
    window.location.href = `mailto:support@arvo.services?subject=${subject}&body=${body}`;
  };

  const configs = {
    UserPoolId: config.userPoolId,
    ClientId: config.userPoolWebClientId,
  };
  const userPool = new CognitoUserPool(configs);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setErrorText(e.target.value !== newPassword ? "Passwords do not match" : "");
    setButtonClass(e.target.value === newPassword && oldPassword ? "change-password-button-active" : "change-password-button-inactive");
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrorText("Passwords do not match");
      return;
    }

    const user = userPool.getCurrentUser();
    if (!user) {
      toast.error('No user is logged in');
      return;
    }

    user.getSession((err, session) => {
      if (err) {
        toast.error('Error getting session');
        return;
      }
      user.changePassword(oldPassword, newPassword, async (err, result) => {
        if (err) {
          toast.error(err.message || JSON.stringify(err));
        } else {
          const emailData = {
            recipient_email: session.idToken.payload.email, // Get the email from session
           // brand_name: 'YourBrand', // Replace with the actual brand name
            first_name: session.idToken.payload.name, // Get the first name from session
            template_type: 'password_change',
            ticket_id: '12345', // Optional
            issue_description: 'Issue description', // Optional
            date_raised: new Date().toISOString().split('T')[0], // Optional
            date_generated: new Date().toISOString().split('T')[0], // Optional
            description: 'Product description' // Optional
          };

          try {
            const response = await axios.post(
              'https://4y53b9gp2k.execute-api.ap-south-1.amazonaws.com/prod/emails',
              emailData,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );

            console.log('Email sent successfully!', response.data);
          } catch (error) {
            console.error('Error sending email:', error.response ? error.response.data : error.message);
          }

          toast.success('Password changed successfully');
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
      });
    });
  };

  useEffect(() => {
    const user = userPool.getCurrentUser();
    if (!user) {
      toast.error('No user is logged in');
    }
  }, [userPool]);

  return (
    <div className="change-password-container">
      <div className="left-side">
        <div className="logo-container">
          <img src={logo} alt="ARVO Logo" className="logo" />
        </div>
      </div>
      <div className="right-side">
        <div className="change-password-right-side">
          <div className="change-password-top-texts">
            <span className="change-password-welcome">Reset Password</span>
            <span className="change-password-arvocloud">
              ARVO Integrated Cloud
            </span>
          </div>
          <div className="change-password-input-box-container">
            <input
              type="password"
              placeholder="Old Password"
              className="box-change-password-input"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="New Password"
                className="box-change-password-input"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <img
                src={eyeclose}
                alt="Toggle Password Visibility"
                className="icon-eye"
                onClick={togglePasswordVisibility}
              />
            </div>
            <div className="password-input-wrapper">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                className="box-change-password-input"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              <img
                src={eyeclose}
                alt="Toggle Password Visibility"
                className="icon-eye"
                onClick={toggleConfirmPasswordVisibility}
              />
            </div>
            <div style={{ color: "red", fontSize: "10px" }}>{errorText}</div>
          </div>
          <button
            className='change-password-button'
            onClick={handleChangePassword}
            disabled={buttonClass === "change-password-button-inactive"}
          >
            Reset
          </button>
          <div className="text-btn-below" onClick={handleMailUs}>
            <span className="having-trb">Having trouble?</span>
            <span className="contact-us">Contact us</span>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ChangePassword;
