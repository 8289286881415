import React, { useState, useEffect } from 'react';
import './ProductJourney.css';
import axios from 'axios';
import { toast } from 'react-toastify';

function ProductJourney({ onClose, skuid }) {
  const [journeys, setJourneys] = useState([
    { title: '', description: '' },
    { title: '', description: '' },
  ]);
  const [isExistingJourney, setIsExistingJourney] = useState(false);
  const [batchNumber, setBatchNumber] = useState('');
  const [batchAlphabet, setBatchAlphabet] = useState('');  // Define state
  const [batchNum, setBatchNum] = useState('');  // Define state

  // Fetch existing product journey data
  useEffect(() => {
    const fetchProductJourney = async () => {
      try {
        console.log(`Fetching product journey for SKU ID: ${skuid}`);
        const response = await axios.get(`https://1c6see68bj.execute-api.ap-south-1.amazonaws.com/prod/product-journey?skuid=${skuid}`);
        
        const data = response.data;
        
        if (data && data.ProductJourney) {
          setJourneys(data.ProductJourney.map(journey => ({
            title: journey.title,
            description: journey.description
          })));
          if (data.BatchNumber) {
            setBatchNumber(data.BatchNumber);
          }
          setIsExistingJourney(true);
        } else {
          console.log('No product journey found for this SKU ID.');
          setIsExistingJourney(false);
        }
      } catch (error) {
        console.error('Error fetching product journey:', error);
      }
    };

    fetchProductJourney();
  }, [skuid]);

  const addJourney = () => {
    setJourneys([...journeys, { title: '', description: '' }]);
  };

  const removeJourney = (index) => {
    const newJourneys = journeys.filter((_, i) => i !== index);
    setJourneys(newJourneys);
  };

  const handleChange = (index, field, value) => {
    const newJourneys = journeys.map((journey, i) =>
      i === index ? { ...journey, [field]: value } : journey
    );
    setJourneys(newJourneys);
  };

  const handleSubmit = async () => {
    const productJourney = journeys.map((journey, index) => ({
      index: index + 1,
      title: journey.title,
      description: journey.description
    }));

    const payload = {
      skuid,
      createdOn: new Date().toISOString(),
      productJourney,
      lastModifiedOn: new Date().toISOString(),
      batchNumber: batchNumber || batchAlphabet + batchNum
    };

    try {
      console.log('Submitting payload:', payload);
      const apiMethod = isExistingJourney ? 'put' : 'post';
      await axios[apiMethod]('https://1c6see68bj.execute-api.ap-south-1.amazonaws.com/prod/product-journey', payload);
      toast.success(isExistingJourney ? "Your Product Journey has been updated" : "Your Product Journey is captured");
      onClose(); // This will close the modal and refresh the parent component
    } catch (error) {
      console.error('Error submitting product journey:', error);
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('product-journey-modal')) {
      onClose();
    }
  };

  return (
    <div className="product-journey-modal" onClick={handleOutsideClick}>
      <div className="product-journey-modal-content">
        <span className="product-journey-close" onClick={onClose}>&times;</span>
        <h2>Product Journey</h2>
        {batchNumber && (
          <div className="batch-inputs">
            <label>First Batch Number</label>
            <input
              type="text"
              value={batchNumber}
              readOnly
               className='batch-number-read'
            />
          </div>
        )}
        {!batchNumber && (
          <div className="batch-inputs">
            <label>First Batch Number</label>
            <input
              type="text"
              placeholder="Alphabet"
              value={batchAlphabet}
              onChange={(e) => setBatchAlphabet(e.target.value)}
            />
            <input
              type="number"
              placeholder="Number"
              value={batchNum}
              onChange={(e) => setBatchNum(e.target.value)}
            />
          </div>
        )}
        {journeys.map((journey, index) => (
          <div className="journey-input" key={index}>
            <label>{index + 1}. Title</label>
            <input
              type="text"
              placeholder='Enter the Product Journey Title'
              value={journey.title}
              onChange={(e) => handleChange(index, 'title', e.target.value)}
            />
            <label>Description</label>
            <textarea
              placeholder='Enter the Product Journey Description'
              value={journey.description}
              onChange={(e) => handleChange(index, 'description', e.target.value)}
            ></textarea>
            {index === journeys.length - 1 && (
              <div className="journey-controls">
                <span className="journey-add" onClick={addJourney}>+</span>
                {journeys.length > 1 && <span className="journey-remove" onClick={() => removeJourney(index)}>-</span>}
              </div>
            )}
          </div>
        ))}
        <div className="journey-submit-button-container">
          <button className="journey-bottom-right-button" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default ProductJourney;
