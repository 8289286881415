import React, { useState } from "react";
import "./Order.css";
import ReloadIcon from "../../assets/reload.png";
import filter from "../../assets/filter.png";
import DownArrow from "../../assets/dropdownblue.png";
import Status from "./Status/Status";

interface OrderRow {
  orderDate: string; // New field for date
  orderTime: string;
  brandName: string;
  product: string;
  items: string[];
  status: "Pending" | "Delivered" | "Download";
  dueDate: string;
  quantity: number;
  address: string;
}

const orderData: OrderRow[] = [
  {
    orderDate: "March 15 2024",
    orderTime: "21:58:13",
    brandName: "Kandavika",
    product: "A2 Cow Ghee 1L",
    items: ["NFC213", "NFC423"],
    status: "Pending",
    dueDate: "March 30 2024",
    quantity: 20,
    address:
      "23, C K Mukharjee Marg, Block - 56, Block M, Chittaranjan Park, New Delhi, Delhi 110019",
  },
  {
    orderDate: "March 15 2024",
    orderTime: "21:58:13",
    brandName: "Genesis",
    product: "A2 Cow Ghee 1L",
    items: ["NFC213", "NFC423"],
    status: "Delivered",
    dueDate: "March 30 2024",
    quantity: 20,
    address:
      "23, C K Mukharjee Marg, Block - 56, Block M, Chittaranjan Park, New Delhi, Delhi 110019",
  },
  {
    orderDate: "March 15 2024",
    orderTime: "21:58:13",
    brandName: "ApMed",
    product: "A2 Cow Ghee 1L",
    items: ["QR Code"],
    status: "Download",
    dueDate: "NA",
    quantity: 20,
    address:
      "23, C K Mukharjee Marg, Block - 56, Block M, Chittaranjan Park, New Delhi, Delhi 110019",
  },
  {
    orderDate: "March 15 2024",
    orderTime: "21:58:13",
    brandName: "Prima",
    product: "A2 Cow Ghee 1L",
    items: ["NFC213", "NFC423"],
    status: "Pending",
    dueDate: "March 30 2024",
    quantity: 20,
    address:
      "23, C K Mukharjee Marg, Block - 56, Block M, Chittaranjan Park, New Delhi, Delhi 110019",
  },
  {
    orderDate: "March 15 2024",
    orderTime: "21:58:13",
    brandName: "Adidas",
    product: "A2 Cow Ghee 1L",
    items: ["NFC213", "NFC423"],
    status: "Delivered",
    dueDate: "March 30 2024",
    quantity: 20,
    address:
      "23, C K Mukharjee Marg, Block - 56, Block M, Chittaranjan Park, New Delhi, Delhi 110019",
  },
  {
    orderDate: "March 15 2024",
    orderTime: "21:58:13",
    brandName: "Nike",
    product: "A2 Cow Ghee 1L",
    items: ["NFC213", "NFC423"],
    status: "Pending",
    dueDate: "March 30 2024",
    quantity: 20,
    address:
      "23, C K Mukharjee Marg, Block - 56, Block M, Chittaranjan Park, New Delhi, Delhi 110019",
  },
  {
    orderDate: "March 15 2024",
    orderTime: "21:58:13",
    brandName: "absc",
    product: "A2 Cow Ghee 1L",
    items: ["QR Code"],
    status: "Download",
    dueDate: "NA",
    quantity: 20,
    address:
      "23, C K Mukharjee Marg, Block - 56, Block M, Chittaranjan Park, New Delhi, Delhi 110019",
  },
  {
    orderDate: "March 15 2024",
    orderTime: "21:58:13",
    brandName: "ASDF",
    product: "A2 Cow Ghee 1L",
    items: ["QR Code"],
    status: "Download",
    dueDate: "NA",
    quantity: 20,
    address:
      "23, C K Mukharjee Marg, Block - 56, Block M, Chittaranjan Park, New Delhi, Delhi 110019",
  },
  {
    orderDate: "March 15 2024",
    orderTime: "21:58:13",
    brandName: "XYZ",
    product: "A2 Cow Ghee 1L",
    items: ["NFC213", "NFC423"],
    status: "Pending",
    dueDate: "March 30 2024",
    quantity: 20,
    address:
      "23, C K Mukharjee Marg, Block - 56, Block M, Chittaranjan Park, New Delhi, Delhi 110019",
  },
];

function getStatusStyle(status: "Pending" | "Delivered" | "Download") {
  const baseStyle = {
    borderRadius: "8px",
    // width: '80px',
    // height: '27px',
    padding: "4px 15px",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18.75px",
    letterSpacing: "0.02em",
    // textAlign: 'center' as 'center',
  };

  const styles = {
    Pending: {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
    Delivered: {
      backgroundColor: "#DDFFE2",
      color: "#119927",
      ...baseStyle,
    },
    Download: {
      backgroundColor: "#DDFFE2",
      color: "#119927",
      ...baseStyle,
    },
  };

  return <span style={styles[status]}>{status}</span>;
}

function Order() {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const toggleRow = (index: number) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="orders">
      <div className="orders-header">
        <div className="orders-text">
          <p>Orders</p>
        </div>
        <div className="right-side-content">
          <span className="last-updated">Last updated 32 mins ago</span>
          <img src={ReloadIcon} alt="Reload" className="reload-icon" />
          <div className="order-filter">
            <button className="order-filter-button">
              <img src={filter} alt="Filter" />
              Filter
            </button>
          </div>
        </div>
      </div>
      <table className="orders-table">
        <thead>
          <tr>
            <th>Order Timestamp</th>
            <th>Brand Name</th>
            <th>Product</th>
            <th>Items</th>
            <th>Status</th>
            <th>Due Date</th>
            <th>Quantity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orderData.map((order, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <div>{order.orderDate}</div>
                  <div className="order-table-timestamp">{order.orderTime}</div>
                </td>
                <td>{order.brandName}</td>
                <td>{order.product}</td>
                <td>
                  {order.items.map((item, itemIndex) => (
                    <div className="order-table-items" key={itemIndex}>
                      {item}
                    </div>
                  ))}
                </td>
                <td>{getStatusStyle(order.status)}</td>
                <td>{order.dueDate}</td>
                <td>{order.quantity}</td>
                <td onClick={() => toggleRow(index)}>
                  <img
                    src={DownArrow}
                    alt="Expand"
                    className={`order-expand-icon ${
                      expandedRow === index ? "rotated" : ""
                    }`}
                  />
                </td>
              </tr>
              {expandedRow === index && (
                <tr className="spacer expanded">
                  <td colSpan={8} style={{ padding: 0, verticalAlign: "top" }}>
                    <Status
                      productName={order.product}
                      itemNames={order.items}
                      status={order.status}
                      address={order.address}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Order;
