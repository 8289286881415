import React from 'react';
import './TabWithCount.css';

interface TabWithCountProps {
  tabName: string;
  status: string;
  statusColor: string;
  ticketData: any[];
}

const TabWithCount: React.FC<TabWithCountProps> = ({ tabName, status, statusColor, ticketData }) => {
  const filteredTickets = ticketData.filter(ticket => ticket.status === status);
  const count = filteredTickets.length;

  return (
    <div className="tab-with-count">
      <span>{tabName}</span>
      {count > 0 && (
        <div className="count-circle" style={{ backgroundColor: statusColor }}>
          {count}
        </div>
      )}
    </div>
  );
};

export default TabWithCount;
