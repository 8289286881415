import React, {useState,useEffect} from 'react'
import './Scans.css';
import trendup from '../../../../assets/Trend Down.png';
import lineup from '../../../../assets/Line 2@2x.png';
import scan from '../../../../assets/scanpercus.png';


function Scans({nfcsessions}) {  

  const [avg, setavg] = useState(0);
  const current = nfcsessions.map((item) => {
    const ipadress = item.geodata.ip;
    return {
      ipadress,
    };
  });
  useEffect(() => {
    const ipCounts = current.reduce((counts, { ipadress }) => {
      counts[ipadress] = (counts[ipadress] || 0) + 1;
      return counts;
    }, {});

    const uniqueIPAddresses = Object.keys(ipCounts);
    const totalOccurrences = uniqueIPAddresses.reduce(
      (sum, ipAddress) => sum + ipCounts[ipAddress],
      0
    );
    const totalUniqueIPAddresses = uniqueIPAddresses.length;

    setavg(
      totalUniqueIPAddresses > 0 ? totalOccurrences / totalUniqueIPAddresses : 0
    );

    // console.log('Total occurrences:', totalOccurrences);
    // console.log('Total unique IP addresses:', totalUniqueIPAddresses);
    // console.log('Average occurrences per unique IP address:', avg);
    localStorage.setItem("avgUser", avg);
  }, [avg, nfcsessions]);

  return (
    <div
      style={{
        height: "190px",
        width: "35%",
        border: "none",
        borderRadius: "11px",
        padding: "10px",
        background: "white",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{display:"flex", alignItems:"center"}}>
      <img src={scan} style={{width:'19px', marginRight:'5px'}} alt="" />


        <span className="scans-header">Avg Scans per Customer</span>
        
      </div>
        <div style={{ display: "flex", justifyContent: "space-evenly", alignItems:'center', marginTop:'30px' }}>
        <div style={{marginLeft:"30px", display:'flex', flexDirection:"column"}}>
            <span className="nfc-orders-font-main"> {avg.toFixed(4)}</span>
            <div style={{ display: "flex", alignItems:'center' }}>
            <img style={{width:'16px'}} src={trendup} alt="" />
            <span className="nfc-orders-percent">6%</span>
            </div>
            <span className="nfc-orders-past-timeline">from past hour</span>

        </div>
        <div style={{marginRight:'30px'}}>
            <img src={lineup} style={{width:"130px"}} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Scans
