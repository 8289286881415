import React from "react";
import "./Document.css";

function Document() {
  return (
    <div className="technical-document">
      <div className="technical-header">
        <span>Technical Document: Arvo Integrated Cloud (AIC)</span>
      </div>
      <div className="technical-content">
        <h2>Overview</h2>
        <hr />
        <p>ARVO Integrated Cloud (AIC) is a comprehensive platform designed to facilitate the creation, customization, authentication, and traceability of products through advanced technologies such as blockchain, QR code/NFC authentication, and AI labeling. This document outlines the architectural design and key components of AIC, highlighting its frontend, backend, and integrated AWS services.</p>

        <h2>Architecture Overview</h2>
        <hr />
        <p>AIC is structured with a frontend built using React and TypeScript, ensuring a responsive and scalable user interface. The backend leverages various AWS services for robust functionality, including DynamoDB for data storage, Lambda for serverless computing, API Gateway for RESTful API management, SES for email notifications, Cognito for user authentication, CloudWatch for monitoring, and S3 for scalable object storage.</p>
        <p>The application is hosted on AWS using Route 53 for DNS management, CloudFront for content delivery, ensuring low-latency access globally.</p>

        <h2>Key Features</h2>
        <hr />
        <h3>Product Management</h3>
        <ul>
          <li><strong>Create Products:</strong> AIC enables users to efficiently create new products, configuring details such as descriptions, images, and specifications.</li>
          <li><strong>Customize Product Pages:</strong> Users can customize product pages to showcase various attributes and functionalities.</li>
          <li><strong>Link Products with Pages:</strong> Facilitates linking specific products with designated product pages for streamlined navigation and access.</li>
        </ul>

        <h3>Authentication and Security</h3>
        <ul>
          <li><strong>Authentication Methods:</strong> Users authenticate via QR code, NFC, or AI label, ensuring secure and user-friendly access.</li>
          <li><strong>Cognito Integration:</strong> Utilizes AWS Cognito for user management, authentication, and authorization, ensuring data security and privacy.</li>
        </ul>

        <h3>Traceability and Blockchain Integration</h3>
        <ul>
          <li><strong>Product Traceability:</strong> AIC leverages Algorand blockchain for batch-wise product traceability, enabling users to track product journeys transparently.</li>
          <li><strong>Blockchain View:</strong> Users can view detailed product history and traceability information directly through the product app.</li>
        </ul>

        <h3>User Engagement and Analysis</h3>
        <ul>
          <li><strong>Rating and Reviews:</strong> Allows users to provide ratings and reviews for products, facilitating feedback collection and analysis within AIC.</li>
          <li><strong>Scan Information:</strong> Users can view scanned product information such as location and timestamp, enhancing transparency and accountability.</li>
        </ul>

        <h3>Additional Features</h3>
        <ul>
          <li><strong>NFT Integration:</strong> Supports Non-Fungible Tokens (NFTs) for unique product identification and ownership verification.</li>
          <li><strong>Marketing and Promotion:</strong> Includes features for marketing campaigns and social media promotion, enhancing product visibility and engagement.</li>
        </ul>

        <h2>Conclusion</h2>
        <hr />
        <p>Arvo Integrated Cloud (AIC) represents a cutting-edge solution for product management, authentication, traceability, and user engagement. By leveraging React, TypeScript, and AWS services like DynamoDB, Lambda, API Gateway, and blockchain technology, AIC provides a robust platform that meets modern enterprise needs for secure, scalable, and traceable product management.</p>
        <p>For further details regarding specific components or implementation strategies, refer to corresponding technical specifications and documentation provided by the Arvo Integrated Cloud (AIC) development team.</p>

        <h2>Contact Information</h2>
        <hr />
        <p>For inquiries or technical support, please contact:</p>
        <p>Email: <a href="mailto:support@arvo.services">support@arvo.services</a><br />Website: <a href="https://onearvoventures.com/">onearvoventures.com</a></p>
      </div>
    </div>
  );
}

export default Document;
