import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./t3chart.css";
import charts from '../../../assets/bar-chart.png'
import ThreedChart2 from './t3chart2';
am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_animated);

const ThreedChart = ({nfcsession, product}) => {
  const [age, setAge] = useState(1);
  const handleChange = (event) => {
    setAge(Number(event.target.value)); // Convert string to number
  };

  const storedData = JSON.parse(localStorage.getItem("nfcsessions")) || [];
  const [currentMonth, setCurrentMonth] = useState("");
  const blacklistarray = useRef([]);
  const lastSixMonthsOccurrenceforscan = useRef([]);
  
  const monthsOccurrence = new Array(12).fill(0);

// Get today's month
const todays = new Date();
const currentMonthforscan = todays.getMonth(); // Get zero-based index

// Iterate through the dataArray
for (const data of nfcsession) {
  const timeline = new Date(parseInt(data.timeline));

  // Get the month (zero-based index)
  const monthIndex = timeline.getMonth();

  // Increment the occurrence count for the corresponding month
  monthsOccurrence[monthIndex]++;
}

// Initialize an array to store the last six month's occurrences

// Loop through the last six months
for (let i = 0; i < 6; i++) {
  let monthIndex = currentMonthforscan - i;
  if (monthIndex < 0) {
    monthIndex += 12; // Wrap around to the end of the array
  }
  lastSixMonthsOccurrenceforscan.current.unshift(monthsOccurrence[monthIndex]); // Add occurrence count to the beginning of the array
}



  useEffect(() => {
    const currentDate = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = monthNames[currentDate.getMonth()];
    setCurrentMonth(month);
  }, []);

  const generateLastSixMonths = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const lastSixMonths = [];

    const blacklistscans=JSON.parse(localStorage.getItem('blacklistscan'));
   
    // Assuming data is the object containing the IDs and arrays of objects
  
  const monthsOccurrence = {};
  var resultArraymonth = [];
  // Iterate over each ID
  for (const id in blacklistscans) {
      // Get the array of objects for the current ID
      const objectsArray = blacklistscans[id];
  
      // Get the last object in the array
      const lastObject = objectsArray[objectsArray.length - 1];
  
      // Extract the timeline value
      const timeline = lastObject.timeline;
  
      // Convert the timeline value to a JavaScript Date object
      const timestamp = parseInt(timeline);
      const date = new Date(timestamp);
  
      // Extract the month from the date (months are zero-indexed in JavaScript)
      const month = date.getMonth() + 1; // Adding 1 to convert to 1-indexed month
  
      // Count the occurrence of the month
      if (monthsOccurrence[month]) {
          monthsOccurrence[month]++;
      } else {
          monthsOccurrence[month] = 1;
      }
  }
  
  // Convert the monthsOccurrence object to an array of occurrences
  const monthsOccurrenceArray = [];
  for (let month = 1; month <= 12; month++) {
      // If the month exists in monthsOccurrence, push its occurrence count, otherwise push 0
      monthsOccurrenceArray.push(monthsOccurrence[month] || 0);
  }
  

   
const currentMonth = currentDate.getMonth(); // Get current month (0-indexed)


// Loop through the last 12 months (including the current month)
for (let i = 0; i < 12; i++) {
    const index = (currentMonth - i + 12) % 12; // Calculate the index in the result array

    // If the index is within the last 6 months, keep the original value
    // Otherwise, set it to 0
    if (i < 6) {
      blacklistarray.current.unshift(monthsOccurrenceArray[index] || 0);
      // Add the original value if it exists, otherwise add 0
    } else {
      blacklistarray.current.unshift(0);
     // Add 0 for months before the last 6 months
    }
}


    for (let i = 5; i >= 0; i--) {
      const monthIndex = (currentMonthIndex - i + 12) % 12;
      lastSixMonths.push(monthNames[monthIndex]);
    }

    return lastSixMonths;
  };
  const today = new Date();

  // Initialize an array to store the last six values
  const lastSixMonthsOccurrence = [];
  

const currentMon = today.getMonth() + 1; // Adjust for zero-based index

// Initialize an array to store the last six values

// Loop through the last 6 months
for (let i = 0; i < 6; i++) {
    let monthIndex = currentMon - i;
    if (monthIndex <= 0) {
        monthIndex += 12; // Wrap around to the end of the array
    }
    lastSixMonthsOccurrence.unshift(blacklistarray.current[monthIndex - 1] || 0); // Add occurrence count to the beginning of the array
}


  
  const array1 = lastSixMonthsOccurrenceforscan.current;
  const array2 = lastSixMonthsOccurrence||[0,0,0,0,0,0];

  const months = generateLastSixMonths();

  const resultArray = months.map((month, index) => ({
    month,
    scans: array1[index],
    blacklist: array2[index],
  }));

  useEffect(() => {
    var chart3d = am4core.create("chartdiv3d", am4charts.XYChart3D);

    chart3d.data = resultArray;

    // Create axes
    var categoryAxis = chart3d.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "month";
    categoryAxis.title.text = "Months";

    var valueAxis = chart3d.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Scans";

    // Create series
    var series = chart3d.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "scans";
    series.dataFields.categoryX = "month";
    series.name = "Scans";
    series.tooltipText = "{name}: [bold]{valueY}[/]";

    var series2 = chart3d.series.push(new am4charts.ColumnSeries3D());
    series2.dataFields.valueY = "blacklist";
    series2.dataFields.categoryX = "month";
    series2.name = "Blacklist";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";

    chart3d.cursor = new am4charts.XYCursor();

    return () => {
      if (chart3d) {
        chart3d.dispose();
      }
    };
  }, [lastSixMonthsOccurrence,lastSixMonthsOccurrenceforscan.current]);

  const redirectToPricing = () => {
    window.location.href = "/pricing";
  };
  
  
  return (
    <div className="customChartBox " onClick={() => redirectToPricing()}>
       <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent:'center'
          }}
        >
      <div className="chartTitle" style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
            <img src={charts} alt="" style={{height: '17px', marginRight:'5px'}}/>
        <p
          style={{
            fontFamily: "Baumans",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "18.91px",
            letterSpacing: "0.02em",
            
            }}
        >
          Scans by   {age==1? 'Months': 'Category'} 
        </p>
          </div>
          <div className="form-control">
          <label
            htmlFor="map-select"
            style={{
              fontFamily: "Baumans",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "18.91px",
              letterSpacing: "0.02em",
            }}
          >
            Map
          </label>
              <select
                style={{
                  borderRadius: "20px",
                  height: "30px",
                  marginLeft: "4px",
                  // padding: "5px",
                }}
                id="map-select"
                value={age}
                onChange={handleChange}
                className="select"
              >
                 <optgroup label="Select Months">
                  <option value={1}>Months</option>
                </optgroup>
                <optgroup label="Select by  Category">
                  <option value={2}>All</option>
                </optgroup>
              </select>
          </div>
        </div>
      <br />
      {age==1? <div id="chartdiv3d" className="chart3dContainer"></div>:
      <ThreedChart2 nfcsessions={nfcsession}
      product={product} /> }
     
    </div>
  );
};

export default ThreedChart;
