import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./Inventory.css";
import {
  decrement,
  increment,
  fetchNfcSessions,
  fetchProducts,
  fetchTenantData,
} from "../redux/counterSlice";
import { useSelector, useDispatch } from "react-redux";

function Inventory() {
  const { id } = useParams();
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [tenantData, setTenantdata] = useState();
  const tenantDataRef = useRef([]);
  const nfcsessions = useSelector((state) => state.counter.nfcsessions);
  const product = useSelector((state) => state.counter.products);

  const tenants = localStorage.getItem("tenant").toLowerCase();

  const calculateTotalQty = (data) => {
    let totalQty = 0;
    data.forEach((entry) => {
      if (entry.requestBody.rows && entry.requestBody.rows.length > 0) {
        // Sum up the qty values from all rows
        totalQty += entry.requestBody.rows.reduce(
          (acc, row) => acc + row.qty,
          0
        );
      }
    });
    return totalQty;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Step 1: Fetch tenant data
        const tenantResponse = await axios.get(
          `https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/employee?employeeid=${tenants}`
        );
        const tenantdata = tenantResponse.data;
        setTenantdata(tenantdata);

        // Step 2: Extract necessary information from tenant data
        const tenantId = tenantdata.id; // Adjust this according to your data structure

        // Step 3: Fetch property detail data using extracted information
        const propertyResponse = await axios.get(
          `https://xc5ph8i3p0.execute-api.ap-south-1.amazonaws.com/prod/upload/?id=${id}`
        );
        const propertyData = propertyResponse.data.Item;
        setPropertyDetail(propertyData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!propertyDetail) {
    return <div>Loading...</div>;
  }

  const uniqueAttachedUid = [...new Set(propertyDetail.attachedUid)]; // Remove duplicate attached UIDs

  // Filter tags into active and inactive lists
  const activeTags = uniqueAttachedUid.filter((uid) =>
    nfcsessions.some((session) => session.Tagid === uid)
  );
  const inactiveTags = uniqueAttachedUid.filter(
    (uid) => !nfcsessions.some((session) => session.Tagid === uid)
  );

  return (
    <div>
      <div className="productdetails-headers" style={{ marginTop: "140px", marginLeft: "20px" }}>
        <div className="productdetails-text">
          <p>Product Inventory</p>
        </div>
      </div>
      <h1 className="product-details-product-name" style={{ marginLeft: "20px" }}>
        {propertyDetail.title}
      </h1>
      <div className="description-container" style={{ marginLeft: "20px" }}>
        <div className="product-details-container-info">
          <div className="product-details-product-info">
            <p>{propertyDetail.description}</p>
          </div>
          <div
            className="description"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "16px",
            }}
          >
            <h5 className="product-details">Product SKU Id:</h5>
            <div className="product-details">
              {propertyDetail.productId}
            </div>
          </div>
          <div
            className="description"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "16px",
            }}
          >
            <h5 className="product-details">Total Number of Inactive Tags:</h5>
            <div className="product-details">
              {inactiveTags.length}
              {/* {calculateTotalQty(
                tenantData.orderdetials.filter(
                  (entry) => entry.productName === propertyDetail.title
                )
              )} */}
            </div>
          </div>
          <div
            className="description"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "16px",
            }}
          >
            <h5 className="product-details">Total Number of Active Tags:</h5>
            <div className="product-details">
              {activeTags.length}
            </div>
          </div>
        </div>
        <div className="photo-container" style={{ margin: "10px" }}>
          <img src={propertyDetail.photo[0]} alt={`Photo 1`} />
        </div>
      </div>
      <div className="table-and-map" style={{ marginLeft: "20px" }}>
        <div className="attached-uid">
          <h3 className="product-details-recent-scans">List of Active Tags:</h3>
          <div className="table-container">
            <table className="product-details-table">
              <thead>
                <tr>
                  <th>Tag ID</th>
                  <th>Tag Type</th>
                </tr>
              </thead>
              <tbody>
                {activeTags.map((item, index) => (
                  <tr key={index}>
                    <td>{`${index + 1} ) ${item}`}</td>
                    <td>{item.length === 14 ? "NFC" : "QR Code"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="vertical-line" />
        <div className="attached-uid">
          <h3 className="product-details-recent-scans">List of Inactive Tags:</h3>
          <div className="table-container">
            <table className="product-details-table">
              <thead>
                <tr>
                  <th>Tag ID</th>
                  <th>Tag Type</th>
                </tr>
              </thead>
              <tbody>
                {inactiveTags.map((item, index) => (
                  <tr key={index}>
                    <td>{`${index + 1} ) ${item}`}</td>
                    <td>{item.length === 14 ? "NFC" : "QR Code"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
