import React, { useEffect, useState } from "react";
import "./index.css";
import NPS from "./cardone/nps/Nps";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Sessions from "./cardone/sessions/Sessions";
import Transactions from "./cardone/transactions/Transactions";
import Revenue from "./cardtwo/revenuesales/Revenue";
import Salesoverview from "./cardtwo/SalesOverview/Salesoverview";
import ProductDetailsAdmin from './cardtwo/ProductDetail/ProductDetailsAdmin'
import Nfcorders from "./cardthree/nfcorders/Nfcorders";
import Scans from "./cardthree/ScansCustomer/Scans";
import Traceability from "./cardthree/traceability/Traceability";
import ReloadIcon from "../../assets/reload.png";
import Outreach from "./cardfour/outreach/Outreach";
import Logistic from "./cardfour/logistic/Logistic";
import Region from './region/Region';
import {
  fetchNfcSessions,
  fetchProducts,
} from "../../redux/counterSlice";
import { useSelector, useDispatch } from "react-redux";
import Dcode from "./dcode";

function Index() {
  const selectedBrand = useSelector((state) => state.counter.selectedbrand);
  const nfcsessions = useSelector((state) => state.counter.nfcsessions);
  const product = useSelector((state) => state.counter.products);
  const dispatch = useDispatch();

  // Initialize brandda as an empty array if localStorage.getItem('brand') returns null
  const brandda = JSON.parse(localStorage.getItem('brand')) || [];

  // Filter data based on selectedBrand
  const filteredData = brandda.filter(obj => obj.cognitoBrandName === selectedBrand);

  // State to store the selected employee ID
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [fetchedNfcSessions, setFetchedNfcSessions] = useState([]); // State for fetched NFC sessions

  // Fetch nfcsessions and products when selectedEmployeeId changes
  useEffect(() => {
    if (selectedEmployeeId) {
      dispatch(fetchNfcSessions(selectedEmployeeId));
      dispatch(fetchProducts(selectedEmployeeId));
    }
  }, [dispatch, selectedEmployeeId]);

  // Fetch nfcsessions and products when filteredData changes
  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      dispatch(fetchNfcSessions(filteredData[0].employeeid));
      dispatch(fetchProducts(filteredData[0].employeeid));
    }
  }, [dispatch, selectedBrand]);

  // Fetch NFC sessions from API if selectedBrand is "All"
  useEffect(() => {
    const fetchAllNfcSessions = async () => {
      try {
        const response = await fetch('https://nwtgrfetjc.execute-api.ap-south-1.amazonaws.com/prod/allnfcsession');
        const data = await response.json();
        setFetchedNfcSessions(data.Items || []); // Assuming the response has an Items array
      } catch (error) {
        console.error("Error fetching NFC sessions:", error);
      }
    };

    if (selectedBrand === "All") {
      fetchAllNfcSessions();
    }
  }, [selectedBrand]);

  // Create an array of employee IDs
  const employeeIds = filteredData.map(data => data.employeeid);
  const nfcDataToPass = selectedBrand === "All" ? fetchedNfcSessions : nfcsessions;

  return (
    <div className="superadmin-dashboard" style={{ paddingBottom: "20px", paddingTop: '20px' }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <span className="superadmin-dashboard-header">Dashboard</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <span className="super-admin-last-updated">Last updated on 2 min ago </span>
          <img
            style={{ marginRight: "4px", marginLeft: "4px", cursor: "pointer" }}
            src={ReloadIcon}
            alt=""
          />
          <span style={{ marginRight: '4px' }}>Tenant :</span>
          <select
            className="super-admin-dashboard-select"
            style={{
              background: 'linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)',
              boxShadow: '4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset',
              border: 'none',
              color: 'white',
              fontSize: '14px',
              fontWeight: '400',
              borderRadius: '24px',
              fontFamily: 'Roboto',
              padding: '8px 16px',
              cursor: 'pointer',
            }}
            value={selectedEmployeeId}
            onChange={(event) => setSelectedEmployeeId(event.target.value)}
          >
            {employeeIds.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Tabs style={{ marginTop: "20px" }}>
      <TabList style={{ marginBottom: "30px" ,width:'30%' }}>
        <Tab>AIC</Tab>
        <Tab>Dcode</Tab>
      </TabList>
      <TabPanel>
       
  
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}
      >
        <NPS />
        <Sessions />
        <Transactions 
          nfcsessions={nfcDataToPass} 
          product={product} 
          selectedBrand={selectedBrand}
          selectedEmployeeId={selectedEmployeeId} 
          filteredData={filteredData} 
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}
      >
        <Revenue nfcsessions={nfcDataToPass} />
        {/* <Salesoverview filteredData={filteredData} /> */}
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}
      >
        <Outreach nfcsessions={nfcDataToPass} product={product} />
        <Logistic filteredData={filteredData} />
      </div>
      <Region nfcsessions={nfcDataToPass} />
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}
      >
        <Nfcorders filteredData={filteredData} />
        <Scans nfcsessions={nfcDataToPass} />
        <Traceability />
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}
      >
        {/* <Revenue nfcsessions={nfcDataToPass} /> */}
         <Salesoverview filteredData={filteredData} /> 
         <ProductDetailsAdmin filteredData={filteredData} /> 
      </div>
      </TabPanel>
      <TabPanel>
      <Dcode />
      </TabPanel>
      </Tabs>
    </div>
  );
}

export default Index;
