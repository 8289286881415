import React from "react";
import "./CustomTable.css";
import usage from '../../../assets/folder.png';
import download from '../../../assets/Download.png';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
// interface ScansProps {
//   product: any[];
//   nfcsessions: any[];
//   tenantdata:any[];
// }
// Function to calculate total quantity


const CustomTable = ({ product, nfcsessions,selectedOptions ,tenantdata}) => {
  const navigation= useNavigate();
   

   const [tenantData, setTenantdata] = React.useState(tenantdata);
   const tenantDataRef = React.useRef([]);
 
   const tenants = localStorage.getItem("tenant").toLowerCase();
 
  
   const filtereddata = selectedOptions !== "All" 
   ? product.filter((entry) => entry.title === selectedOptions)
   : product;
 
   const mappingdata=filtereddata.length==0?product:filtereddata;
   // Define the headings you want in the CSV
   const headings = ['title', 'productId', 'price',  'discountmrp', 'tenant', 'description', 'category'];
  
   
    
   const mapDataToHeadings = (object) => {
     return headings.map(heading => {
       // If the object has a property matching the heading, return its value
       // Otherwise, return an empty string
       return object.hasOwnProperty(heading) ? object[heading] : '';
     });
   };


 const convertToCsvRow = (object) => {
   return Object.values(object).map(value => {
     // If the value contains a comma, enclose it in double quotes
     if (typeof value === 'string' && value.includes(',')) {
       return `"${value}"`;
     }
     return value;
   }).join(',');
 };


 const csvContent = [
   headings.join(','), // Join headings with comma
   ...product.map(object => {
     const mappedData = mapDataToHeadings(object);
     return convertToCsvRow(mappedData);
   }) // Convert each data object to a CSV row
 ].join('\n'); 

  // Function to download CSV file
  const downloadCsv = () => {
   const blob = new Blob([csvContent], { type: 'text/csv' });
   const url = URL.createObjectURL(blob);
   const a = document.createElement('a');
   a.href = url;
   a.download = 'export.csv';
   document.body.appendChild(a);
   a.click();
   document.body.removeChild(a);
 };


  return (
    <div className="table-container">
    <div className="header-container">
      <div className="metrics-container">
          <img src={usage} alt="" style={{height: '20px', marginRight: '5px'}}/>
          <p>Product List</p>
        </div>
        <div style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
          <button className="download-btn" onClick={downloadCsv}>Download 
            <img src={download} style={{height:'15px'}} alt="print" />
          </button>
        </div>
      </div>
      <table className="data-table">
        <thead>
          <tr className="table-header">
            <th>Product</th>
            <th>Product Name</th>
            <th>Sub-Category</th>
            <th>Sku-Id </th>
            <th>Number of Tags </th>
            <th>Number of Scans</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {mappingdata.map((row, index) => (
            <tr key={index}>
              <td>
                <img  className='table-item-photo' src={row.photo[0]}  />
              </td>
              <td>{row.title}</td>
              <td>{row.category}</td>
              <td onClick={()=>navigation(`/product/${row.title}/${row.productId}`)}>{row.productId} <img width="10" height="10" src="https://img.icons8.com/external-anggara-basic-outline-anggara-putra/24/external-share-ui-anggara-basic-outline-anggara-putra.png" alt="external-share-ui-anggara-basic-outline-anggara-putra"/></td>
              <td onClick={()=>navigation(`/inventory/${row.productId}`)}  >  
              {new Set(row.attachedUid).size} 
              <img width="10" height="10" src="https://img.icons8.com/external-anggara-basic-outline-anggara-putra/24/external-share-ui-anggara-basic-outline-anggara-putra.png" alt="external-share-ui-anggara-basic-outline-anggara-putra"/>
                </td>
              <td >
                {
                  nfcsessions.filter((entry) => entry.title === row.title).length
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
