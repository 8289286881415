import React, { useState, useEffect } from "react";
import "./CustomTable.css";
import usageIcon from "../../../assets/folder.png";
import downloadIcon from "../../../assets/Download.png";
import axios from "axios";

const CustomTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    // Fetch data from the API
    axios
      .get("https://712yp9baw5.execute-api.ap-south-1.amazonaws.com/prod/verify")
      .then((response) => {
        const sortedData = response.data.sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn));
        setData(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const formatDate = (dateString) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options).replace(/[/]/g, "-").replace(",", "");
  };

  const downloadCsv = () => {
    const headings = ["Phone Number", "Coupon Code", "Discount Percentage", "Location", "Created On"];
  
    const csvContent = [
      headings.join(","),
      ...data.map((object) => {
        const row = [
          `"${object.PhoneNumber}"`,
          `"${object.CouponCode}"`,
          `"${object.DiscountPercentage}"`,
          `"${object.City || '-'}, ${object.Region || '-'}"`,
          `"${formatDate(object.CreatedOn)}"`
        ];
        return row.join(",");
      }),
    ].join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `export-${formatDate(Date.now())}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const selectedData = data.slice(startIndex, startIndex + rowsPerPage);

  return (
    <div className="table-container">
      <div className="header-container">
        <div className="metrics-container">
          <img src={usageIcon} alt="Usage" style={{ height: "20px", marginRight: "5px" }} />
          <p>Data List</p>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <button className="download-btn" onClick={downloadCsv}>
              Download
              <img src={downloadIcon} alt="Download" />
            </button>
          </div>
        </div>
      </div>

      <table className="data-table">
        <thead>
          <tr className="table-header">
            <th>Phone Number</th>
            <th>Coupon Code</th>
            <th>Discount Percentage</th>
            <th>Location</th>
            <th>Created On</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {selectedData.map((row, index) => (
            <tr key={index}>
              <td>{row.PhoneNumber}</td>
              <td>{row.CouponCode}</td>
              <td>{row.DiscountPercentage}</td>
              <td>
                {row.City}, {row.Region}
              </td>
              <td>{formatDate(row.CreatedOn)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination-container">
        <label>
          Rows per page:
          <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </label>
        <div>
          {Array.from({ length: Math.ceil(data.length / rowsPerPage) }, (_, index) => (
            <button
              key={index}
              className={`page-btn ${index + 1 === currentPage ? "active" : ""}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
