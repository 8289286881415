import React from "react";
import "./CounterfeitTimeline.css";

interface TimelineProps {
  report: "Seal Tampering" | "Duplicate Tracked";
  itemNames: string[];
  firstDot: string;
  secondDot: string;
}

const Timeline: React.FC<TimelineProps> = ({
  report,
  itemNames,
  firstDot,
  secondDot,
}) => {
  const isNFC =
    itemNames.includes("NFC213") ||
    itemNames.includes("NFC423") ||
    itemNames.includes("QR Code");

  const getStatusClasses = () => {
    let classes = {
      unsealed: "circle",
      sealed: "circle",
      lineToDuplicate: "line",
    };

    if (isNFC) {
      if (report === "Seal Tampering") {
        classes.unsealed =
          firstDot === "Unsealed" ? "circle orange" : "circle red";
        classes.sealed =
          secondDot === "Sealed" ? "circle red" : "circle orange";
        classes.lineToDuplicate = "line orange";
      } else if (report === "Duplicate Tracked") {
        classes.unsealed = "circle orange";
        classes.sealed = "circle orange";
        classes.lineToDuplicate = "line orange";
      }
    }

    return classes;
  };

  const classes = getStatusClasses();

  return (
    <div className="report-timeline-container">
      {/* Unsealed circle */}
      <div className={classes.unsealed}>
        <div className="report-circle"></div>
        <div className="report-timeline-label">{firstDot}</div>
        {/* Location and Time container */}
        <div className="location-time-container">
          <div className="report-location-time">Location: New York</div>
          <div className="report-location-time">Time: 23:34:12 </div>
          <div className="report-location-time">Date: 12/12/2024 </div>
        </div>
      </div>
      {/* Line to next circle */}
      <div className={classes.lineToDuplicate}></div>
      {/* Sealed circle */}
      <div className={classes.sealed}>
        <div className="report-circle"></div>
        <div className="report-timeline-label">{secondDot}</div>
        {/* Location and Time container */}
        <div className="location-time-container">
          <div className="report-location-time">Location: New York </div>
          <div className="report-location-time">Time: 23:34:12 </div>
          <div className="report-location-time">Date: 12/12/2024 </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
