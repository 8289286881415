import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./t3chart.css";
import charts from '../../../assets/charts.png'
am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_animated);

const   ThreedChart = ({nfcsessions, product}) => {
  const storedData = JSON.parse(localStorage.getItem("nfcsessions")) || [];


  
  const chartRef = useRef(null);

  useEffect(() => {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end 

    // Create chart instance
    let chart = am4core.create(chartRef.current, am4charts.XYChart);

     chart.data = product.map(product => ({
      "name": product.title,
      "points": nfcsessions.filter((entry) => entry.title === product.title).length,
      "color": chart.colors.next(),
      "bullet": product.photo[0] // Assuming the first photo URL is used for the bullet
    }));
    // Add data
    // chart.data = [{
    //     "name": "John",
    //     "points": 35654,
    //     "color": chart.colors.next(),
    //     "bullet": "https://www.amcharts.com/lib/images/faces/A04.png"
    // }, {
    //     "name": "Damon",
    //     "points": 65456,
    //     "color": chart.colors.next(),
    //     "bullet": "https://www.amcharts.com/lib/images/faces/C02.png"
    // }, {
    //     "name": "Patrick",
    //     "points": 45724,
    //     "color": chart.colors.next(),
    //     "bullet": "https://www.amcharts.com/lib/images/faces/D02.png"
    // }, {
    //     "name": "Mark",
    //     "points": 13654,
    //     "color": chart.colors.next(),
    //     "bullet": "https://www.amcharts.com/lib/images/faces/E01.png"
    // }];

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.inside = true;
    categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
    categoryAxis.renderer.labels.template.fontSize = 8;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeDasharray = "4,4";
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    // Do not crop bullets
    chart.maskBullets = false;

    // Remove padding
    chart.paddingBottom = 0;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "points";
    series.dataFields.categoryX = "name";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.column.cornerRadiusTopLeft = 15;
    series.columns.template.column.cornerRadiusTopRight = 15;
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/b] Scans";

    // Add bullets
    let bullet = series.bullets.push(new am4charts.Bullet());
    let image = bullet.createChild(am4core.Image);
    image.horizontalCenter = "middle";
    image.verticalCenter = "bottom";
    image.dy = 20;
    image.y = am4core.percent(100);
    image.propertyFields.href = "bullet";
    image.tooltipText = series.columns.template.tooltipText;
    image.propertyFields.fill = "color";
    image.filters.push(new am4core.DropShadowFilter());

    return () => {
      chart.dispose();
    };
  }, [nfcsessions, product ]);

  return (
    <div className="customChartBox">
      <div className="chartTitle" style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
            <img src={charts} alt="" style={{height: '17px', marginRight:'5px'}}/>
        <p
          style={{
            fontFamily: "Baumans",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "18.91px",
            letterSpacing: "0.02em",
          }}
        >
          Scans by
        </p>
          </div>
      <br />
      <div ref={chartRef} className="chart3dContainer"></div>
    </div>
  );
};

export default ThreedChart;
