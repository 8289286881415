import React, { useState, useEffect } from 'react';
import './Outreach.css';
import World from '../../../../assets/World.png';
import OutreachMap from './OutreachMap';

function Outreach({  nfcsessions}) {

  //console.log(nfcsessions)
  return (
    <div
      style={{
        height: '500px',
        width: '40%',
        border: 'none',
        borderRadius: '11px',
        padding: '10px',
        background: 'white',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className="outreach-header">
          <img src={World} style={{ width: '19px', marginRight: '5px' }} alt="" />
           Product sales
        </div>
       
      </div>
      <OutreachMap  nfcsessions={nfcsessions}/>
    </div>
  );
}

export default Outreach;
