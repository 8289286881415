import React from 'react';
import './index.css';
import BrandName from './brandname/BrandName';
import Info from './info/Info';

function Navbar() {
  return (
    <div className="navbar">
      <div className="navbar-brand">
        <BrandName />
      </div>
      <div className="navbar-info">
        <Info />
      </div>
    </div>
  );
}

export default Navbar;
