import React, { useEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldHigh from "@amcharts/amcharts4-geodata/worldHigh";

const Am4chartMap = ({ data }) => {
  const mapRef = useRef(null);

  const getUniqueTooltipsAndCounts =(data) => {
    const tooltips= [];
    const counts= [];
  
    data.forEach((item) => {
      const tooltipIndex = tooltips.indexOf(item.tooltip);
  
      if (tooltipIndex === -1) {
        tooltips.push(item.tooltip);
        counts.push(1);
      } else {
        counts[tooltipIndex]++;
      }
    });
  
    return { tooltips, counts: counts.map(Number) };
  };
  
  // Example usage
  const storedData = localStorage.getItem('latlong')
  const parsedData = storedData ? JSON.parse(storedData) : [];
  
  const { tooltips, counts } = getUniqueTooltipsAndCounts(parsedData);

  useEffect(() => {
    const storedLatLong = localStorage.getItem('latlong');
  
    let map = am4core.create("map", am4maps.MapChart);
    map.geodata = am4geodata_worldHigh;
    map.percentHeight = 90;
    map.dy = 10;
    map.projection = new am4maps.projections.NaturalEarth1();
    let polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;
    map.homeZoomLevel = 1.2;
    map.zoomControl = new am4maps.ZoomControl();
    map.zoomControl.layout = 'horizontal';
    map.zoomControl.align = 'left';
    map.zoomControl.valign = 'bottom';
    map.zoomControl.dy = -10;
    map.zoomControl.contentHeight = 20;
    map.zoomControl.minusButton.background.fill = am4core.color("#C7D0FF");
    // ... (rest of the zoom control configurations)
    map.zoomControl.plusButton.dx = 5;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = am4core.color("#E0E0E0");
    polygonTemplate.stroke = am4core.color("#FFFFFF")
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#354D84");

    let citySeries = map.series.push(new am4maps.MapImageSeries());
    citySeries.data = data
    citySeries.dataFields.value = "size";

    let city = citySeries.mapImages.template;
    city.nonScaling = true;
    city.propertyFields.latitude = "latitude";
    city.propertyFields.longitude = "longitude";

    let circle = city.createChild(am4core.Circle);
    circle.fill = am4core.color("#000000");
    circle.strokeWidth = 0;

    let circleHoverState = circle.states.create("hover");
    circleHoverState.properties.strokeWidth = 1;
    circle.tooltipText = '{tooltip}';
    circle.propertyFields.radius = 'size';

    mapRef.current = map;

    return () => {
      if (mapRef.current) {
        mapRef.current.dispose();
      }
    };
  }, [data]);

  return (

      <div style={{ position: 'relative', height: '500px', width: '100%' }}>
        <div>
      
        </div>
        <div id="map" style={{ height: '85%', position: 'relative' }}>
          <span>Alternative content for the map</span>
        </div>
      </div>
 
  );
};

export default Am4chartMap;
