import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./Marketing.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {
  CurveChart,
  CurveColumnSeries,
  CurveCursor,
  bullets,
  CurveLineSeries,
  SerpentineChart
} from "@amcharts/amcharts4/plugins/timeline";

import { PinBullet } from "@amcharts/amcharts4/plugins/bullets";
am4core.useTheme(am4themes_animated);



function ProductMarketing({id}) {
  // const { id } = useParams();
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [tenantData, setTenantdata] = useState();
  const tenantDataRef = useRef([]);
  // useEffect(() => {
  //   // Calculate the total width of all tab names
  //   const tabList = document.querySelector(".react-tabs__tab-list");
  //   // const totalTabWidth = Array.from(tabList.children).reduce(
  //   //   (acc, tab) => acc + tab.offsetWidth,
  //   //   0
  //   // );
  
  //   // Set the width of the tab list container
  //   tabList.style.width = `800px`;
  //   tabList.style.height = '36px';
  // }, []);   

  const tenants = localStorage.getItem("tenant").toLowerCase();

  const calculateTotalQty = (data) => {
  
    let totalQty = 0;
    data.forEach((entry) => {
     
      if (entry.requestBody.rows && entry.requestBody.rows.length > 0) {
        // Sum up the qty values from all rows
        totalQty += entry.requestBody.rows.reduce(
          (acc, row) => acc + row.qty,
          0
        );
      }
    });
    return totalQty;
  };

  


 

  return (
    <div className="blur-free-tier-content">
      <div className="productdetails-header">
        <div className="productdetails-text">
          <p>Product Marketing</p>
        </div>
      </div>

      <div id="chartdiv1" style={{ width: "100%", height: "500px" }} ></div>
      <div id="chartdiv" style={{ width: "100%", height: "500px" }} />
 
    </div>
  );
}

export default ProductMarketing;
