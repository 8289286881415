import React from "react";
import "./Region.css";
import Map from "../am4chartMap/Map";

function Region({nfcsessions}) {
  return (
    <div className="region-container">
      <div className="region-content">
        <Map nfcsessions={nfcsessions} />
      </div>
    </div>
  );
}
export default Region;
