import React, { useState, useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const WordCloudChart = ({reviewdata}) => {
const [wordCloudDatas,setwordCloudDatas]=useState([])
    useEffect(()=>{
        if (!reviewdata || !reviewdata.reviews) {
            return; // Handle the case where reviewdata is undefined or does not have the reviews property
          }
         // Step 1: Extract reviewText from each object
    const allWords = reviewdata.reviews.map(review => review.reviewText.toLowerCase().split(/\W+/)).flat();
          

    // Step 2: Tokenize and count words
    const wordCounts = {};
    allWords.forEach(text => {
      const words = text.toLowerCase().split(/\W+/).filter(word => word.length > 0);
      words.forEach(word => {
        if (wordCounts[word]) {
          wordCounts[word]++;
        } else {
          wordCounts[word] = 1;
        }
      });
    });
    
    // Step 3: Create dataset in the required format
    const wordCloudData = Object.keys(wordCounts).map(word => ({
      tag: word,
      count: wordCounts[word]
    }));
    
  
    setwordCloudDatas(wordCloudData)
},[reviewdata])
   
    useEffect(() => {

      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end
  
      let chart = am4core.create("chartdivwordcloud", am4plugins_wordCloud.WordCloud);
      chart.fontFamily = "Courier New";
      let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
      series.randomness = 0.1;
      series.rotationThreshold = 0.5;
  
      series.data = wordCloudDatas;
    
      series.dataFields.word = "tag";
      series.dataFields.value = "count";
  
      series.heatRules.push({
        "target": series.labels.template,
        "property": "fill",
        "min": am4core.color("#0000CC"),
        "max": am4core.color("#CC00CC"),
        "dataField": "value"
      });
  
   

      series.labels.template.tooltipText = "{word}: {value}";
  
      let hoverState = series.labels.template.states.create("hover");
      hoverState.properties.fill = am4core.color("#FF0000");
  
      
      let title = chart.titles.create();
    
      title.fontSize = 20;
      title.fontWeight = "800";
  
      return () => {
        chart.dispose();
      };
    }, [wordCloudDatas]);
  
    return <div id="chartdivwordcloud"   style={{ width: "100%", height: "300px" }}  />;
  };

  export default WordCloudChart;