import React, { useState, useEffect, useRef } from "react";
import "./Order.css";
import ReloadIcon from "../assets/reload.png";
import filter from "../assets/filter.png";
import DownArrow from "../assets/dropdownblue.png";
import Status from "./Status/Status";
import axios from "axios";
import { format } from "date-fns";
import { QRCode } from "react-qrcode-logo";
import { fetchTenantData } from "../redux/counterSlice";
import { useDispatch } from "react-redux";

function getStatusStyle(status) {
  const baseStyle = {
    borderRadius: "8px",
    padding: "4px 15px",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18.75px",
    letterSpacing: "0.02em",
  };
  const styles = {
    pending: {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
    Picked: {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
    Transit: {
      backgroundColor: "#b3cee5",
      color: "#4682B4",
      ...baseStyle,
    },
    Delivered: {
      backgroundColor: "#DDFFE2",
      color: "#119927",
      ...baseStyle,
    },
  };

  return <span style={styles[status]}>{status}</span>;
}

function Order() {
  const [expandedRow, setExpandedRow] = useState(null);
  const [isRotating, setIsRotating] = useState(false);
  const [open, setOpen] = React.useState(false);
  const reloadData = () => {
    setIsRotating(true);
    setTimeout(() => {
      setIsRotating(false);
    }, 2000);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    dispatch(fetchTenantData(data.idToken.payload.name));
  }, []);

  const [url, setUrl] = useState();
  const handleClose = (a) => {
    setUrl(a);
    setOpen(!open);
  };
  const toggleRow = (index) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };
  const tenant = {
    orderno: "1712565198337",
    status: "pending",
    requestBody: [],
  };
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const [tenantData, setTenantData] = useState([]);
  const tenantDataRef = useRef([]);
  const [logoImage, setLogoImage] = useState("");
  const [logoOpacity, setLogoOpacity] = useState(1);
  const [qrStyle, setQRStyle] = useState("dots");
  const [logoSize, setLogoSize] = useState(40);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const tenants = localStorage.getItem("tenant").toLowerCase();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/employee?employeeid=${tenants}`
        );

        const data = response.data.orderdetials.reverse();
        setTenantData(data);
        tenantDataRef.current = data;
      } catch (error) {
        console.error("Error fetching NfcSessions:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="orders">
      <div className="orders-header">
        <div className="orders-text">
          <p>Orders</p>
        </div>

        {open && (
  <div className="popup">
    <div className="popup-content">
      <div className="close-button" onClick={handleClose}>
        <span>×</span>
      </div>
      <div className="popup-flex">
        <div className="customize-section">
          <h3>Customize Logo</h3>
          <div className="customize-item">
            <label>Upload Logo:</label>
            <input type="file" accept="image/*" onChange={handleLogoUpload} />
          </div>
          <div className="customize-item">
            <label>Logo Size:</label>
            <input type="range" min="50" max="200" value={logoSize} onChange={(e) => setLogoSize(e.target.value)} />
            <span>{logoSize}</span>
          </div>
          <div className="customize-item">
            <label>Logo Opacity:</label>
            <input type="range" step="0.01" min="0" max="1" value={logoOpacity} onChange={(e) => setLogoOpacity(e.target.value)} />
            <span>{logoOpacity}</span>
          </div>
          <div className="customize-item">
            <label>QR Code Style:</label>
            <select value={qrStyle} onChange={(e) => setQRStyle(e.target.value)}>
              <option value="dots">Dots</option>
              <option value="squares">Square</option>
              <option value="fluid">Fluid</option>
            </select>
          </div>
          <div className="customize-item">
            <button onClick={downloadQRCode}>Download QR codes</button>
          </div>
          <h6>Click on above Button to Download as png</h6>
        </div>
        <div className="qr-code-section" onClick={downloadQRCode}>
          <QRCode
            id="qrCodeEl"
            value={url}
            size={217}
            logoImage={logoImage}
            logoHeight={logoSize}
            logoWidth={logoSize}
            logoOpacity={logoOpacity}
            enableCORS={true}
            qrStyle={qrStyle}
            eyeRadius={10}
          />
        </div>
      </div>
    </div>
  </div>
)}

      </div>
      <div className="table-container">
        <table className="orders-table">
          <thead>
            <tr>
              <th>Order Timestamp</th>
              <th>Order ID</th>
              <th>Product</th>
              <th>Items</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Quantity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tenantData &&
              Array.isArray(tenantData) &&
              tenantData.length > 0 &&
              tenantData.map((order, index) => (
                <>
                  <tr key={index}>
                    <td>
                      <div>{format(Number(order.orderno), "MMMM dd yyyy")}</div>
                      <div className="order-table-timestamp">
                        {format(Number(order.orderno), "HH:mm:ss")}
                      </div>
                    </td>
                    <td>{order.orderno}</td>
                    <td>{order.productName}</td>
                    <td>
                      {order.requestBody.rows &&
                        Array.isArray(order.requestBody.rows) &&
                        order.requestBody.rows.length > 0 &&
                        order.requestBody.rows.map((item, itemIndex) => (
                          <div className="order-table-items" key={itemIndex}>
                            {item.nfc}
                          </div>
                        ))}
                    </td>
                    <td>{getStatusStyle(order.status)}</td>
                    <td>
                      {order.requestBody.rows &&
                        Array.isArray(order.requestBody.rows) &&
                        order.requestBody.rows.length > 0 &&
                        order.requestBody.rows.reduce((accumulator, item) => {
                          const price = parseFloat(item.price);
                          const qty = item.qty;
                          const totalPrice = price * qty;
                          return accumulator + totalPrice;
                        }, 0)}
                    </td>
                    <td>
                      {order.requestBody.rows &&
                        Array.isArray(order.requestBody.rows) &&
                        order.requestBody.rows.length > 0 &&
                        order.requestBody.rows.reduce((accumulator, item) => {
                          const qty = Number(item.qty);
                          return accumulator + qty;
                        }, 0)}
                    </td>
                    <td onClick={() => toggleRow(index)}>
                      <img
                        src={DownArrow}
                        alt="Expand"
                        className={`order-expand-icon ${
                          expandedRow === index ? "rotated" : ""
                        }`}
                      />
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr className="spacer expanded" key={`expanded-${index}`}>
                      <td
                        colSpan={8}
                        style={{ padding: 0, verticalAlign: "top" }}
                      >
                        <Status
                          state={open}
                          handleClose={handleClose}
                          productName={order.productName}
                          itemNames={order.requestBody.rows.map((item) => ({
                            ...item,
                            productImage: item.productImage || null,
                          }))}
                          status={order.status}
                          order={{
                            ...order,
                            productImage: order.productImage || null,
                          }}
                        />
                      </td>
                    </tr>
                  )}
                </>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Order;
