import React, { useEffect } from "react";
import "./index.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Usertable from "./UserTable/UserTable";
import Usertable1 from "./UserTable copy/Usertable";
import Usertable2 from "./UserTable copy 2/Usertable";
import Usertable3 from "./UserTable copy 3/Usertable";
import hourglass from "../../assets/Hourglass.png";

const Index: React.FC = () => {
  useEffect(() => {
    const tabList = document.querySelector(".react-tabs__tab-list");
    if (tabList) {
      const totalTabWidth = Array.from(tabList.children)
        .filter((child): child is HTMLElement => child.nodeName !== "#text")
        .reduce((acc, tab) => acc + tab.offsetWidth, 0);

      const tabListElement = tabList as HTMLElement;
      tabListElement.style.width = `${totalTabWidth}px`;
      tabListElement.style.height = "36px";
    }
  }, []);

  return (
    <div className="superadmin-usermanager" style={{ paddingBottom: "20px", paddingTop: "20px" }}>
    <div>
      <span className="superadmin-dashboard-header">User Managament</span>
    </div>
    <Tabs style={{ marginTop: "20px" }}>
      <TabList style={{ marginBottom: "30px" }}>
        <Tab>Brands</Tab>
        <Tab>Users</Tab>
        <Tab>Products</Tab>
        <Tab>Activity Log</Tab>
      </TabList>
      <TabPanel>
        <Usertable />
      </TabPanel>
      <TabPanel>
        <div className="blur-container">
          <div className="blur-content">
          {/* <Usertable1 /> */}
          </div>
          <div className="center-image">
            <p style={{fontFamily:'Roboto', fontWeight:'400'}}> Coming Soon.... </p>
            <img style={{width:'20px', height:'20px'}} src={hourglass} alt="" />
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="blur-container">
          <div className="blur-content">
          {/* <Usertable2 /> */}
          </div>
          <div className="center-image">
          <p style={{fontFamily:'Roboto', fontWeight:'400'}}> Coming Soon.... </p>
          <img style={{width:'20px', height:'20px'}} src={hourglass} alt="" />

          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="blur-container">
          <div className="blur-content">
          {/* <Usertable3 /> */}
          </div>
          <div className="center-image">
          <p style={{fontFamily:'Roboto', fontWeight:'400'}}> Coming Soon.... </p>
          <img style={{width:'20px', height:'20px'}} src={hourglass} alt="" />

          </div>
        </div>
      </TabPanel>
    </Tabs>
  </div>

  );
};

export default Index;