import React, { useState ,useEffect} from "react";
import "./Counterfeits.css";
import ReloadIcon from "../assets/reload.png";
import filter from "../assets/filter.png";
import DownArrow from "../assets/dropdownblue.png";
import Status from "./CounterfeitStatus/CounterfeitStatus";
//import type { RootState } from "../redux/store";

import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  fetchNfcSessions,
  fetchProducts,
} from "../redux/counterSlice";


// interface TagData {
//   tagid: string;
//   openclose: string;
//   timeline: number;
// }

// interface OrderRow {
//   counterfeitDate: string;
//   counterfeitTime: string;
//   sku: number;
//   product: string;
//   items: string[];
//   report: "Seal Tampering" | "Duplicate Tracked";
//   itemID: string;
//   location: string;
//   firstDot: string;
//   secondDot: string;
// }

function getStatusStyle(report) {
  const baseStyle = {
    borderRadius: "8px",
    // width: '80px',
    // height: '27px',
    padding: "4px 15px",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18.75px",
    letterSpacing: "0.02em",
    // textAlign: 'center' as 'center',
  };

  const styles = {
    "Seal Tampering": {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
    "Duplicate Tracked": {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
  };

  return <span style={styles[report]}>{report}</span>;
}

function Counterfeits() {
  const [expandedRow, setExpandedRow] = useState(null);
  const tenant = localStorage.getItem("tenant").toLowerCase();
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch fetchNfcSessions action when the component mounts
    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant)); // Replace "tenant" with your actual tenant value
  }, [dispatch]);

  const toggleRow = (index) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };

  const nfcsessions = useSelector(
    (state) => state.counter.nfcsessions
  );
 

  const sortedData = nfcsessions.slice().sort((a, b) => parseInt(a.timeline) - parseInt(b.timeline));

  // Group objects by Tagid
  const groupedData = {};
  sortedData.forEach(item => {
    if (!groupedData[item.Tagid]) {
      groupedData[item.Tagid] = [item];
    } else {
      groupedData[item.Tagid].push(item);
    }
  });
  const filteredTagIds = {};

for (const tagId in groupedData) {
    const tagIdData = groupedData[tagId];
    const latestData = tagIdData[tagIdData.length - 1];

    let isCounterDecreasing = false;
    for (let i = tagIdData.length - 2; i >= 0; i--) {
        const previousData = tagIdData[i];
        if (latestData.decimalValue < previousData.decimalValue) {
            isCounterDecreasing = true;
            break;
        }
    }

    if (isCounterDecreasing) {
        filteredTagIds[tagId] = tagIdData;
    }
}
function sysmathsdDate(dates) {
  const time = Number(dates);
  const date = new Date(time);

  // Extract date components
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-indexed
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Format the date
  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""}${day}`;
}
function sysmathsdtime(dates) {
  const time = Number(dates);
  const date = new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Format the date
  return `${hours}:${minutes}:${seconds}`;
}


// Assuming data is your object
const mappedData = Object.entries(filteredTagIds).map(([key, value]) => ({
  tagId: key, // Extracting the key as tagId
  objects: value.map(obj => ({
    counterfeitDate: sysmathsdDate(obj.timeline),
    counterfeitTime: sysmathsdtime(obj.timeline),
    sku: obj.timeline,
    product: obj.title,
    items: [' NFC 213'],
    report: 'Duplicate Tracked',
    itemID: obj.Tagid,
    location: obj.geodata.city,
    firstDot: obj.geodata.city,
    secondDot: obj.geodata.region,
  }))
}));

// mappedData will contain an array of objects where each object has a tagId and objects array

  // Now 'groupedData' contains objects grouped by Tagid, sorted by timeline
 // console.log(groupedData);
  // const filteredTagIds = {};

  // for (const tagId in groupedData) {
  //     const tagIdData = groupedData[tagId];
  //     const latestData = tagIdData[tagIdData.length - 1];
  //     const oldestData = tagIdData[0];
      
  //     if (latestData.openclose === "unbreak" && oldestData.openclose === "break") {
  //         filteredTagIds[tagId] = tagIdData;
  //     }
  // }
  
  // console.log(filteredTagIds);

 
  
  
  
  

  return (
    <div className="orders">
      <div className="orders-header">
        <div className="orders-text">
          <p>Counterfeits Report</p>
        </div>
        <div className="right-side-content">
          <span className="last-updated">Last updated 32 mins ago</span>
          <img src={ReloadIcon} alt="Reload" className="reload-icon" />
          <div className="order-filter">
            <button className="order-filter-button">
              <img src={filter} alt="Filter" />
              Filter
            </button>
          </div>
        </div>
      </div>
      <table className="orders-table">
        <thead>
          <tr>
            <th>Track Timestamp</th>
            <th>SKU IDs</th>
            <th>Product Name</th>
            <th>Tag type</th>
            <th>Report Details</th>
            <th>Tag IDs</th>
            <th>Location</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {mappedData.map((order, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <div>{order.objects[0].counterfeitDate}</div>
                  <div className="order-table-timestamp">
                    {order.objects[0].counterfeitTime}
                  </div>
                </td>
                <td>{order.objects[0].sku}</td>
                <td>{order.objects[0].product}</td>
                <td>
                  {order.objects[0].items.map((item, itemIndex) => (
                    <div className="order-table-items" key={itemIndex}>
                      {item}
                    </div>
                  ))}
                </td>
                <td>{getStatusStyle(order.objects[0].report)}</td>
                <td>{order.tagId}</td>
                <td>{order.objects[0].location}</td>
                <td onClick={() => toggleRow(index)}>
                  <img
                    src={DownArrow}
                    alt="Expand"
                    className={`order-expand-icon ${
                      expandedRow === index ? "rotated" : ""
                    }`}
                  />
                </td>
              </tr>
              {expandedRow === index && (
                <tr className="spacer expanded">
                  <td colSpan={8} style={{ padding: 0, verticalAlign: "top" }}>
                    <Status
                      productName={order.objects[0].product}
                      itemNames={order.objects[0].items}
                      report={order.objects[0].report}
                      firstDot={order.objects[0].firstDot} // Pass the firstDot value as a prop
                      secondDot={order.objects[order.objects.length-1].secondDot} // Pass the secondDot value as a prop
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Counterfeits;
