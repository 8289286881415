export const pricingTableData = {
  features: [
    "Credits",
    "Number of scans",
    "Static QR Codes",
    "Dynamic QR Codes",
    "Serialized Dynamic Codes",
    "Dynamic Scans",
    "Users",
    "Dashboard",
    "Social Media Analytics & Integration",
    "Marketplace Analytics & Integration",
    "Traceability(from origin to shelf)",
    "ERP Integration",
    "High Resolution Image",
    "Track the location of Scans",
    "Save your QR code design as a template",
    "Upload Limit",
    "QR Codes in Bulk",
    "Google Analytics",
    "Precision Geolocation Tracking",
    "Restrict Scanning Zone(Geofence)",
    "Enhanced Analytics",
    "Email Reporting",
    "Priority Support",
    "Product Recommendation on Dynamic Page ",
    "Pop ups with CTA on Dynamic page",
    "Sub Domain for Dynamic Page",
    "Use your own domain(white label)",
  ],
  descriptions: [
    "Currency used to create dynamic codes and other operations",
    "Number of times the static QR code can be scanned",
    "QR codes that could be generated with a fixed landing page",
    "QR codes that could be generated with an editable landing page",
    "Unique serial codes for each item in a batch of products",
    "Number of times a dynamic QR code can be scanned ",
    "Number of users that can access an account",
    "Access to the user dashboard",
    "Integration with social media platforms",
    "Integration with online marketplaces like Amazon, Zomato, Blinkit, Flipkart etc..",
    "Track and trace the journey of your product from source to consumers via decentralized distributed ledger ",
    "Integration with ERP software like SAP, Oracle etc.",
    "High quality images for dynamic landing pages.",
    "Track where the scans are happening",
    "Save and reuse the same QR code for designs",
    "Maximum size of data uploaded",
    "Ability to generate QR codes in a mass amount",
    "Integration with Google Analytics",
    "Precise tracking of scan locations",
    "Restrict areas where QR codes can be scanned",
    "Advanced analytics features like sentiment and feedback Analysis",
    "Regular scheduled reports sent directly to your email.",
    "Priority Customer Support",
    "Promote your other products through our recommendation engine. ",
    "Generate popups with call-to-action on the dynamic landing page of your product.",
    "Showcase your own domain along with our domain name for dynamic QR code pages.",
    "Showcase your own domain and remove our branding for dynamic QR code pages.",
  ],
  free: [
    "30",
    "Unlimited",
    "Unlimited",
    "3",
    "No",
    "500",
    "1",
    "Limited Access",
    "No",
    "No",
    "No",
    "No",
    "No",
"Yes",
"No",
"2 MB",
"No",
"No",
"No",
"No",
"No",
"No",
"No",
"No",
"No",
"No",
"No"
  ],
  basic: [
    "2,500",
    "Unlimited",
    "Unlimited",
    "250",
    "No",
    "6000",
    "3",
    "Full Access",
    "No",
    "No",
    "No",
    "No",
    "Yes",
"Yes",
"Yes",
"10 MB",
"No",
"No",
"No",
"No",
"Yes",
"No",
"No",
"Yes",
"No",
"No",
"No",
  ],
  standard: [
    "15,000",
    "Unlimited",
    "Unlimited",
    "1,500",
    "15,000",
    "50,000",
    "5",
    "Full Access",
    "Instagram and Facebook",
    "Yes",
    "No",
    "No",
    "Yes",
"Yes",
"Yes",
"50 MB",
"Yes",
"Yes",
"No",
"Yes",
"Yes",
"Yes",
"No",
"Yes",
"Yes",
"Yes",
"No",
  ],
  enterprise: [
    "Customised",
    "Unlimited",
    "Unlimited",
    "Customised",
    "Customised",
    "Unlimited",
    "10",
    "Full Access",
    "Instagram, Facebook, Twitter, Youtube and Website",
    "Yes",
    "Yes",
    "Yes",
    "Yes",
"Yes",
"Yes",
"Customized",
"Yes",
"Yes",
"Yes",
"Yes",
"Yes",
"Yes",
"Yes",
"Yes",
"Yes",
"Yes",
"Yes",
  ],
};
