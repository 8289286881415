import './BrandName.css'

function BrandName() {
  return (
    <div className="brand-name">
      <span className="welcome">Welcome,</span>
      <span className="username"> ARVO</span>
      <div className="greeting">Have a great day ahead!</div>
    </div>
  )
}

export default BrandName;
