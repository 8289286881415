import React, { useState } from "react";
import "./ForgotPassword.css";
import logo from "../../assets/arvologo.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import config from "../../cognito/config";
import OtpInput from "react-otp-input";

function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [stage, setStage] = useState(1);
  const navigate = useNavigate();

  const handleMailUs = () => {
    const subject = encodeURIComponent("Regarding Inquiry");
    const body = encodeURIComponent(
      `Hello,\n\nI would like to inquire about .\n\nPlease provide me with more information.\n\nThank you.`
    );
    window.location.href = `mailto:support@arvo.services?subject=${subject}&body=${body}`;
  };

  const userPool = new CognitoUserPool({
    UserPoolId: config.userPoolId,
    ClientId: config.userPoolWebClientId,
  });

  const handleSendCode = () => {
    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.forgotPassword({
      onSuccess: function (result) {
        toast.success("Verification code sent to your email");
        setStage(2);
      },
      onFailure: function (err) {
        toast.error(err.message || JSON.stringify(err));
      },
    });
  };

  const handleResetPassword = () => {
    if (!confirmPassword) {
      setPasswordMatchError("Please type the confirm password");
      return;
    }
    if (newPassword !== confirmPassword) {
      setPasswordMatchError("Passwords do not match");
      return;
    }
    setPasswordMatchError(""); // Clear the error message if passwords match
    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: function () {
        toast.success("Password reset successfully");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      },
      onFailure: function (err) {
        toast.error(err.message || JSON.stringify(err));
      },
    });
  };

  return (
    <div className="forgot-password-container">
      <div className="left-side">
        <div className="logo-container">
          <img src={logo} alt="ARVO Logo" className="logo" />
        </div>
      </div>
      <div className="right-side">
        <div className="forgot-password-right-side">
          {stage === 1 && (
            <>
              <div className="forgot-password-top-texts">
                <span className="forgot-password-welcome">Forgot Password</span>
                <span className="forgot-password-arvocloud">
                  ARVO Integrated Cloud
                </span>
              </div>
              <div className="forgot-password-input-box-container">
                <input
                  type="text"
                  placeholder="Email"
                  className="box-forgot-password-input"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <button
                className="forgot-password-button"
                onClick={handleSendCode}
              >
                Send Verification Code
              </button>
            </>
          )}
          {stage === 2 && (
            <>
              <div className="forgot-password-top-texts">
                <span className="forgot-password-welcome">Reset Password</span>
                <span className="forgot-password-arvocloud">ARVO Integrated Cloud</span>
              </div>
              <div className="forgot-password-input-box-container">
              <p className="  ">
                Please enter the OTP received in your email
              </p>
                <OtpInput
                  value={verificationCode}
                  onChange={setVerificationCode}
                  numInputs={6}
                  separator={<span>-</span>}
                  inputStyle="forgot-inputStyle"
                  renderInput={(props) => <input {...props} />}
                />
                <input
                  type="password"
                  placeholder="New Password"
                  className="box-forgot-password-input"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="box-forgot-password-input"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {passwordMatchError && (
                  <div className="error-message">{passwordMatchError}</div>
                )}
              </div>
              <button
                className="forgot-password-button"
                onClick={handleResetPassword}
              >
                Reset Password
              </button>
              <div
                className="otp-info-text"
                style={{
                  fontSize: "12px",
                  color: "#555",
                  margin: "10px",
                }}
              >
                (If you don't find the mail, please check your junk or spam
                folder.)
              </div>
            </>
          )}
          <div className="text-btn-below" onClick={handleMailUs}>
            <span className="having-trb">Having trouble?</span>
            <span className="contact-us">Contact us</span>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ForgotPassword;
