import React, { useState ,useEffect} from "react";
import "./SignUp.css";
import logo from "../../assets/arvologo.png";
import productImage from "../../assets/AIC front.png";
import { signUp } from "../../cognito/Auth";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import config from "../../cognito/config";
import OtpInput from "react-otp-input";
import axios from "axios";
import mobile from "../../assets/mobile.png";
import bg1 from "../../assets/login2.png";
import bg2 from "../../assets/login3.png";
import bg3 from "../../assets/login5.png";
import bg4 from "../../assets/login7.png";
import mobileview1 from "../../assets/login1.png";
import mobileview2 from "../../assets/login4.png";
import mobileview3 from "../../assets/login6.png";
import mobileview4 from "../../assets/login8.png";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CompanySearch from "./CompanySearch"; // Import the CompanySearch component

function SignUp() {
  const [brandName, setBrandName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedTier, setSelectedTier] = useState("Free Tier");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState();
  const [signedUp, setSignedUp] = useState(false); // State to track signup success
  const navigate = useNavigate();
  const [bgImages, setBgImages] = useState([bg1, bg2, bg3, bg4]);
  const [overlayImages, setOverlayImages] = useState([mobileview1, mobileview2, mobileview3, mobileview4]);
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const [currentOverlayIndex, setCurrentOverlayIndex] = useState(0);

  const handleMailUs = () => {
    const subject = encodeURIComponent("Regarding Inquiry");
    const body = encodeURIComponent(
      `Hello,\n\nI would like to inquire about .\n\nPlease provide me with more information.\n\nThank you.`
    );
    window.location.href = `mailto:support@arvo.services?subject=${subject}&body=${body}`;
  };

  const configs = {
    UserPoolId: config.userPoolId,
    ClientId: config.userPoolWebClientId,
  };

  const transformBrandName = (brandName) => {
    // Convert to lowercase
    let transformed = brandName.toLowerCase();

    // Remove spaces
    transformed = transformed.replace(/\s+/g, "");

    // Append '1' at the end
    transformed += "1";

    return transformed;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
      setCurrentOverlayIndex((prevIndex) => (prevIndex + 1) % overlayImages.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, [bgImages.length, overlayImages.length]);

  const validatePassword = (password) => {
    const lengthCheck = password.length >= 8;
    const upperCaseCheck = /[A-Z]/.test(password);
    const lowerCaseCheck = /[a-z]/.test(password);
    const numberCheck = /[0-9]/.test(password);
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return {
      lengthCheck,
      upperCaseCheck,
      lowerCaseCheck,
      numberCheck,
      specialCharCheck,
    };
  };

  const handleSignUp = async () => {
    setError("");

    // Check if any field is left blank
    if (
      !brandName ||
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !confirmPassword
    ) {
      setError("Please fill in all fields");
      return;
    }

    // Check if the email is in the correct format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }

    // Check if the passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    // Validate password requirements
    const passwordErrors = validatePassword(password);
    if (Object.values(passwordErrors).some((value) => !value)) {
      setError("Please meet all password requirements");
      return;
    }

    // Check if the brand name is available
    try {
      const response = await axios.get(
        `https://4y53b9gp2k.execute-api.ap-south-1.amazonaws.com/prod/onboading/?id=${transformBrandName(
          brandName
        )}`
      );
      if (response.data.Item) {
        setError("Brand name already taken");
        return;
      }
    } catch (error) {
      console.error("Error checking brand name:", error);
      setError("An error occurred while checking the brand name");
      return;
    }

    // Proceed with sign up if brand name is available
    let username = `${firstName}-${lastName}`;
    try {
      const result = await signUp(username, password, email, brandName);
      const postdata = {
        name: username,
        id: transformBrandName(brandName),
        BrandName: brandName,
        email: email,
      };
      const response = await axios.put(
        "https://4y53b9gp2k.execute-api.ap-south-1.amazonaws.com/prod/onboading",
        JSON.stringify(postdata),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        // Handle successful API response
      }
      console.log("Signup successful:", result);
      setUser(result);
      setSignedUp(true);
    } catch (error) {
      setError(error.message || "An error occurred during signup.");
      console.error("Error during signup:", error);
    }
  };

  const userPool = new CognitoUserPool(configs);

  const handleOTPValidation = async () => {
    let username = `${firstName}-${lastName}`;
  
    const userData = {
      Username: email, // Or `username` if you're using the generated username
      Pool: userPool,
    };
  
    const cognitoUser = new CognitoUser(userData);
  
    try {
      cognitoUser.confirmRegistration(otp, true, async (err, result) => {
        if (err) {
          console.error(`Error confirming registration: ${err.message}`);
          setError(err.message);
          return;
        }
  
        // Construct email data
        const emailData = {
          recipient_email: email,
          brand_name: brandName,
          first_name: firstName,
          template_type: 'onboarding', // or 'password_change', 'ticket_generation', 'qr_code_download'
          ticket_id: '12345', // Optional
          issue_description: 'Issue description', // Optional
          date_raised: '2024-06-18', // Optional
          date_generated: '2024-06-18', // Optional
          description: 'Product description' // Optional
        };
  
        try {
          const response = await axios.post(
            'https://4y53b9gp2k.execute-api.ap-south-1.amazonaws.com/prod/emails',
            emailData,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
  
          console.log('Email sent successfully!', response.data);
        } catch (error) {
          console.error('Error sending email:', error.response ? error.response.data : error.message);
        }
  
        toast.info(
          `Your account has been created. You can now log in and start using our services`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
        // If confirmation succeeds
        // Redirect the user to the next page or display a success message
      });
    } catch (error) {
      console.error(`Caught error: ${error.message}`);
      setError(error.message);
    }
  };
  

  const passwordChecks = validatePassword(password);

  return (
    <div className="signup-container">
      <div className="left-side">
        <div className="text-top">
          <p>
            Create dynamic product{" "}
            <span style={{ color: "#0956cc" }}>landing pages</span> and{" "}
            <span style={{ color: "#0956cc" }}>safeguard</span> your physical
            products with
            <span style={{ fontWeight: "500" }}>
              {" "}
              Copy-proof QR codes, NFC labels,
            </span>{" "}
            and{" "}
            <span style={{ fontWeight: "500" }}>Tamper-proof AI labels.</span>
          </p>
        </div>
        {/* <div className="image-center">
          <img
            src={productImage}
            className="login-image-center"
            alt="Product"
          />
        </div>
        <div className="logo-bottom-left">
          <img src={logo} alt="Logo" />
        </div>
      </div> */}
      <div className="login-image-center">
      <img src={bgImages[currentBgIndex]} alt="Background Image" className="login-background-image" />
      <img src={mobile} alt="Mobile Image" className="login-base-image" />
      <img src={overlayImages[currentOverlayIndex]} alt="Overlay Image" className="login-overlay-image" />
    </div>
      <div className="logo-bottom-left">
        <img src={logo} alt="Logo" />
      </div>
    </div>
      <div className="right-side">
        <ToastContainer />
        {signedUp === false ? (
          <div className="signup-right-side">
            <div className="signup-top-texts">
              <span className="signup-welcome">Signup to</span>
              <span className="signup-arvocloud">ARVO Integrated Cloud</span>
            </div>
            <div
              className="signup-container-input-box"
              style={{ width: "100%" }}
            >
              <label htmlFor="brandName">Brand Name:</label>
              <CompanySearch
                onSelectCompany={(company) => setBrandName(company.title)}
              />
              <div className="name-inputs-container">
                <div className="first-name-container">
                  <label htmlFor="firstName">First Name:</label>
                  <input
                    type="text"
                    id="firstName"
                    className="box-signup-input"
                    placeholder="First name"
                    value={firstName}
                    style={{ marginTop: "10px" }}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="last-name-container">
                  <label htmlFor="lastName">Last Name:</label>
                  <input
                    type="text"
                    id="lastName"
                    style={{ marginTop: "10px" }}
                    className="box-signup-input"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                className="box-signup-input"
                placeholder="Enter your email"
                style={{ width: "100%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                className="box-signup-input"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: "100%" }}
              />
              {password && (
                <ul className="password-requirements">
                  <li
                    style={{
                      color: passwordChecks.lengthCheck ? "green" : "red",
                    }}
                  >
                    At least 8 characters long
                  </li>
                  <li
                    style={{
                      color: passwordChecks.upperCaseCheck ? "green" : "red",
                    }}
                  >
                    At least one uppercase letter
                  </li>
                  <li
                    style={{
                      color: passwordChecks.lowerCaseCheck ? "green" : "red",
                    }}
                  >
                    At least one lowercase letter
                  </li>
                  <li
                    style={{
                      color: passwordChecks.numberCheck ? "green" : "red",
                    }}
                  >
                    At least one number
                  </li>
                  <li
                    style={{
                      color: passwordChecks.specialCharCheck ? "green" : "red",
                    }}
                  >
                    At least one special character
                  </li>
                </ul>
              )}

              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input
                type="password"
                id="confirmPassword"
                className="box-signup-input"
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{ width: "100%" }}
              />
              {error && (
                <div className="error-message" style={{ color: "red" }}>
                  {error}
                </div>
              )}
            </div>
            <button className="signup-button" onClick={handleSignUp}>
              Signup
            </button>
            <div className="text-btn-below" onClick={handleMailUs}>
              <span className="having-trb">Having trouble?</span>
              <span className="contact-us">Contact us</span>
            </div>
          </div>
        ) : ( <div className="signup-right-side">
        <div className="otp-validation-container">
          <h2 className="signup-welcome">OTP Validation</h2>
          <p className="signup-arvocloud">
            Please enter the OTP received in your email
          </p>
    
          <br />
          <br />
          <div className="otp-input-container">
            <OtpInput
              value={otp}
              inputStyle="inputStyle"
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <br />
          <br />
          {error && <p className="error-message">{error}</p>}
          <button className="signup-button" onClick={handleOTPValidation}>
            Validate OTP
          </button>
          <div className="otp-info-text">
            (If you don't find the mail, please check your junk or spam folder.)
          </div>
        </div>
      </div>
    )}
      </div>
    </div>
  );
}

export default SignUp;
