import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { QRCode } from "react-qrcode-logo";
import PdfPopup from "./PdfPopup";
import GridLoader from "react-spinners/GridLoader";
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100vh;
  margin-top: 100px;
  padding: 20px;
  background-color: #f0f0f0;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 4px;
  right: 10px;
  width: 30px;
  height: 30px;
  background: linear-gradient(45deg, #ff4d4d, #ff0000);
  border: none;
  border-radius: 50%;
  font-size: 18px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;

  &:hover {
    background: linear-gradient(45deg, #ff6666, #ff1a1a);
  }
`;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  background-color: #f0f0f0;
`;

const DropZone = styled.div`
  width: 600px;
  height: 600px;
  border: 2px dashed #007bff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  position: relative;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const QRCodeWrapper = styled.div`
  position: absolute;
  cursor: move;
  transition: all 0.2s ease;
`;

const TextWrapper = styled.div`
  position: absolute;
  cursor: move;
  transition: all 0.2s ease;
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: ${(props) => (props.remove ? "#ff4d4d" : "#007bff")};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.remove ? "#ff1a1a" : "#0056b3")};
  }
`;

const GoldenButton = styled(Button)`
  background: linear-gradient(45deg, #ffd700, #ffa500);
  color: black;
  font-weight: bold;
  margin-bottom: 20px;

  &:hover {
    background: linear-gradient(45deg, #ffa500, #ffd700);
  }
`;

const CustomizeSection = styled.div`
  width: 35%;
  height: 90%;
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-y: auto;
`;

const Input = styled.input`
  margin-top: 10px;
  padding: 5px;
  width: 100%;
`;

const ColorInput = styled.input`
  margin-top: 10px;
  padding: 5px;
  width: 100%;
`;

const Label = styled.label`
  margin-top: 10px;
  display: block;
`;

const Select = styled.select`
  margin-top: 10px;
  padding: 5px;
  width: 100%;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999999;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius:7px;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
`;

const LoadingWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1500;
  width: 60%;
  text-align: center;
`;

const LoadingBarContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f3f3f3;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
`;

const LoadingBar = styled.div`
  height: 100%;
  background: linear-gradient(45deg, #ffd700, #ffa500);
  width: ${(props) => props.progress}%;
  transition: width 0.5s ease;
`;

const GeneratingQRCode = styled.div`
  margin-top: 10px;
  font-size: 18px;
  color: #007bff;
  text-align: center;
`;

// Function to detect file type
const getFileType = (file) => {
  const type = file?.type;
  if (type.includes("image/jpeg")) return "jpg";
  if (type.includes("image/png")) return "png";
  if (type.includes("application/pdf")) return "pdf";
  return "unknown";
};
const MAX_DIMENSION = 600;
const resizeImage = (imageSrc, maxWidth, maxHeight, callback) => {
  if (typeof window === 'undefined') {
    console.error("This function should only be run in the browser environment.");
    return;
  }

  const img = new window.Image(); // Explicitly use `window.Image`
  img.src = imageSrc;
  img.onload = () => {
    let { width, height } = img;

    // Calculate the scaling factor to maintain the aspect ratio
    if (width > maxWidth || height > maxHeight) {
      const scalingFactor = Math.min(maxWidth / width, maxHeight / height);
      width = width * scalingFactor;
      height = height * scalingFactor;
    }

    // Create a canvas to draw the resized image
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    // Convert canvas back to base64 image
    const resizedImage = canvas.toDataURL("image/jpeg");
    callback(resizedImage);
  };

  img.onerror = (error) => {
    console.error("Error loading image:", error);
  };
};


const ImageQrCode = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [fileType, setFileType] = useState("pdf");
  const [fileTypes, setFileTypes] = useState("png");
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [numberOfCopies, setNumberOfCopies] = useState(1);
  const [copiesPerPage, setCopiesPerPage] = useState(1);
  const [batchNumber, setBatchNumber] = useState("");
  const [batchDate, setBatchDate] = useState("");
  const [expiryDuration, setExpiryDuration] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [qrCodePosition, setQrCodePosition] = useState({ x: 100, y: 100 });
  const [qrCodeVisible, setQrCodeVisible] = useState(false);
  const [currentSerial, setCurrentSerial] = useState("");
  const [showInputPopup, setShowInputPopup] = useState(false);
  
  // QR Code customization options
  const [qrCodeSize, setQrCodeSize] = useState(100);
  const [qrCodeColor, setQrCodeColor] = useState("#000000");
  const [qrCodeBgColor, setQrCodeBgColor] = useState("#ffffff");
  const [qrCodeEyeColor, setQrCodeEyeColor] = useState("#000000");

  const extractNumberFromUrl = (url) => {
    const regex = /uid=(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const dataid = localStorage.getItem("link");
  const extractedNumber = extractNumberFromUrl(dataid);

  const [logoImage, setLogoImage] = useState("");
  const [logoOpacity, setLogoOpacity] = useState(1);
  const [qrStyle, setQRStyle] = useState("dots");
  const [logoSize, setLogoSize] = useState(50);

  const [texts, setTexts] = useState([]);
  const [activeTextIndex, setActiveTextIndex] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      resizeImage(reader.result, MAX_DIMENSION, MAX_DIMENSION, setImageSrc);
      setQrCodeVisible(false);
      setQrCodePosition(null);
      setCurrentSerial("");
      setTexts([]);
      setActiveTextIndex(null);
    };

    reader.readAsDataURL(file);
  }, []);


  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    onDrop: useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];
      const fileType = getFileType(file);
      setFileTypes(fileType)
      if (fileType === "unknown") {
        alert("Unsupported file type");
        return;
      }
      if (fileType === "pdf") {
        // Handle PDF file (if necessary)
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        resizeImage(reader.result, MAX_DIMENSION, MAX_DIMENSION, (resizedImage) => {
          setImageSrc(resizedImage);
          setQrCodeVisible(false);
          setQrCodePosition(null);
          setCurrentSerial("");
          setTexts([]);
          setActiveTextIndex(null);
        });
      };
      reader.readAsDataURL(file);
    }, []),
  });

  const calculateExpiryDate = (batchDate, duration) => {
    const date = new Date(batchDate);
    date.setDate(date.getDate() + parseInt(duration));
    return date.toISOString().split("T")[0];
  };
  const handleGenerateFile = async () => {
    setLoading(true);
    setLoadingProgress(0); // Initialize loading progress

    try {
        const requestBody = {
            imageBuffer: imageSrc.split(",")[1], // Extract base64 string
            qrCodeData: `https://arvo.media/?uid=${extractedNumber}x${batchDate}-${expiryDuration}x${currentSerial}`,
            texts: texts.map((text) => ({
                content: text.text,
                xPos: text.position.left,
                yPos: text.position.top,
                size: text.fontSize,
            })),
            qrCodePosition: qrCodePosition,
            copies: numberOfCopies,
            copiesPerPage: copiesPerPage,
            fileType: fileTypes,
            qrCodeSize: qrCodeSize,
            qrCodeColor: qrCodeColor,
            qrCodeBgColor: qrCodeBgColor,
            batchNumber:batchNumber
        };

        console.log(requestBody)
        const response = await fetch(
            "https://x5i01p9a23.execute-api.ap-south-1.amazonaws.com/prod/imageqrcode",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            }
        );

        const result = await response.json();
        if (response.ok) {
            setPdfDataUrl(result.url);
            setShowPopup(true);
        } else {
            console.error("Error:", result.message);
        }
    } catch (error) {
        console.error("Request failed:", error);
    } finally {
        setLoading(false);
    }
};

  
  const handleAddRemoveQRCode = () => {
    setQrCodeVisible(!qrCodeVisible);
    setQrCodePosition(qrCodeVisible ? null : { x: 100, y: 100 });
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const index = e.dataTransfer.getData("text/plain");
    const dropZoneRect = e.currentTarget.getBoundingClientRect();
    const dropX = e.clientX - dropZoneRect.left;
    const dropY = e.clientY - dropZoneRect.top;

    if (index === "qr-code") {
      setQrCodePosition({
        x: dropX - qrCodeSize / 2, // Center the QR code
        y: dropY - qrCodeSize / 2,
      });
    } else if (index >= 0 && index < texts.length) {
      const newTexts = [...texts];
      newTexts[index].position = {
        left: dropX - newTexts[index].fontSize / 2, // Center the text
        top: dropY - newTexts[index].fontSize / 2,
      };
      setTexts(newTexts);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleAddText = () => {
    setTexts([
      ...texts,
      {
        text: "New Text",
        position: { top: 100, left: 100 },
        fontSize: 20,
        fontWeight: "normal",
        fontStyle: "normal",
        fontColor: "#000000",
        fontFamily: "Arial",
      },
    ]);
    setActiveTextIndex(texts.length);
  };

  const handleTextChange = (e) => {
    if (activeTextIndex !== null) {
      const newTexts = [...texts];
      newTexts[activeTextIndex].text = e.target.value;
      setTexts(newTexts);
    }
  };

  const handleFontSizeChange = (e) => {
    if (activeTextIndex !== null) {
      const newTexts = [...texts];
      newTexts[activeTextIndex].fontSize = e.target.value;
      setTexts(newTexts);
    }
  };

  const handleFontWeightChange = (e) => {
    if (activeTextIndex !== null) {
      const newTexts = [...texts];
      newTexts[activeTextIndex].fontWeight = e.target.value;
      setTexts(newTexts);
    }
  };

  const handleFontStyleChange = (e) => {
    if (activeTextIndex !== null) {
      const newTexts = [...texts];
      newTexts[activeTextIndex].fontStyle = e.target.value;
      setTexts(newTexts);
    }
  };

  const handleFontColorChange = (e) => {
    if (activeTextIndex !== null) {
      const newTexts = [...texts];
      newTexts[activeTextIndex].fontColor = e.target.value;
      setTexts(newTexts);
    }
  };

  const handleFontFamilyChange = (e) => {
    if (activeTextIndex !== null) {
      const newTexts = [...texts];
      newTexts[activeTextIndex].fontFamily = e.target.value;
      setTexts(newTexts);
    }
  };

  return (
    <Wrapper>
      <Container>
        <DropZone
          {...getRootProps()}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <input {...getInputProps()} />
          {imageSrc ? (
            <ImageWrapper
              id={`canvas-1`}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <Image src={imageSrc} alt="Uploaded" />
              {qrCodeVisible && qrCodePosition && (
                <QRCodeWrapper
                  id="qr-code"
                  style={{
                    top: `${qrCodePosition.y}px`,
                    left: `${qrCodePosition.x}px`,
                  }}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "qr-code")}
                >
                  <QRCode
                    value={`https://arvo.media/?uid=${extractedNumber}x${batchDate}-${expiryDuration}x${currentSerial}`}
                    size={qrCodeSize}
                    fgColor={qrCodeColor}
                    bgColor={qrCodeBgColor}
                    eyeColor={qrCodeEyeColor}
                    logoImage={logoImage}
                    logoHeight={logoSize}
                    logoWidth={logoSize}
                    logoOpacity={logoOpacity}
                    qrStyle='bar'
                    eyeRadius={10}
                  />
                </QRCodeWrapper>
              )}
              {texts.map((text, index) => (
                <TextWrapper
                  key={index}
                  id={`text-${index}`}
                  style={{
                    ...text.position,
                    fontSize: `${text.fontSize}px`,
                    fontWeight: text.fontWeight,
                    fontStyle: text.fontStyle,
                    color: text.fontColor,
                    fontFamily: text.fontFamily,
                  }}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  {text.text}
                </TextWrapper>
              ))}
            </ImageWrapper>
          ) : (
            <p>Drag & drop an image here, or click to select one</p>
          )}
        </DropZone>
        {imageSrc && showPopup && (
          <PdfPopup
            pdfDataUrl={pdfDataUrl}
            onClose={() => setShowPopup(false)}
            numberOfCopies={numberOfCopies}
            numberOfPages={copiesPerPage}
            batchId={batchNumber}
            batchDate={batchDate}
            expiryDate={expiryDate}
          />
        )}

        {loading && (
          <Overlay>
            <LoadingWrapper>
              <GeneratingQRCode>Generating Preview...</GeneratingQRCode>
              <GridLoader />       
            </LoadingWrapper>
          </Overlay>
        )}
  {showInputPopup && (
    <Overlay >
      <Popup onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={() => setShowInputPopup(false)}>&times;</CloseButton>
        <h2>Enter the number of copies and batch details</h2>
        <Label>File Type:</Label>
        <Select
          value={fileType}
          onChange={(e) => setFileType(e.target.value)}
        >
          <option value="pdf">PDF</option>
        </Select>
        <Label>Number of Copies:</Label>
        <Input
          type="number"
          value={numberOfCopies}
          onChange={(e) => setNumberOfCopies(e.target.value)}
          min="1"
          max="600"
        />
        <Label>Copies Per Page:</Label>
        <Input
          type="number"
          value={copiesPerPage}
          onChange={(e) => setCopiesPerPage(e.target.value)}
          min="1"
          max="4"
          disabled // Disable if Batch Date or Expiry Duration is not set
        />
        <Label>Batch Number:</Label>
        <Input
          type="text"
          value={batchNumber}
          onChange={(e) => setBatchNumber(e.target.value)}
        />
        <Label>Batch Date:</Label>
        <Input
          type="date"
          value={batchDate}
          onChange={(e) => setBatchDate(e.target.value)}
        />
        <Label>Expiry Duration (in days):</Label>
        <Input
          type="number"
          value={expiryDuration}
          onChange={(e) => {
            setExpiryDuration(e.target.value);
            setExpiryDate(calculateExpiryDate(batchDate, e.target.value));
          }}
        />
        <Label>Expiry Date:</Label>
        <Input type="text" value={expiryDate} readOnly />
        <Button
          onClick={() => {
            setShowInputPopup(false);
            handleGenerateFile();
          }}
          disabled={!batchDate || !expiryDuration} // Disable button if Batch Date or Expiry Duration is not set
        >
          Generate 
        </Button>
      </Popup>
    </Overlay>
  )}


      </Container>
      {imageSrc && (
        <CustomizeSection>
          {qrCodeVisible && (
            <GoldenButton onClick={() => setShowInputPopup(true)}>
              Generate Serialized QR Code
            </GoldenButton>
          )}
          <h3>Customize QR Code</h3>
          <Button onClick={handleAddRemoveQRCode} remove={qrCodeVisible}>
            {qrCodeVisible ? "Remove QR Code" : "Add QR Code"}
          </Button>
          {qrCodeVisible && (
            <>
              <Label>QR Code Size:</Label>
              <Input
                type="range"
                min="50"
                max="300"
                value={qrCodeSize}
                onChange={(e) => setQrCodeSize(parseInt(e.target.value))}
              />
              <Label>QR Code Color:</Label>
              <ColorInput
                type="color"
                value={qrCodeColor}
                onChange={(e) => setQrCodeColor(e.target.value)}
              />
              <Label>QR Code Background Color:</Label>
              <ColorInput
                type="color"
                value={qrCodeBgColor}
                onChange={(e) => setQrCodeBgColor(e.target.value)}
              />
              <Label>QR Code Eye Color:</Label>
              <ColorInput
                type="color"
                value={qrCodeEyeColor}
                onChange={(e) => setQrCodeEyeColor(e.target.value)}
              />
              <Label>QR Code Logo:</Label>
              <Input type="file" onChange={handleLogoUpload} />
            </>
          )}
          <h3 style={{ marginTop: "40px" }}>Add and Customize Text</h3>
          <Button onClick={handleAddText}>Add Text</Button>
          {activeTextIndex !== null && (
            <>
              <Label>Text:</Label>
              <Input
                type="text"
                value={texts[activeTextIndex]?.text || ""}
                onChange={handleTextChange}
                placeholder="Edit your text"
              />
              <div className="customize-item" style={{ marginTop: "10px" }}>
                <Label>Font Size:</Label>
                <input
                  type="range"
                  min="10"
                  max="100"
                  value={texts[activeTextIndex]?.fontSize || 20}
                  onChange={handleFontSizeChange}
                />
                <span>{texts[activeTextIndex]?.fontSize || 20}px</span>
              </div>
              <div className="customize-item">
                <Label>Font Weight:</Label>
                <Select
                  value={texts[activeTextIndex]?.fontWeight || "normal"}
                  onChange={handleFontWeightChange}
                >
                  <option value="normal">Normal</option>
                  <option value="bold">Bold</option>
                  <option value="bolder">Bolder</option>
                  <option value="lighter">Lighter</option>
                </Select>
              </div>
              <div className="customize-item">
                <Label>Font Style:</Label>
                <Select
                  value={texts[activeTextIndex]?.fontStyle || "normal"}
                  onChange={handleFontStyleChange}
                >
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                  <option value="oblique">Oblique</option>
                </Select>
              </div>
              <div className="customize-item">
                <Label>Font Family:</Label>
                <Select
                  value={texts[activeTextIndex]?.fontFamily || "Arial"}
                  onChange={handleFontFamilyChange}
                >
                  <option value="Arial">Arial</option>
                  <option value="Times New Roman">Times New Roman</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Verdana">Verdana</option>
                  <option value="Tahoma">Tahoma</option>
                </Select>
              </div>
              <div className="customize-item">
                <Label>Font Color:</Label>
                <ColorInput
                  type="color"
                  value={texts[activeTextIndex]?.fontColor || "#000000"}
                  onChange={handleFontColorChange}
                />
              </div>
            </>
          )}
        </CustomizeSection>
      )}
    </Wrapper>
  );
};

export default ImageQrCode;
