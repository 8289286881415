import React, { useState, useRef, useEffect } from "react";
import notificationIcon from "../../../assets/notification-bell.png";
import "./Profile.css";
import { useSelector } from "react-redux";
import Tooltip from "../navtoottip/Tooltip";

function Profile() {
  const [notificationCount, setNotificationCount] = useState(3);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const iconRef = useRef(null);
  const tenentData = useSelector((state) => state.counter.tenentData);
  const tenant = localStorage.getItem("tenant").toLowerCase();

  // Find the selected tenant
  const selectedTenant = tenentData.find(
    (order) => order.employeeid === tenant
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const filteredNotifications =
    selectedTenant && selectedTenant.notification
      ? selectedTenant.notification
          .filter((notification) => {
            // Filter notifications older than 2 days
            const notificationDate = new Date(notification.date);
            const twoDaysAgo = new Date();
            twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
            return notificationDate > twoDaysAgo;
          })
          .slice(0, 5)
          .reverse()
      : []; // Limit to 5 notifications

  return (
    <div className="notification-container">
      {/* Notification icon with badge */}
      <Tooltip text="Alert">
        <div
          className="icon-circle"
          ref={iconRef}
          onClick={toggleDropdown}
          style={{ zIndex: 1 }}
        >
          <img
            src={notificationIcon}
            alt="Notifications"
            className="img-info"
          />
          {notificationCount > 0 &&
            !showDropdown &&
            filteredNotifications.length > 0 && (
              <div className="notification-badge">
                {filteredNotifications.length}
              </div>
            )}
        </div>
      </Tooltip>

      {/* Dropdown list of notifications */}
      {showDropdown && (
        <div className="notification-dropdown" ref={dropdownRef}>
          {filteredNotifications.length > 0 ? (
            <ul>
              {filteredNotifications.map((notification) => (
                <li key={notification.date}>{notification.message}</li>
              ))}
            </ul>
          ) : (
            <p>No new alerts</p>
          )}
        </div>
      )}
    </div>
  );
}

export default Profile;
