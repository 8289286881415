import React, { useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const OutreachMap = ({ nfcsessions }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) {
      am4core.useTheme(am4themes_animated);

      let chart = am4core.create("chartdivadmin", am4maps.MapChart);
      chart.geodata = am4geodata_worldLow;
      chart.projection = new am4maps.projections.Orthographic();
      chart.panBehavior = "rotateLongLat";
      chart.deltaLatitude = -20;
      chart.padding(20, 20, 20, 20);

      chart.adapter.add("deltaLatitude", function (deltaLatitude) {
        return am4core.math.fitToRange(deltaLatitude, -90, 90);
      });

      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;

      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}";
      polygonTemplate.fill = am4core.color("#354D84");
      polygonTemplate.stroke = am4core.color("#FFFFFF");
      polygonTemplate.strokeWidth = 0.5;

      let graticuleSeries = chart.series.push(new am4maps.GraticuleSeries());
      graticuleSeries.mapLines.template.line.stroke = am4core.color("#ffffff");
      graticuleSeries.mapLines.template.line.strokeOpacity = 0.08;
      graticuleSeries.fitExtent = false;

      chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 0.1;
      chart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#454a58");

      if (nfcsessions && Array.isArray(nfcsessions)) {
        nfcsessions.forEach((session) => {
          const [lat, long] = session.geodata.loc.split(",");
          let bullet = chart.series.push(new am4maps.MapImageSeries());
          let image = bullet.mapImages.create();
          image.latitude = parseFloat(lat);
          image.longitude = parseFloat(long);

          let circle = image.createChild(am4core.Circle);
          circle.radius = 3;
          circle.fill = am4core.color("#ffffff");
        });
      }

      mapRef.current = chart;
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.dispose();
        mapRef.current = null;
      }
    };
  }, [nfcsessions]);

  return (
    <div id="chartdivadmin" style={{ width: "100%", height: "440px" }}></div>
  );
};

export default OutreachMap;
