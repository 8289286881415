import React, { useEffect, useState } from "react";
import "./Login.css";
import logo from "../../assets/arvologo.png";
import productImage from "../../assets/AIC front.png";
import eyeclose from "../../assets/eyeclose.png";
// import { CognitoIdentityServiceProvider } from "aws-sdk";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchTenantData,
  setcredits,
  settenantObjectOptions,
} from "../../redux/counterSlice";
import { signIn } from "../../cognito/Auth";
import mobile from "../../assets/mobile.png";
import bg1 from "../../assets/login2.png";
import bg2 from "../../assets/login3.png";
import bg3 from "../../assets/login5.png";
import bg4 from "../../assets/login7.png";
import mobileview1 from "../../assets/login1.png";
import mobileview2 from "../../assets/login4.png";
import mobileview3 from "../../assets/login6.png";
import mobileview4 from "../../assets/login8.png";
import axios from "axios";
import dropdownArrow from "../../assets/dropdownarrowblack.png";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const tenantdata = useSelector((state) => state.counter.tenentData);

  const [tenantdatas, setTenantdatas] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [text, settext] = useState("");
  const [textloading, setTextloading] = useState("Login");
  const [bgImages, setBgImages] = useState([bg1, bg2, bg3, bg4]);
  const [overlayImages, setOverlayImages] = useState([
    mobileview1,
    mobileview2,
    mobileview3,
    mobileview4,
  ]);
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const [currentOverlayIndex, setCurrentOverlayIndex] = useState(0);
  const photolistStr = localStorage.getItem('photolist');
  console.log(photolistStr)
  useEffect(() => {
    const photolistStr = localStorage.getItem('photolist');
    let photolist = [];
    
    try {
      photolist = JSON.parse(photolistStr) || [];
    } catch (error) {
      console.error("Error parsing photolist from localStorage", error);
    }
  
    if (photolist.length > 0) {
      setBgImages(photolist);
      setOverlayImages([mobileview2]); // Set mobileview2 as the overlay image
    }
  }, []);

  const handleMailUs = () => {
    const subject = encodeURIComponent("Regarding Inquiry");
    const body = encodeURIComponent(
      `Hello,\n\nI would like to inquire about .\n\nPlease provide me with more information.\n\nThank you.`
    );
    window.location.href = `mailto:support@arvo.services?subject=${subject}&body=${body}`;
  };

  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setTextloading("Loading...");

    try {
      await signIn(username, password);
      const data = JSON.parse(localStorage.getItem("user"));
      settext("");
      await fetchtenant(data.idToken.payload.name);
    } catch (err) {
      if (username.length === 0 || password.length === 0) {
        settext(`Password or username can't be empty`);
        setTextloading("Login");
      } else {
        settext("Incorrect Password or Username");
      }
    }
  };

  const [selectedTenant, setSelectedTenant] = useState("Choose Tenant");
  
  const fetchtenant = async (brand) => {
    try {
      const response = await axios.get(
        `https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/brand?brandname=${brand}`
      );
      setTenantdatas(response.data);
      localStorage.setItem("BrandName", username);
      dispatch(fetchTenantData(brand));    
      localStorage.setItem("tenantdata", JSON.stringify(response.data));
      if (response.data.length === 1) {
        selectTenant(response.data[0].employeeid);
        setTimeout(() => {
          if (username === "aditya@arvo.luxury" || username === "info@arvo.luxury") {
            navigate("/admin/dashboard");
          } else {
            navigate("/dashboard");
          }
        }, 2000);
      } else {
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error("Error fetching NfcSessions:", error);
    }
  };

  const tenantObj = useSelector((state) => state.counter.tenantObject);

  useEffect(() => {
    if (username && username.length > 0) {
      dispatch(fetchTenantData(username));
    }
  }, [dispatch, username]);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const selectTenant = (tenant) => {
    setSelectedTenant(tenant);
    localStorage.setItem("tenant", tenant);
    const tenantData = JSON.parse(localStorage.getItem("tenantdata"));
    const tenantselected = tenantData.find((order) => order.employeeid === tenant);
    if (tenantselected && tenantselected.credits) {
      dispatch(setcredits(tenantselected.credits));
    }
    setIsDropdownOpen(false);
  };

  const navigatetodashboard = () => {
    toast.info(`${selectedTenant} tenant selected`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    setTimeout(() => {
      if (username === "aditya@arvo.luxury") {
        navigate("/admin/dashboard");
      } else {
        navigate("/dashboard");
      }
    }, 3000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
      setCurrentOverlayIndex((prevIndex) => (prevIndex + 1) % overlayImages.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, [bgImages.length, overlayImages.length]);

  return (
    <div className="login-tenant-container">
      <div className="left-side">
        <div className="text-top">
          <p>
            Create dynamic product <span style={{color:"#0956cc"}}>landing pages</span> and <span style={{color:"#0956cc"}}>safeguard</span> your physical products with
            <span style={{fontWeight:'500'}}> Copy-proof QR codes, NFC labels,</span> and <span style={{fontWeight:'500'}}>Tamper-proof AI labels.</span>
          </p>
        </div>
        <div className="login-image-center">
          <img 
            src={bgImages[currentBgIndex]} 
            alt="Background Image" 
            className={photolistStr !== null ? "login-background-image-new" : "login-background-image"} 
          />
          <img src={mobile} alt="Mobile Image" className="login-base-image" />
          <img src={overlayImages[currentOverlayIndex]} alt="Overlay Image" className="login-overlay-image" />
        </div>
        <div className="logo-bottom-left">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      {!isAuthenticated ? (
        <div className="right-side">
          <div className="login-right-side">
            <div className="login-top-texts">
              <span className="login-welcome">Welcome to</span>
              <span className="login-arvocloud">ARVO Integrated Cloud</span>
            </div>
            <div className="login-input-box-container">
              <input
                type="text"
                placeholder="Email"
                className="box-login-input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="password-input-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="box-login-input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLogin(e);
                    }
                  }}
                />
                <img
                  src={showPassword ? eyeclose : eyeclose}
                  alt="Toggle Password Visibility"
                  className="icon-eye"
                  onClick={togglePasswordVisibility}
                />
              </div>
              <div style={{ color: "red", fontSize: "10px" }}>{text}</div>
              <div
                className="forgot-password-text"
                onClick={() => navigate("/forgetPassword")}
              >
                Forgot Password?
              </div>
            </div>
            <button
              className={`login-button${username.length > 0 && password.length >= 8 ? "" : " inactive"}`}
              onClick={handleLogin}
            >
              {textloading}
            </button>
            <div
              className="text-btn-below"
              style={{
                bottom: "10px",
                width: "100%",
                textAlign: "center",
                cursor: "pointer",
                display: "flex",
              }}
              onClick={() => navigate("/signup")}
            >
              <span className="having-trb">Don't have an account?</span>
              <span className="contact-us">Signup!</span>
            </div>
            <div
              className="text-btn-below"
              style={{
                position: "absolute",
                bottom: "10px",
                textAlign: "center",
                cursor: "pointer",
                display: "flex",
              }}
              onClick={handleMailUs}
            >
              <span className="having-trb">Having trouble?</span>
              <span className="contact-us">Contact us</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="tenants-right-side">
          <ToastContainer />
          <div className="tenant-right-container">
            <div className="tenant-top-texts">
              <span className="tenant-hello">Hello!</span>
              <span className="tenant-brand">
                Select {username === "Admin" ? "Role" : " your Tenant"}
              </span>
            </div>
            <div className="tenant-custom-dropdown">
              <button
                className="tenant-dropdown-button"
                onClick={toggleDropdown}
              >
                {selectedTenant}
                <img
                  src={dropdownArrow}
                  alt="Dropdown"
                  className="tenant-dropdown-arrow"
                />
              </button>
              {isDropdownOpen && (
                <div className="tenant-dropdown-content">
                  {tenantdatas.map((tenant) => (
                    <div
                      key={tenant}
                      className="tenant-dropdown-item"
                      onClick={() => selectTenant(tenant.employeeid)}
                    >
                      {tenant.employeeid}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button
              className="tenant-proceed-button"
              onClick={navigatetodashboard}
            >
              Proceed
            </button>
            <div
              className="tenant-text-btn-below"
              style={{
                position: "absolute",
                bottom: "10px",
                width: "100%",
                textAlign: "center",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleMailUs}
            >
              <span className="having-trb">Having trouble?</span>
              <span className="contact-us">Contact us</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
