import React, { useState, useEffect } from 'react';
import './Notification.css';
import axios from 'axios';
import droparrow from "./../../../../assets/arrow-down-sign-to-navigate.png";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchNfcSessions,
  fetchProducts,
  setSelectedbrand
} from "../../../../redux/counterSlice";

function Notification() {
  const [isOpen, setIsOpen] = useState(false);
  const nfcsessions = useSelector((state) => state.counter.nfcsessions);
  const product = useSelector((state) => state.counter.products);
  const dispatch = useDispatch();
  const tenant = localStorage.getItem("tenant").toLowerCase();

  useEffect(() => {
    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant));
  }, [dispatch]);

  const [brand, setBrand] = useState([]);
  const [emailMapping, setEmailMapping] = useState({});
  const [selectedOption, setSelectedOption] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // New state for search term

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/allbrand');
        const brandda = response.data;

        const uniqueBrandNames = [...new Set(
          brandda
            .filter(obj => obj.cognitoBrandName !== "Admin")
            .map(obj => {
              return { name: obj.cognitoBrandName, email: obj.emailaddress };
            })
        )];

        const options = ['All', ...uniqueBrandNames.map(item => item.name)];
        setBrand(options);

        const mapping = {};
        uniqueBrandNames.forEach(item => {
          mapping[item.name] = item.email;
        });
        setEmailMapping(mapping);

        setSelectedOption(uniqueBrandNames[0]?.name || '');
        dispatch(setSelectedbrand(uniqueBrandNames[0]?.name || ''));

        if (uniqueBrandNames[0]) {
          localStorage.setItem("selectedBrandEmail", uniqueBrandNames[0].email);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    dispatch(setSelectedbrand(option));
    localStorage.setItem("selectedBrand", option);
    localStorage.setItem("Brandlist", JSON.stringify(brand));
    localStorage.setItem("userslist", JSON.stringify(emailMapping));
    localStorage.setItem("selectedBrandEmail", emailMapping[option] || '');
    
    setIsOpen(false);
    setSearchTerm(''); // Clear search term when an option is selected
  };

  // Filter brands based on the search term
  const filteredBrands = brand.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="dropdown">
      <button onClick={() => setIsOpen(!isOpen)} className="navbar-dropdown-button">
        {selectedOption}
        <img className='nav-btn-img-two' src={droparrow} alt="" />
      </button>
      {isOpen && (
        <div className="dropdown-content" style={{ maxHeight: '430px', overflow: 'scroll' }}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input" // Add a class for styling the input
          />
          {filteredBrands.map((option, index) => (
            <div key={index} className="dropdown-item" onClick={() => handleSelectOption(option)}>
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Notification;
