import React, { useState } from "react";
import "./Status.css";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";



function getStatusStyle(status) {
  const baseStyle = {
    borderRadius: "8px",
    padding: "4px 15px",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18.75px",
    letterSpacing: "0.02em",
  };

  const styles = {
    Deactivated: {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
    Active: {
      backgroundColor: "#DDFFE2",
      color: "#119927",
      ...baseStyle,
    },
  };

  return <span style={styles[status]}>{status}</span>;
}

const changestatus=["pending","closed","escalated", "irrelevant" ]
const Status = ({
  status,
  handleDeleteRow,
  handleDeactivateTenant,
  handleDeleteTenant,
  rowIndex,
  tenantData,
}) => {
  const [showTenantTable, setShowTenantTable] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");

  const handleChangeStatus=async(tenant)=>{

    try {
      const senddata = {
        tenantid: localStorage.getItem('tenant'),
        timestamp: Number(tenant.timestamp),
        newStatus: selectedEmployeeId
      };
  
      // Send POST request to your API endpoint
      const response = await axios.post("https://bxcswtw14g.execute-api.ap-south-1.amazonaws.com/prod/", senddata);
  
      console.log("Response from API:", response.data);
      toast.success(`Status changed successfully`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // Close the popup after successful submission
    } catch (error) {
      console.error("Error sending data:", error);
    }


  }

  const getButtonText = () => {
    if (status === "Deactivated") {
      return "Activate";
    } else if (status === "Active") {
      return "Deactivate";
    }
    return "";
  };

  const toggleTenantTable = () => {
    setShowTenantTable(!showTenantTable);
  };

  return (
    <div className="super-status-wrapper">
       <ToastContainer />
      <div className="button-container">
        <button className="status-button-show-tenant" onClick={toggleTenantTable}>
          {showTenantTable ? "Hide Detail" : "Show Detail"}
        </button>
        <button className="status-button-show-tenant">Send Ticket</button>
        <button className="status-button-delete" onClick={handleDeleteRow}>
          Delete
        </button>
     
      </div>
      
      {showTenantTable && (
        <table>
          <tbody>
            {tenantData.map((tenant, tenantIndex) => (
              <tr key={tenantIndex}>
                <td> Name : {tenant.name}</td>
                <td colSpan={5} rowSpan={5}> Issue : {tenant.issueText}</td>
                <td > Contact Details : {tenant.contactDetails}</td>
                <td> Change  Ticket Status : &nbsp;
                <select
            className="super-admin-dashboard-select"
            style={{
              background: 'linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)',
              boxShadow: '4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset',
              border: 'none',
              color: 'white',
              fontSize: '14px',
              fontWeight: '400',
              borderRadius: '24px',
              fontFamily: 'Roboto',
              padding: '8px 16px',
              cursor: 'pointer',
            }}
            value={selectedEmployeeId}
            onChange={(event)=>setSelectedEmployeeId(event.target.value)}
          >
            {changestatus.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
                </td>
                <td>
                  <button
                    className="tenant-table-delete-btn"
                    onClick={() => handleChangeStatus(tenant)}
                  >
                    Submit Change Status
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Status;