import React from "react";
import "./Tooltip.css";

const Tooltip = ({ text, children }) => {
  return (
    <div className="nav-tooltip">
      {children}
      <span className="nav-tooltiptext">{text}</span>
    </div>
  );
};

export default Tooltip;
