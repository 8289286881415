import React from 'react';
import './Info.css';
import Search from './search/Search';
import Profile from './profile/Profile';
import Notification from './notification/Notification';
import { signIn, signOut } from "../../../cognito/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import logout from '../../../assets/Logout.png';

function Info() {
  const location = useLocation();
  const navigate = useNavigate();

  const renderNotification = location.pathname === '/admin/dashboard' || location.pathname === '/';

  const confirmLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      handlelogout();
    }
  };
  const handlelogout = async () => {
    localStorage.setItem("tenantdata", " ");
    localStorage.setItem("tenant", " ");
    await signOut();
    navigate("/");
  };
  return (
    <div className="info-container">
      {/* <Search /> */}
      {renderNotification && <Notification />}
      <Profile />
      <div>
        <div className="icon-circle">
          <img onClick={confirmLogout} src={logout} alt="Logout" className='img-info' />
        </div>
      </div>
    </div>
  );
}

export default Info;
