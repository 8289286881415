import React, { useState } from "react";
import "./Status.css";

const Status = ({
  status,
  handleDeleteRow,
  handleDeactivateTenant,
  handleDeleteTenant,
  rowIndex,
  tenantData,
}) => {
  const [showTenantTable, setShowTenantTable] = useState(false);

  const getButtonText = () => {
    if (status === "Deactivated") {
      return "Activate";
    } else if (status === "Active") {
      return "Deactivate";
    }
    return "";
  };

  const toggleTenantTable = () => {
    setShowTenantTable(!showTenantTable);
  };

  console.log(tenantData);

  const getStatusStyle = (status) => {
    const baseStyle = {
      borderRadius: "8px",
      padding: "4px 15px",
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "18.75px",
      letterSpacing: "0.02em",
    };

    const styles = {
      Deactivated: {
        backgroundColor: "#FFF1E6",
        color: "#EA6708",
        ...baseStyle,
      },
      Active: {
        backgroundColor: "#DDFFE2",
        color: "#119927",
        ...baseStyle,
      },
    };

    return <span style={styles[status]}>{status}</span>;
  };

  // Ensure tenantData is always an array
  const tenantList = Array.isArray(tenantData) ? tenantData : [tenantData];

  return (
    <div className="super-status-wrapper">
      <div className="button-container">
        <button className="status-button-show-tenant" onClick={toggleTenantTable}>
          {showTenantTable ? "Hide Tenant" : "Show Tenant"}
        </button>
        <button className="status-button-deacticate">{getButtonText()}</button>
        <button className="status-button-delete" onClick={handleDeleteRow}>
          Delete
        </button>
      </div>
      {showTenantTable && (
        <div>
          <table>
            <tbody>
              {tenantList.map((tenant, tenantIndex) => (
                <React.Fragment key={tenantIndex}>
                
                  <tr>
                    <td colSpan={4} className="tenant-info">
                      <div>Tenant Name: {tenant.brandName}</div>
                      <div>Email Address: {tenant.credits}</div>
                      <div>Username: {tenant.tenantName}</div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Status;
  