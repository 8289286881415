import React, { useState } from "react";
import "./Open.css";
import ticketData from "../Data";
import ChatBox from "../ChatBox/ChatBox";

function Open({ data }) {
  const openTicket = data.filter((ticket) => ticket.status === "open");
  const [selectedTicket, setSelectedTicket] = useState(null);

  const handleRowClick = (ticket) => {
    setSelectedTicket(ticket);
  };

  const handleClosePanel = () => {
    setSelectedTicket(null);
  };

  function calculateTimeDifference(start, end) {
    const startTimestamp = new Date(start).getTime(); // Example start timestamp
    const endTimestamp = new Date(end).getTime();

    console.log(startTimestamp, endTimestamp);

    const timeDifference = endTimestamp - startTimestamp;
    const seconds = Math.floor((timeDifference / 1000) % 60);
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (days > 0) {
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  }

  return (
    <div>
      <table className="open-table">
        <thead>
          <tr>
            <th>Ticket Id</th>
            <th>Date</th>
            <th>Email</th>
            <th>Subject</th>
            <th>Status</th>
            <th>Time Lapsed</th>
          </tr>
        </thead>
        <tbody>
          {openTicket.map((ticket, index) => (
            <tr key={index} onClick={() => handleRowClick(ticket)}>
              <td>{ticket.id}</td>
              <td>{ticket.date}</td>
              <td>{ticket.email}</td>
              <td>{ticket.subject.slice(0, 25)}...</td>
              <td>{ticket.status}</td>
              <td>{calculateTimeDifference(ticket.timestamp, Date.now())}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedTicket && (
        <ChatBox
          data={data}
          id={selectedTicket.id}
          email={selectedTicket.email}
          status={selectedTicket.status}
          subject={selectedTicket.subject}
          name={selectedTicket.name}
          time={selectedTicket.time}
          date={selectedTicket.date}
          onClose={handleClosePanel}
        />
      )}
    </div>
  );
}

export default Open;
