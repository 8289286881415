  import React ,{useEffect, useState}from "react";
  import "./Salesoverview.css";
  import SalesOver from "../../../../assets/Salesoverview.png";
  import ReactApexChart from "react-apexcharts";
  import {
    decrement,
    increment,
    fetchNfcSessions,
    fetchProducts,
  } from "../../../../redux/counterSlice";
  import { useSelector, useDispatch } from "react-redux";

  function Salesoverview({filteredData}) {

    const nfcsessions = useSelector((state) => state.counter.nfcsessions);  
    const product = useSelector((state) => state.counter.products);
    const dispatch = useDispatch();
    let employeeIds = [];
    if (filteredData && Array.isArray(filteredData)) {
        employeeIds = filteredData.map(data => data.employeeid);
    }
  console.log(filteredData,employeeIds)
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(""); // State to store the selected employee ID
useEffect(()=>{
  if (selectedEmployeeId) {
    // Fetch nfcsessions
    dispatch(fetchNfcSessions(employeeIds[0]));

    // Fetch products for each product 
      dispatch(fetchProducts(employeeIds[0]));
  
  }

},[dispatch,employeeIds])
  
useEffect(() => {
  if (nfcsessions.length > 0 && product.length > 0) {
    // Create a mapping of the counts of nfcsessions by title
    const sessionCounts = nfcsessions.reduce((acc, session) => {
      acc[session.title] = (acc[session.title] || 0) + 1;
      return acc;
    }, {});

    // Create the series based on product titles
    const updatedSeries = product.map((prod) => {
      // Match product title to session title and get the count
      return sessionCounts[prod.title] || 0; // Default to 0 if not found
    });

    console.log(updatedSeries,sessionCounts)
    const updatedSrc = product.map((prod) => prod.photo[0]);
    const updatedLab = product.map((prod) => prod.title);

    setChartData({
      series: updatedSeries,
      options: {
        ...chartData.options,
        fill: {
          ...chartData.options.fill,
          image: {
            ...chartData.options.fill.image,
            src: updatedSrc,
          },
        },
        labels: updatedLab,
      },
    });
  }
}, [nfcsessions, product]); // Re-run when nfcsessions or products change

    

    console.log(nfcsessions,product)  
    const [chartData, setChartData] = React.useState({
      series: [44, 33, 54, 45],
      options: {
        chart: {
          width: 380,
          type: "pie",
        },
        colors: ["#93C3EE", "#E5C6A0", "#669DB5", "#94A74A"],
        fill: {
          type: "image",
          opacity: 0.85,
              image: {
                src: [
                  "https://via.placeholder.com/150",
                  "https://placekitten.com/200/300",
                  "https://picsum.photos/200",
                  "https://source.unsplash.com/random/200x200",
                ],
            width: 25,
            imagedHeight: 25,
          },
        },
        stroke: {
          width: 4,
        },
        labels: ["Label 1", "Label 2", "Label 3", "Label 4"], // Add labels here
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#111"],
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    });

    return (
      <div
        style={{
          height: "190px",
          width: "50%",
          border: "none",
          borderRadius: "11px",
          padding: "10px",
          background: "white",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
  
    <div className="revenue-header">
    <img src={SalesOver} style={{width:'19px', marginRight:'5px'  }} alt="" />
      Product Scans</div>
  
  </div>

        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="pie"
            width={400} 
            height={150}
          />
        </div>
        <div></div>
      </div>
    );
  }

  export default Salesoverview;
