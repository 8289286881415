import React, { useState ,useEffect} from "react";
import "./Tag.css";
import orderplace from "../../../assets/31fe3938221437.5968c5a847d93.gif";
import { useParams, useNavigate } from "react-router-dom";
function Invoice() {
  const navigate = useNavigate();

  useEffect(()=>{
setTimeout(() => {
  navigate('/dashboard') 
}, 5000);
  },[])
  return (
    <div className="tag-page">
      <img src={orderplace} />
    </div>
  );
}

export default Invoice;
